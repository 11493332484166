/* eslint-disable no-useless-concat */

import moment from 'moment';
import { renderToStaticMarkup } from 'react-dom/server';
import { logger } from '../../services/logger';
import { ReactElement } from 'react';
// import { stringSimilarity } from "string-similarity";

export const reactSelectStyle = {
	control: (base: any, state: { isFocused: any }) => ({
		...base,
		border: state.isFocused ? '0.1rem solid #6F6F6F' : '0.1rem solid #6F6F6F',
		// backgroundColor: state.isSelected ? "#6F6F6F" : "white",
		boxShadow: state.isFocused ? '0.1rem solid #6F6F6F' : 0,
		'&:hover': {
			// border: state.isFocused ? 0 : 0
		},
	}),
	option: (provided: any, state: { isSelected: any }) => ({
		...provided,
		backgroundColor: state.isSelected ? 'rgba(204, 204, 204, .3)' : 'white',
		color: state.isSelected ? '#020202' : '#020202',
	}),
};

export const formatNumWithoutCommaNaira = (number: any) => {
	// const nairaSymbol = "\u{020A6}";

	var regex = /[,\sN#%₦G]/g;
	var result = String(number).replace(regex, '');
	return result;
};

export const formatNumWithCommaNaira = (number: any) => {
	var regex = /[,\sNG]/g;
	var result = String(number).replace(regex, '');
	var num = Math.abs(Number(result));
	num = Number(num.toFixed(2));
	const numSplit = num.toString().split('.');
	var int = numSplit[0];
	const dec = numSplit[1];
	if (int.length > 3) {
		int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	if (number) {
		return int + '.' + dec;
	}

	return '0' + '.' + '00';
};

export const formatNumWithCommaNairaSymbol = (number: any) => {
	const nairaSymbol = '\u{020A6}';

	var regex = /[,\sNG]/g;
	var result = String(number).replace(regex, '');
	var num = Math.abs(Number(result));
	num = Number(num.toFixed(2));
	const numSplit = num.toString().split('.');
	var int = numSplit[0];
	const dec = numSplit[1];
	if (int.length > 3) {
		int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	if (number) {
		return nairaSymbol + '' + int + '.' + dec;
	}

	return nairaSymbol + '' + '0' + '.' + '00';
};

export function clearCache() {
	if ('caches' in window) {
		// Clear all caches
		caches.keys().then((cacheNames) => {
			cacheNames.forEach((cacheName) => {
				caches.delete(cacheName);
			});
		});
	}
}

export const formatNumWithComma = (
	number: string | number,
	curr?: string | undefined,
	points?: number | undefined
) => {
	if (typeof number === 'string') {
		number = parseFloat(number);
	}

	if (!curr) {
		if (number >= 1e6) {
			// Format number in millions (M)
			return (
				(number / 1e6)
					.toFixed(points ?? 1)
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'M'
			);
		} else if (number >= 1e3) {
			// Format number in thousands (k)
			return (
				(number / 1e3)
					.toFixed(points ?? 1)
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'k'
			);
		} else {
			return number?.toLocaleString(); // Add commas to numbers with less than 1 thousand
		}
	} else {
		const regex = /[,\sNG]/g;
		const result = String(number).replace(regex, '');
		const num = Math.abs(Number(result));
		const numSplit = num.toFixed(2).split('.');
		let int = numSplit[0];
		const dec = numSplit[1];

		if (int.length > 3) {
			int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}

		if (number) {
			return int + '.' + dec;
		}

		return '0';
	}
};

export const getTotalPage = (perpage: any, totalNum: any) => {
	const val = Math.ceil(Number(totalNum) / Number(perpage));
	// logger.log(val);
	return val;
};

export const generateReference = () => {
	let text = '';
	const possible =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	for (let i = 0; i < 10; i++)
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	return text;
};

export function formatDate(date: Date) {
	const year = String(date.getFullYear());
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}/${month}/${day}`;
}

export function numberFormatChart(num: number, digits: number | undefined) {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'Q' },
		{ value: 1e18, symbol: 'QT' },
		{ value: 1e21, symbol: 'QQ' },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var item = lookup
		.slice()
		.reverse()
		.find(function (item) {
			return num >= item.value;
		});
	if (item && item.symbol === 'M') {
		// const formattedNum = (num / item.value).toFixed(digits);
		// const integerPart = formattedNum.split(".")[0];
		// const decimalPart = formattedNum.split(".")[1];
		// return `${integerPart}.${decimalPart}${item.symbol}`;
	}
	return item
		? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
		: '0';
}

// export const formatMetaData = (obj: string) => {
// 	if (obj) {
// 		const newObj = JSON?.parse(obj);
// 		// logger.log(newObj);
// 		return newObj;
// 	}
// };

export const getPageNum = (link: string) => {
	if (link) {
		const num = link?.split('&current_page=')[1]?.split('&')[0];
		return num;
	}
};

export const sumAllNum = (list: any[]) => {
	if (list) {
		const sumVal = list.reduce((a: any, b: any) => a + b, 0);
		return sumVal;
	}
};

export const removeCountryCode = (num: string) => {
	if (num) {
		const val =
			num.slice(0, 3) === '234'
				? num.replace('234', '0')
				: num.slice(0, 1) === '0'
				? num.replace('0', '')
				: num;
		return val;
	}
};

export const capitalizeFirstLetter = (str?: any) => {
	if (str) {
		let capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
		return capitalizedStr;
	}
};

export function capitalizeFirstWord(str: string): string {
	if (!str || str.length === 0) {
		return str;
	}
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const capitalizeAllLetter = (str?: any) => {
	if (str) {
		let capitalizedStr = str?.toUpperCase();
		return capitalizedStr;
	}
};

export const formatStatus = (param?: string | number) => {
	if (param) {
		const val =
			param === '0'
				? 'pending'
				: param === '1'
				? 'processing'
				: param === '2'
				? 'success'
				: param === '3'
				? 'failed'
				: '--';
		return val;
	}
};

export const removeSpace = (str: string) => {
	const val = str.replace(/\s+/g, '');
	// logger.log(val);
	return val;
};

export const trimLongString = (str: string | undefined, num: number) => {
	if (str && num) {
		const val =
			String(str).length > Number(num)
				? `${String(str).slice(0, Number(num))}...`
				: str;
		// console.log(val);

		return val;
	}
};

export const getPosTerminalRequest = (list: string | any[]) => {
	if (list?.length > 0) {
		const status = list[0]?.status;
		return status;
	}
};

export const formatTitleTemplate = (text: any) => {
	var regex = /[,\s_.csvNG]/g;
	var result = String(text).replace(regex, ' ');
	return result;
};

export const formatNUmPan = (str: string | any[]) => {
	if (str) {
		const val = `${str?.slice(0, 6)}******${str?.slice(
			str?.length - 4,
			str?.length
		)}`;
		return val;
	}
};

export const lowerCaseWrap = (text: string) => {
	if (text) {
		const lowerText = text.toLowerCase();
		let capitalizedStr = lowerText
			.split(' ')
			.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
		return capitalizedStr;
	}
};

export const formatTypeFunction = (str: string) => {
	let val;
	// console.log(str);
	if (str) {
		if (!str?.includes('_')) {
			val = str;
		} else {
			const splitStr = String(str)?.replace(/_/g, ' ');
			val = lowerCaseWrap(splitStr);
		}

		// console.log(splitStr);

		// console.log(val);
		return val;
	}
};

export const getStartAndStopDates = (param: moment.DurationInputArg1) => {
	if (param) {
		// get the current date
		var currentDate = moment();

		// subtract 7 days from the current date moment(currentDate).subtract(daysToSubtract, 'days')
		var actualDate = moment(currentDate).subtract(param, 'days');

		const fromDate = actualDate.format('YYYY-MM-DD');

		const toDate = currentDate.format('YYYY-MM-DD');

		const obj = {
			start_date: fromDate,
			end_date: toDate,
		};

		return obj;
	}
};

/**
 *
 * @param {string} key The hot key you want to bind to
 * @param {any} func The function to call when the key is pressed
 * @returns function
 * @description This fn allows you bind child functions to a hot key - basically a key binding function
 */

export const mapHotkey = (key: string, func: any) => {
	const handleKeyDown = (event: { metaKey: any; key: string }) => {
		// Check if Cmd + D is pressed
		if (event.metaKey && event.key === key) {
			func();
		}
	};

	// Add event listener when the component mounts
	window.addEventListener('keydown', handleKeyDown);

	// Clean up the event listener when the component unmounts
	return () => {
		window.removeEventListener('keydown', handleKeyDown);
	};
};

/**
 * A custom implementation of moment date time formater using JavaScript Internalization API.
 * @author Ezeani Emmanuel
 * @param {Date | string} date
 * @returns string
 */
export function formatDateTime(date: Date | string) {
	const parsedDate = new Date(date);

	if (isNaN(parsedDate.getTime())) {
		return 'Invalid Date';
	}

	const options: any = {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	};

	return new Intl.DateTimeFormat('en-US', options).format(parsedDate);
}

/**
 * @name IconVault
 * @description A utility function for converting React icons to data URLs.
 * @author Ezeani Emmanuel
 *
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>>} icon - The React icon component to convert.
 *
 * @returns {string} The data URL representing the icon.
 *
 * @usage
 * 1. Import the `IconVault` function:
 *    import { IconVault } from './IconVault';
 *
 * 2. Use the `IconVault` function to convert a React icon to a data URL:
 *    const dataUrl = IconVault(icons.arrow_right);
 *
 *    Note: Replace `icons.arrow_right` with your actual React icon component.
 *
 * 3. Use the `dataUrl` in your CSS or inline styles:
 *    background-image: url(dataUrl);
 *
 * @example
 * // Import the required libraries
 * import { renderToStaticMarkup } from 'react-dom/server';
 *
 * // Define the IconVault function
 * const IconVault = (
 *   icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>
 * ) => {
 *   const svgString = renderToStaticMarkup(icon);
 *   const utf8Svg = decodeURIComponent(encodeURIComponent(svgString));
 *   const base64Svg = btoa(utf8Svg);
 *   const dataUrl = `url("data:image/svg+xml;base64,${base64Svg}")`;
 *   return dataUrl;
 * };
 *
 * @notes
 * This utility function converts a React icon component into a data URL
 * that can be used in CSS or inline styles. It encodes the SVG string to
 * base64 format and generates a data URL with the proper MIME type.
 * By using this function, you can easily integrate React icons into your
 * styling workflow.
 *
 * Please note that the function uses the `renderToStaticMarkup` function
 * from the `react-dom/server` module, so make sure to import it before using
 * the `IconVault` function.
 */

export const IconVault = (
	icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>
): string => {
	const svgString = renderToStaticMarkup(icon);
	const utf8Svg = decodeURIComponent(encodeURIComponent(svgString));
	const base64Svg = btoa(utf8Svg);
	const dataUrl = `data:image/svg+xml;base64,${base64Svg}`;
	return dataUrl;
};

export function symbol(wallet: string) {
	if (wallet === 'ngn') {
		return '₦';
	} else if (wallet === 'usd') {
		return '$';
	} else if (wallet === 'gbp') {
		return '£';
	} else if (wallet === 'rf' || wallet === 'rwf') {
		return 'Fr';
	} else if (wallet === 'ksh' || wallet === 'khs') {
		return 'KSh';
	} else if (wallet === 'eur') {
		return '€';
	} else {
		return ''; // Default case if wallet is not recognized
	}
}

export function returnInitial(name: string) {
	if (name) {
		const i = name?.split(' ');
		if (i.length > 1) {
			return i[0]?.slice(0, 1).toUpperCase() + i[1]?.slice(0, 1).toUpperCase();
		} else {
			return i[0]?.slice(0, 1).toUpperCase() + i[0]?.slice(1, 2).toUpperCase();
		}
	} else {
		return '';
	}
}

/**
 *
 * @param key The URL Param Key you want to add to the URL e.g 'search-query' or 'q'
 * @param value The value you want to add to the URL Param Key e.g 'Ezeani Mona'
 * @param title The Page Title e.g 'Search Results for Ezeani Mona'
 * @param preserve_hash set to true to preserve the hash value you want to add to the URL Param Key
 * @usage
 * 1. Import the `insertUrlParam` function:
 *    import { insertUrlParam } from './helper';
 *
 * 2. Use the `insertUrlParam` function to add url parameters to the URL
 *
 * @example
 *  const search = insertUrlParam;
 * <button onclick={() => search('q', 'Mona Lee')}/>
 */
export function insertUrlParam(
	key: string,
	value: string,
	title = '',
	preserve_hash = false
) {
	let searchParams = new URLSearchParams(window.location.search);
	searchParams.set(key, value);
	let newurl =
		window.location.protocol +
		'//' +
		window.location.host +
		window.location.pathname +
		'?' +
		searchParams.toString();
	if (preserve_hash) newurl = newurl + window.location.hash;
	let oldTitle = document.title;
	if (title !== '') {
		window.history.replaceState({ path: newurl }, title, newurl);
		if (document.title !== title) {
			// fallback if above doesn't work
			document.title = title;
		}
	} else {
		// in case browsers ever clear titles set with empty string
		window.history.replaceState({ path: newurl }, oldTitle, newurl);
	}
}

export function removeUrlParam(key: string, title = '', preserve_hash = false) {
	let searchParams = new URLSearchParams(window.location.search);
	searchParams.delete(key);
	let newurl =
		window.location.protocol +
		'//' +
		window.location.host +
		window.location.pathname +
		(searchParams.toString() !== '' ? '?' + searchParams.toString() : '');
	if (preserve_hash) newurl = newurl + window.location.hash;
	let oldTitle = document.title;
	if (title !== '') {
		window.history.replaceState({ path: newurl }, title, newurl);
		if (document.title !== title) {
			// fallback if above doesn't work
			document.title = title;
		}
	} else {
		// in case browsers ever clear titles set with empty string
		window.history.replaceState({ path: newurl }, oldTitle, newurl);
	}
}

export const searchParams: URLSearchParams = new URLSearchParams(
	document.location.search
);

export function removeCommaAndNairaSign(str: string | number) {
	// Remove commas from the string
	const stringWithoutCommas = String(str).replace(/,/g, '');

	// Remove the Nigerian Naira sign (₦) from the string
	const stringWithoutNairaSign = stringWithoutCommas.replace(/₦/g, '');

	// Return the modified string
	return stringWithoutNairaSign;
}

export function cleanseString(input: string) {
	// Use a regular expression to remove non-digit characters
	return input.replace(/[^\d]/g, '');
}
export const handleCopy = (data: string, setState: any, stateValue?: any) => {
	navigator.clipboard?.writeText(data);

	setState(stateValue || true);

	setTimeout(() => {
		setState(false);
	}, 1000);
	// You can add any additional logic or feedback here, like showing a tooltip or toast message.
};

export const genCaptchaKeyAsync = async (param: string | undefined) => {
	try {
		const token = await window.grecaptcha.execute(param, {
			action: 'validate_captcha',
		});
		return token;
	} catch (e) {
		logger.log(e, 'captcha error');
		return 'error_string';
	}
};

export const setCookie = (name: string, value: string, seconds: number) => {
	const expirationDate = new Date();
	expirationDate.setTime(expirationDate.getTime() + seconds * 1000); // Convert seconds to milliseconds

	const cookieValue =
		encodeURIComponent(value) +
		(seconds ? `; expires=${expirationDate.toUTCString()}` : '');

	document.cookie = `${name}=${cookieValue}; path=/`;
};

export function getCookie(name: string) {
	const cookies = document.cookie.split(';');
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i].trim();
		// Check if this cookie is the one we're looking for
		if (cookie.startsWith(name + '=')) {
			// Return the value of the cookie
			return decodeURIComponent(cookie.substring(name.length + 1));
		}
	}
	// If the cookie is not found, return null
	return null;
}

// export function maskCardNumber(cardNumber: any) {
// 	// Regular expression to match only digits
// 	var digitPattern = /\d/g;

// 	// Extract only digits from the input card number
// 	var digits = cardNumber.match(digitPattern);

// 	if (!digits) {
// 		return '---';
// 	}

// 	// Replace all but the first 6 and last 4 digits with asterisks
// 	for (var i = 6; i < digits.length - 4; i++) {
// 		digits[i] = '*';
// 	}

// 	// Join the digits array back into a string
// 	var maskedNumber = digits.join('');

// 	// Insert asterisks between the visible digits in the desired format
// 	return maskedNumber.replace(/(\d{6})(\d+)(\d{4})/, '$1****$3');
// }

export const returnStatus = (param: any) => {
	// String(param) === '0'
	// ? 'pending'
	// : String(param) === '2'
	// ? 'discard'
	// : String(param) === '3'
	// ? 'review'
	// : String(param) === '4'
	// ? 'resolve'

	if (param || String(param) === '0') {
		const val =
			String(param) === '0'
				? 'pending'
				: String(param) === '1'
				? 'info'
				: String(param) === '2'
				? 'failed'
				: String(param) === '3'
				? 'success'
				: 'Unknown';

		return val;
	}
};

export const checkDisputeStatus = (param: any) => {
	const val =
		String(param) === '0'
			? 'pending'
			: String(param) === '4'
			? 'resolved'
			: String(param) === '2'
			? 'discard'
			: String(param) === '3'
			? 'review'
			: 'pending';
	return val;
};

export const checkDisputeStatusColor = (param: any) => {
	const val =
		String(param) === '0'
			? 'pending'
			: String(param) === '4'
			? 'success'
			: String(param) === '2'
			? 'failed'
			: String(param) === '3'
			? 'info'
			: 'pending';
	return val;
};

export function replaceSpacesWithDash(text: string) {
	// Using regular expression to replace spaces with dashes globally
	const valOne = text.replace(/ /g, '-');
	const valTwo = valOne.replace(/-+/g, '-');
	return capitalizeFirstLetter(valTwo);
}

export function replaceSpacesWithNothing(text: string) {
	// Using regular expression to replace spaces with dashes globally
	const valOne = text.replace(/ /g, '');
	return valOne;
}

export const formatMetaData = (obj: string) => {
	if (obj) {
		const newObj = JSON?.parse(obj);
		// console.log(newObj);
		return newObj;
	}
};

export function validateStringForTerminalId(inputString: string) {
	// Regular expression to match exactly 8 alphanumeric characters, all capitalized
	var regex = /^[A-Z0-9]{8}$/;

	// Test the input string against the regular expression
	return regex.test(inputString);
}

export function formatDateTwo(dateString: any) {
	const date = new Date(dateString);

	// Extract time
	const time = date.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
		// hour12: true,
	});

	// Extract month and year
	const monthYear = date.toLocaleDateString('en-US', {
		month: 'short',
		day: '2-digit',
	});

	return {
		time: time !== 'Invalid Date' ? time : '---',
		monthYear: monthYear !== 'Invalid Date' ? monthYear : '---',
	};
}

export function formatTime(timeStr: any) {
	// Split the time string into hours and minutes
	const [hoursStr, minutesStr] = timeStr.split(':');
	const hours = parseInt(hoursStr);
	const minutes = parseInt(minutesStr);

	// Determine if it's AM or PM
	const period = hours >= 12 ? 'pm' : 'am';

	// Convert hours to 12-hour format
	const hours12 = hours % 12 || 12; // Ensure 12-hour format for 0 hours

	// Construct the formatted time string
	const formattedTime = `${hours12}:${minutes < 10 ? '0' : ''}${
		minutes || '0'
	}${period || ' '}`;

	return formattedTime;
}

function rearrangeDaysOfWeek(daysString: any) {
	// Split the string into an array of days
	const daysArray = daysString.split('-');

	// Define the correct order of days in the week
	const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

	// Sort the array of days based on their order in the week
	const sortedDays = daysArray.sort((a: any, b: any) => {
		return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b);
	});

	// Join the sorted array back into a string
	const rearrangedString = sortedDays.join('-');

	return rearrangedString;
}

export function capitalizeDays(daysStr: any) {
	// Split the string into an array of days
	if (daysStr) {
		const daysArray = daysStr?.join('-');

		// Capitalize the first letter of each day
		// const capitalizedDays = daysArray?.map((day: any) => {
		//   // Trim any leading or trailing whitespace
		//   const trimmedDay = day.trim();
		//   // Capitalize the first letter
		//   return trimmedDay.charAt(0).toUpperCase() + trimmedDay.slice(1);
		// });

		// // Join the capitalized days back into a string
		// const capitalizedDaysStr = capitalizedDays.join(', ');

		return rearrangeDaysOfWeek(daysArray);
	}
}

export function maskCardNumber(cardNumber: any) {
	// Regular expression to match only digits
	var digitPattern = /\d/g;

	// Extract only digits from the input card number
	var digits = String(cardNumber).match(digitPattern);
	// console.log(digits);

	if (!digits) {
		return 'Invalid card number';
	}

	// Replace all but the first 6 and last 4 digits with asterisks
	for (var i = 6; i < digits.length - 4; i++) {
		digits[i] = '*';
	}

	// Join the digits array back into a string
	var maskedNumber = digits.join('');

	// Insert asterisks between the visible digits in the desired format
	return maskedNumber.replace(/(\d{6})(\d+)(\d{4})/, '$1****$3');
}

interface FormatOptions {
	hideDecimal?: boolean;
	hideSymbol?: boolean;
	convertToNumber?: boolean;
	symbol?: string;
	removeLeadingZero?: boolean;
	numberAbbreviate?: boolean;
}

const formatNumberWithSymbols = (number: number) => {
	const formats = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'Q' },
		{ value: 1e18, symbol: 'QT' },
		{ value: 1e21, symbol: 'QQ' },
	];

	const format = formats
		.slice()
		.reverse()
		.find((fmt) => number >= fmt.value);

	if (format) {
		return (
			(number / format.value).toFixed(2).replace(/\.00$/, '') + format.symbol
		);
	}

	return number.toString();
};

const getCurrencySymbol = (param: string) => {
	const nairaSymbol = '\u{020A6}';
	const upperParam = String(param).toUpperCase();

	return upperParam === 'NGN' || upperParam === 'NAIRA'
		? nairaSymbol
		: upperParam === 'USD' || upperParam === 'DOLLAR'
		? '$'
		: upperParam === 'EUR' || upperParam === 'EURO'
		? '€'
		: upperParam === 'ZAR'
		? 'R'
		: upperParam === 'KES'
		? 'KSh'
		: upperParam === 'CAD'
		? '$'
		: upperParam === 'GHS'
		? '₵'
		: upperParam === 'GBP' || upperParam === 'POUNDS' || upperParam === 'POUND'
		? '£'
		: param;
};

export const formatNumWithCommaNairaSymbolTwo = (
	number: number | string | null | undefined,
	options?: FormatOptions
): string => {
	const symbol = getCurrencySymbol(options?.symbol || 'NGN');
	const hideDecimal = options?.hideDecimal || false;
	const hideSymbol = options?.hideSymbol || false;
	const convertToNumber = options?.convertToNumber || false;
	const removeLeadingZero = options?.removeLeadingZero || false;
	const numberAbbreviate = options?.numberAbbreviate || false;

	if (number !== null && number !== undefined) {
		// Remove symbols and non-numeric characters
		let numericValue = number.toString().replace(/[^0-9.]/g, '');

		// Separate the integer and decimal parts
		let [integerPart, decimalPart] = numericValue.split('.');

		if (removeLeadingZero) {
			// Remove leading zeros if more than one leading zero
			if (/^0{2,}/.test(integerPart)) {
				integerPart = integerPart.replace(/^0+/, '');
			}
		}

		if (convertToNumber) {
			// Return number without thousand formatting and without symbol
			const val = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
			return val;
		}

		let formattedValue = Number(
			integerPart + (decimalPart ? '.' + decimalPart : '')
		);

		if (numberAbbreviate) {
			let formatted = formatNumberWithSymbols(formattedValue);
			return hideSymbol ? formatted : `${symbol}${formatted}`;
		}

		// Format the integer part with thousand separator
		integerPart = Number(integerPart).toLocaleString();

		// Handle the decimal part
		if (!hideDecimal) {
			if (decimalPart) {
				// Convert the decimal part back to a number and round to two significant figures
				decimalPart = Number('0.' + decimalPart)
					.toPrecision(2)
					.split('.')[1];

				// Remove trailing zeros from the decimal part
				decimalPart = decimalPart?.replace(/0+$/, '') || '';
			}

			const val = decimalPart
				? `${integerPart}${decimalPart ? '.' : ''}${decimalPart}`
				: `${integerPart}.00`;

			return hideSymbol ? val : `${symbol}${val}`;
		} else {
			return hideSymbol ? integerPart : `${symbol}${integerPart}`;
		}
	} else {
		return hideSymbol ? `0.00` : `${symbol}0.00`;
	}
};

export function filterEmptyColumns<T extends Record<string, any>>(
	obj: T
): Partial<T> {
	// Create a new object to store the filtered properties
	const filteredObj: Partial<T> = {};

	// Iterate over each property in the object
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const value = obj[key];

			// Check if the value is not empty
			if (
				value !== null &&
				value !== undefined &&
				value !== '' &&
				!(Array.isArray(value) && value.length === 0) &&
				!(
					typeof value === 'object' &&
					!Array.isArray(value) &&
					Object.keys(value).length === 0
				)
			) {
				// Add the property to the filtered object
				filteredObj[key] = value;
			}
		}
	}

	return filteredObj;
}

export function formatDateHelper(date: string) {
	// console.log(date);
	var actualDate = String(moment(date).format('MMMM, DD, YYYY  — HH:mm a'));
	const splitDate = actualDate.split(',');
	var newDate =
		splitDate[0].slice(0, 3) + '.' + splitDate[1] + ',' + splitDate[2];
	return newDate;
	// const val = date ? formatTimeDifference(date) : null;
	// return val;
}

interface Header {
	label: string;
	key: string;
}

type DataRow = Record<string, any>;

function jsonToCsv(headers: Header[], data: DataRow[]): string {
	// Map the headers to their label values for the CSV header row
	const csvHeaders: string = headers.map((header) => header.label).join(',');

	// Map the data to CSV rows based on the keys from the headers
	const csvRows: string[] = data.map((row: DataRow) => {
		return headers
			.map((header: Header) => {
				const value = row[header.key];
				// Handle any potential commas or newlines in the values
				return typeof value === 'string' && value.includes(',')
					? `"${value.replace(/"/g, '""')}"`
					: value;
			})
			.join(',');
	});

	// Combine headers and rows into a single CSV string
	return [csvHeaders, ...csvRows].join('\n');
}

export function downloadCsvFile(
	headers: Header[],
	data: DataRow[],
	fileName: string
) {
	// Convert JSON to CSV
	const csvContent: string = jsonToCsv(headers, data);

	// Create a Blob from the CSV string
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

	// Create a link element
	const link = document.createElement('a');

	// Create a URL for the blob and set it as the href for the link
	const url = URL.createObjectURL(blob);
	link.href = url;

	// Set the download attribute with the filename
	link.setAttribute('download', fileName);

	// Append the link to the body (required for Firefox)
	document.body.appendChild(link);

	// Trigger the download by simulating a click
	link.click();

	// Clean up by removing the link and revoking the object URL
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
}

interface Action {
	actionId: number;
	actionName: string;
	actionDescription: string;
}

export function removeDuplicatesById(arr: Action[]): Action[] {
	const seen = new Set<number>();
	return arr.filter((item) => {
		if (seen.has(item.actionId)) {
			return false;
		}
		seen.add(item.actionId);
		return true;
	});
}

interface Policy {
	policyName: string;
	actions: any[];
}

export function filterEmptyActions(policies: Policy[]): Policy[] {
	return policies.filter((policy) => policy.actions.length > 0);
}

export function replaceUnderscoreWithSpace(text: string): string {
	return capitalizeFirstLetter(text.replaceAll(/_/g, ' '));
}

type PolicyNameType = {
	policyId: number;
	policyName: string;
	policyDescription: string;
	actions: Array<any>;
};

export function getPolicyNames(policies: PolicyNameType[]): string[] {
	return policies.map((policy) => policy.policyName);
}

export function getPermissionNames(policies: any) {
	if (policies?.length > 0) {
		return policies?.map((policy: any) => policy?.actionName);
	}
}

type User = {
	id: number;
	email: string;
	first_name: string;
	last_name: string;
	role: string;
	invitation_Status: string;
	invitation_expires_at: string | null;
	invitation_token: string | null;
	joined_at: string | null;
	deleted_at: string | null;
	partner_id: string | null;
};

export function movePendingToEnd(users: User[]): User[] {
	// Create a copy of the array to avoid mutation
	const usersCopy = [...users];

	return usersCopy.sort((a, b) => {
		if (
			a.invitation_Status === 'Pending' &&
			b.invitation_Status !== 'Pending'
		) {
			return 1; // Move 'Pending' objects to the end
		} else if (
			a.invitation_Status !== 'Pending' &&
			b.invitation_Status === 'Pending'
		) {
			return -1;
		}
		return 0; // No change in order if both are or are not 'Pending'
	});
}

type ActionType = {
	actionId: number;
	actionName: string;
	actionDescription: string;
};

type PolicyType = {
	policyId: number;
	policyName: string;
	policyDescription: string;
	actions: ActionType[];
};

export function getActionNamesByPolicyName(
	policies: PolicyType[],
	targetPolicyName: string
): string[] | null {
	// Convert targetPolicyName to lowercase to perform case-insensitive matching
	const targetLowerCase = targetPolicyName?.toLowerCase();

	// Find the policy where the policyName matches the targetPolicyName (case-insensitive)
	const foundPolicy = policies?.find(
		(policy) => policy?.policyName?.toLowerCase() === targetLowerCase
	);

	// If the policy is found, return an array of actionNames from its actions array
	if (foundPolicy) {
		return foundPolicy?.actions?.map((action) => action.actionName);
	}

	// Return null if no matching policy was found
	return null;
}
export const ADMIN_OWNER_LIST = ['owner', 'administrator'];
export const checkIfAdminOwner = (param?: string) => {
	// console.log(param);

	if (param) {
		let val;
		if (ADMIN_OWNER_LIST?.includes(param)) {
			val = true;
		} else {
			val = false;
		}
		return val;
	}
};

export function hasSpecialCharacters(input: string): boolean {
	// Regular expression to match any special characters
	const specialCharRegex = /[!@#$%^&*(),.?":{}|<>_\-+=/[\]\\~`]/;

	return specialCharRegex.test(input);
}

// @ts-ignore
function isValidElement<P>(
	object: {} | null | undefined
): object is ReactElement<P>;
export class TypeIs {
	static string = (str: unknown): str is string => typeof str === 'string';
	static any = (value: unknown) => value as any;

	/**
 *@example
 *```ts
console.log(Object.prototype.toString.call({}) = '[object Object]');
console.log(Object.prototype.toString.call([]) = '[object Array]');
console.log(Object.prototype.toString.call(null) = '[object Null]');
 *```
 * @see {@link https://bobbyhadz.com/blog/javascript-check-if-value-is-object}
 * 
 */
	static object = (value: unknown): value is object =>
		Object.prototype.toString.call(value) === '[object Object]';

	static array = (value: unknown) => Array.isArray(value);

	static number = (value: unknown): value is number =>
		typeof value === 'number';

	static reactNode = (node?: unknown): node is React.ReactNode =>
		isValidElement<any>(node);

	static stringOrNumber = (value: unknown): value is string | number =>
		this.string(value) || this.number(value);

	static custom<T>(value?: unknown) {
		return value as T;
	}

	static safe = <T>(value: IsUncertain<T>) => value as T;

	// static email = (value: unknown) => Validator.email("Type").safeParse(value).success;
}

export function convertToLowercase(inputString: string) {
	if (inputString) {
		return String(inputString).toLowerCase();
	}
}

export function identifyNetwork(phoneNumber?: string | any) {
	// Clean the phone number by removing spaces and any leading "+234" or "234"
	const cleanNumber = phoneNumber.replace(/^\+?234/, '').replace(/\s+/g, '');

	// Ensure the number starts with a 0 after removing the country code
	const normalizedNumber = cleanNumber.startsWith('0')
		? cleanNumber
		: '0' + cleanNumber;

	// Network prefixes mapped to their respective networks
	const networkPrefixes = {
		MTN: [
			'0703',
			'0706',
			'0803',
			'0806',
			'0810',
			'0813',
			'0814',
			'0816',
			'0903',
			'0906',
			'0913',
			'0916',
			'07025',
			'07026',
			'0704',
		],
		GLO: ['0705', '0805', '0807', '0811', '0815', '0905', '0915'],
		AIRTEL: [
			'0701',
			'0708',
			'0802',
			'0808',
			'0812',
			'0902',
			'0907',
			'0912',
			'0917',
		],
		MOBILE: ['0809', '0817', '0818', '0909', '0908', '0918'],
	};

	// Loop through the prefixes and return the corresponding network
	for (const [network, prefixes] of Object.entries(networkPrefixes)) {
		if (prefixes.some((prefix) => normalizedNumber.startsWith(prefix))) {
			return network;
		}
	}

	return 'Unknown Network';
}

export function convertToHumanReadable(inputString: string) {
	// Split the input string by underscores
	const words = inputString.split('_');

	// Capitalize the first letter of each word and join them with spaces
	const humanReadableString = words
		.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

	return humanReadableString;
}
