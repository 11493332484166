import React, { useEffect } from 'react';
import './AuthOneLayoutWrap.css';
import hometown from '../../assets/images/hometown.png';
import raven from '../../assets/images/raven-logo-green.png';
import paymable from '../../assets/images/paymable.png';
import LegacySwitch from './LegacySwitch';
import { getCookie } from '../../utils/helper/Helper';
import { useNavigate } from 'react-router-dom';
import { ALL_ROUTES } from '../../routes/dashboard/overview/AllPathNames';
interface MyComponentProps {
	children?: React.ReactNode;
	className?: string;
}

const AuthTwoLayoutWrap: React.FC<MyComponentProps> = ({
	children,
	className,
}) => {
	const navigate = useNavigate();

	async function authCheckpoint() {
		const auth = getCookie('token');

		const matches = location.href.includes('/login');

		if (auth && auth.length > 5 && matches) {
			navigate(ALL_ROUTES?.OVERVIEW);
		}
	}

	useEffect(() => {
		authCheckpoint();
	}, []);
	return (
		<div className={`auth-layout-wrap ${className}`}>
			{/* left wrap start */}

			<div className={`left-wrap left-wrap-two`}>
				<>
					<div className="text-social-link-box">
						<div className="small-text-box">
							<p className="text">TRUSTED COMPANIES</p>
						</div>
						<div  style={{width: "100%"}} className="round-icon-box">
							<figure className="img-box">
								
								<img src={raven} alt="" />
							</figure>
							<figure className="img-box">
								{' '}
								<img src={hometown} alt="" />
							</figure>
							{/* <figure className="img-box">
								{' '}
								<img src={paymable} alt="" />
							</figure> */}
						</div>
					</div>
				</>
			</div>
			{/* left wrap end */}
			{/* right wrap start */}
			<div className="right-wrap right-wrap-login">
				<div className="wrap">{children}</div>
			</div>
			{/* right wrap end */}
		</div>
	);
};

export default AuthTwoLayoutWrap;
