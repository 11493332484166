import React from 'react';
import Pagination from '../../../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../../../components/common/smartFilter';
import SingleUserLayout from '../../SingleUserLayout';
import UserTerminalDetails from './TerminalDetailsIndex';
import { UserTerminalDetail } from './TerminalDetailsIndex';

const UserTerminals = () => {
  const userTerminalDetails: UserTerminalDetail[] = [
    {
      
      name: 'Terminal Alpha',
      serialNo: 1001,
      assignee: 'John Doe',
      volume: 120,
    },
    {
     
      name: 'Terminal Beta',
      serialNo: 1002,
      assignee: 'Jane Smith',
      volume: 98,
    },
    {
      
      name: 'Terminal Gamma',
      serialNo: 1003,
      assignee: 'Emily Johnson',
      volume: 75,
    },
    {
     
      name: 'Terminal Delta',
      serialNo: 1004,
      assignee: 'Michael Brown',
      volume: 150,
    },
    {
      name: 'Terminal Epsilon',
      serialNo: 1005,
      assignee: 'Sarah Wilson',
      volume: 130,
    },
    {
      
      name: 'Terminal Zeta',
      serialNo: 1006,
      assignee: 'David Lee',
      volume: 110,
    },
  ];

  return (
    <div>
    
    
        <div>
          <UserTerminalDetails userDetails={userTerminalDetails} />
        </div>
   
    </div>
  );
};

export default UserTerminals;
