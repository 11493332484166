import { RavenButton, RavenModal } from '@ravenpay/raven-bank-ui';
import React from 'react';
import '../../components/modal/styles/Settoapprove.css';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
	singleChild?: any;
	// style?: React.CSSProperties;
}

const SettoApproved = ({ onClose, visible, singleChild }: MyComponentProps) => {
	return (
		<>
			<div className="">
				<RavenModal
					visble={visible}
					onClose={
						() => {
							onClose && onClose();
						}
						// setShowModal((prev) => ({ ...prev, Set_to_In_Approved: false }))
					}
					onBtnClick={() => {
						// console.log('Button inside modal clicked');
					}}
					className=""
				>
					<div className="modal-container">
						{/* Modal Header */}
						<div className="modal-header">
							<h3 className="modal-title">Set to Approved?</h3>
						</div>

						{/* Modal Body */}
						<div className="modal-body">
							<p>
								Are you sure to set this device request to approved ? <br />
								You can always come back to perform the action.
							</p>
						</div>

						{/* Modal Footer */}
						<div className="modal-footer">
							<RavenButton
								color="deep-green"
								textColor="black-light"
								label="No, Cancel"
								loading={false}
								size="big"
								onClick={() => {
									// console.log('Item deleted!');
								}}
							/>

							<RavenButton
								color="deep-green-light"
								label="Yes, Continue"
								loading={false}
								size="big"
								onClick={() => {
									// console.log('Item deleted!');
								}}
							/>
						</div>
					</div>
				</RavenModal>
			</div>
		</>
	);
};

export default SettoApproved;
