import React, { useState } from 'react';
import { ALL_ROUTES } from '../../../../../../routes/dashboard/overview/AllPathNames';
import { useOnClickOutside } from '../../../../../../utils/helper/UseOnClickOutside';
import { useDarkMode } from '../../../../../../hooks/useDarkMode';

interface myComponentProps {
	activeTabIndex?: string;
	setActiveTabIndex?: (param?: any) => void;
}

const SingleMerchantTabLink = ({
	activeTabIndex,
	setActiveTabIndex,
}: myComponentProps) => {
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	// State to track the active tab index, initially set to the first tab

	// List of tabs
	const singleuserList = [
		{
			name: 'Transaction',
			active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_TRANSACTION,
			path: ALL_ROUTES?.SINGLE_MERCHANT_TRANSACTION,
			onClick: () =>
				setActiveTabIndex &&
				setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_TRANSACTION),
		},

		{
			name: 'Biils',
			active: [
				ALL_ROUTES?.SINGLE_MERCHANT_BILLS_AIRTIME,
				ALL_ROUTES?.SINGLE_MERCHANT_BILLS_DATA,
				ALL_ROUTES?.SINGLE_MERCHANT_BILLS_CABLE,
				ALL_ROUTES?.SINGLE_MERCHANT_ElECTRICITY,
				ALL_ROUTES?.SINGLE_MERCHANT_BETTING,
			]?.includes(activeTabIndex as string),
			path: ALL_ROUTES?.SINGLE_MERCHANT_BILLS_AIRTIME,
			onClick: () =>
				setActiveTabIndex &&
				setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_BILLS_AIRTIME),

			children: [
				{
					name: 'Airtime',
					active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_BILLS_AIRTIME,
					path: ALL_ROUTES?.SINGLE_MERCHANT_BILLS_AIRTIME,
					onClick: () =>
						setActiveTabIndex &&
						setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_BILLS_AIRTIME),
				},
				{
					name: 'Data',
					active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_BILLS_DATA,
					path: ALL_ROUTES?.SINGLE_MERCHANT_BILLS_DATA,
					onClick: () =>
						setActiveTabIndex &&
						setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_BILLS_DATA),
				},
				{
					name: 'Cable',
					active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_BILLS_CABLE,
					path: ALL_ROUTES?.SINGLE_MERCHANT_BILLS_CABLE,
					onClick: () =>
						setActiveTabIndex &&
						setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_BILLS_CABLE),
				},
				{
					name: 'Electricity',
					active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_ElECTRICITY,
					path: ALL_ROUTES?.SINGLE_MERCHANT_ElECTRICITY,
					onClick: () =>
						setActiveTabIndex &&
						setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_ElECTRICITY),
				},
				{
					name: 'Betting',
					active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_BETTING,
					path: ALL_ROUTES?.SINGLE_MERCHANT_BETTING,
					onClick: () =>
						setActiveTabIndex &&
						setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_BETTING),
				},
			],
		},

		{
			name: 'Terminals',
			active: activeTabIndex === ALL_ROUTES?.SINGLE_USER_TERMINAL,
			path: ALL_ROUTES?.SINGLE_USER_TERMINAL,
			onClick: () =>
				setActiveTabIndex &&
				setActiveTabIndex(ALL_ROUTES?.SINGLE_USER_TERMINAL),
		},
		{
			name: 'Device Requests',
			active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_DEVICE_REQUEST,
			path: ALL_ROUTES?.SINGLE_MERCHANT_DEVICE_REQUEST,
			onClick: () =>
				setActiveTabIndex &&
				setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_DEVICE_REQUEST),
		},
		// {
		// 	name: 'User Details',
		// 	active: activeTabIndex === 'dashboard-single-merchant-detail-table',
		// 	path: '/dashboard-single-merchant-detail-table',
		// 	onClick: () =>
		// 		setActiveTabIndex &&
		// 		setActiveTabIndex('/dashboard-single-merchant-detail-table'),
		// },

		// {
		// 	name: 'Payment Links',
		// 	active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_PAYMENT_LINK,
		// 	path: ALL_ROUTES?.SINGLE_MERCHANT_PAYMENT_LINK,
		// 	onClick: () =>
		// 		setActiveTabIndex &&
		// 		setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_PAYMENT_LINK),
		// },

		{
			name: 'Settlements',
			active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_SETTLEMENTS,
			path: ALL_ROUTES?.SINGLE_MERCHANT_SETTLEMENTS,
			onClick: () =>
				setActiveTabIndex &&
				setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_SETTLEMENTS),
		},
		{
			name: 'Dispute',
			active: activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_DISPUTE,
			path: ALL_ROUTES?.SINGLE_MERCHANT_DISPUTE,
			onClick: () =>
				setActiveTabIndex &&
				setActiveTabIndex(ALL_ROUTES?.SINGLE_MERCHANT_DISPUTE),
		},
		// {
		// 	name: 'Compliance',
		// 	active: activeTabIndex === ALL_ROUTES?.COMPLIANCE_CARDLIST,
		// 	path: ALL_ROUTES?.COMPLIANCE_CARDLIST,
		// 	onClick: () =>
		// 		setActiveTabIndex && setActiveTabIndex(ALL_ROUTES?.COMPLIANCE_CARDLIST),
		// },

		// {
		// 	name: 'Payment Link Table',
		// 	active: activeTabIndex === ALL_ROUTES?.SINGLE_USER_LINK_TABLE,
		// 	path: ALL_ROUTES?.SINGLE_USER_LINK_TABLE,
		// 	onClick: () =>
		// 		setActiveTabIndex &&
		// 		setActiveTabIndex(ALL_ROUTES?.SINGLE_USER_LINK_TABLE),
		// },
	];

	const [activeDrop, setActiveDrop] = useState<string>('');
	const [activeDropItem, setActiveDropItem] = useState<string>('');

	const testingSettingPreviewRef = useOnClickOutside(() => {
		setActiveDrop('');
	});

	const getDropList = (list?: any) => {
		if (list?.length > 0) {
			const newList = list?.filter((chi: any) => !chi?.active);
			return newList;
		}
		// console.log(ne);
	};

	const getActiveDropItem = (list?: any, val?: any) => {
		if (list?.length > 0) {
			const newList = list?.find((chi: any) => chi?.name === val);
			return newList?.name;
		}
		// console.log(ne);
	};

	const dropIcon = (color: any) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18"
				height="18"
				fill="none"
				viewBox="0 0 18 18"
			>
				<path
					stroke={color || theme === 'light' ? '#000' : '#ffffff'}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.5"
					d="m4.5 6.75 4.5 4.5 4.5-4.5"
				></path>
			</svg>
		);
	};

	return (
		<>
			<div className="reuseable-wrapper-route-path-wrap-box border-theme-bottom">
				{singleuserList.map((tab, idx) => {
					if (Number(tab?.children?.length) > 0) {
						return (
							<div
								className={`item-row ${tab?.active && 'item-row-active'}`}
								key={idx}
								onClick={() => {
									if (activeDrop === tab.name) {
										setActiveDrop('');
									} else {
										setActiveDrop(tab?.name);
									}
								}}
								ref={testingSettingPreviewRef}
							>
								<div className="name name-children grey-white-color">
									{`${tab.name} ${
										getActiveDropItem(tab?.children, activeDropItem)
											? `: ${activeDropItem}`
											: ''
									}`}{' '}
									<figure className="img-box">
										{dropIcon(
											getActiveDropItem(tab?.children, activeDropItem)
												? 'rgba(1, 67, 69, 1)'
												: ''
										)}
									</figure>
								</div>
								{/* drop box start */}
								{activeDrop && activeDrop === tab.name && (
									<div className="drop-box-wrap more-wrap-drop-reuse white-base-black-bg border-theme">
										{getDropList(tab?.children)?.map(
											(child: any, index: any) => {
												return (
													<p
														onClick={() => {
															if (!child?.active) {
																child?.onClick && child?.onClick();
																setActiveDropItem(child?.name);
																setActiveDrop('');
															}
														}}
														key={index}
														className="child-name border-theme-bottom"
													>
														{child?.name}
													</p>
												);
											}
										)}
									</div>
								)}
								{/* drop box end */}
							</div>
						);
					}
					return (
						<div
							className={`item-row ${tab?.active && 'item-row-active'}`}
							key={idx}
							onClick={() => {
								if (!tab?.active) {
									tab?.onClick && tab?.onClick();
									setActiveDropItem('');
								}
							}}
						>
							<p className="name grey-white-color">{`${tab.name}`}</p>
						</div>
					);
				})}
				{/* <div className="right-box-btn">{btnContent || ''}</div> */}
			</div>
		</>
	);
};

export default SingleMerchantTabLink;
