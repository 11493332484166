import React from 'react';
import QRCode from './QR Code.png';
import './styles/paymentLinksComponentIndex.css';
import { trimLongString } from '../../../../../../../utils/helper/Helper';
export interface paymentLinkProps {
	name: string;
	subscriptionName: string;
	paymentLink: string;
	status: string;
	charges: string;
}
export interface PaymentLinksComponentProps {
	paymentLinks: paymentLinkProps[]; // Array of payment links
}
const PaymentLinksComponent: React.FC<PaymentLinksComponentProps> = ({
	paymentLinks,
}) => {
	const pinIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			className="img"
		>
			<path
				stroke="#8B8B8B"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M8.377 15.616 2.72 21.273m8.974-14.631-1.56 1.56a2 2 0 0 1-.264.242 1 1 0 0 1-.207.11c-.082.033-.17.05-.347.086l-3.665.733c-.952.19-1.428.286-1.65.537a1 1 0 0 0-.243.8c.046.333.389.677 1.076 1.363l7.086 7.086c.686.687 1.03 1.03 1.362 1.076a1 1 0 0 0 .801-.242c.251-.223.346-.7.537-1.651l.733-3.665c.035-.176.053-.265.085-.347q.044-.11.11-.207c.051-.073.115-.136.242-.264l1.561-1.56c.082-.082.122-.123.167-.158a1 1 0 0 1 .126-.085 2 2 0 0 1 .208-.097l2.495-1.069c.727-.312 1.091-.468 1.256-.72a1 1 0 0 0 .144-.747c-.06-.295-.34-.575-.9-1.135l-5.143-5.143c-.56-.56-.84-.84-1.135-.9a1 1 0 0 0-.747.144c-.252.166-.408.53-.72 1.257l-1.069 2.495a2 2 0 0 1-.096.208 1 1 0 0 1-.085.126 2 2 0 0 1-.158.167"
			></path>
		</svg>
	);
	const messageIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			fill="none"
			viewBox="0 0 20 20"
			className="img"
		>
			<path
				stroke="#ACACAC"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M6.25 2.5h5.917c1.867 0 2.8 0 3.513.363.627.32 1.137.83 1.457 1.457.363.713.363 1.646.363 3.513v5.917M5.167 17.5h6.75c.933 0 1.4 0 1.756-.182.314-.16.569-.414.729-.728.181-.357.181-.823.181-1.757v-6.75c0-.933 0-1.4-.181-1.756a1.67 1.67 0 0 0-.729-.729c-.356-.181-.823-.181-1.756-.181h-6.75c-.934 0-1.4 0-1.757.181-.314.16-.569.415-.728.729-.182.356-.182.823-.182 1.756v6.75c0 .934 0 1.4.182 1.757.16.314.414.569.728.728.357.182.823.182 1.757.182"
			></path>
		</svg>
	);

	return (
		<div className="payment_links_component_container_wrap">
			{paymentLinks.map((item, index) => (
				<div
					key={index}
					className="payment_links_component_container_wrap_content border-theme"
				>
					<div className="payment_links_component_container_wrap_content_inner">
						<div className="qrCode_holder">
							<img className=" img-box" src={QRCode} alt="qr code" />
						</div>
						<div className="payment_links_component_container_wrap_content_box">
							<div className="payment_links_component_container_wrap_content_name_and_pin ">
								<div>
									<p className="payment_link_name">{trimLongString(item.name, 23)}</p>
									<p  className="payment_link_subscription_name">{trimLongString(item.subscriptionName,23)}</p>
								</div>
								<div>
									<figure>{pinIcon}</figure>
								</div>
							</div>
							<div className="payment_links_component_container_wrap_content_link_and_messageicon single-merchant-bg-color">
								<p>{trimLongString(item.paymentLink,30)}</p>
								<div>
									<figure>{messageIcon}</figure>
								</div>
							</div>
						</div>
					</div>
          <div className='status_and_charge_box border-theme-top'>
          <p className='payment_link_status_box '>{item.status}</p>
					<p className='payment_link_charge_box'>{item.charges}</p>
          </div>
				
				</div>
			))}
		</div>
	);
};
export default PaymentLinksComponent;
