import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import './styles/index.css';
import { RavenButton, RavenInputField } from '@ravenpay/raven-bank-ui';
import uploadPlaceholder from '../../../../../assets/images/upload-logo-placeholder.png';
import { icons } from '../../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState from '../../../../../redux/types';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';
import { businessCategories } from '../../data/businessCategory';
import { nigerianStates } from '../../data/statesInNigeria';
import { RavenInputProps } from '@ravenpay/raven-bank-ui/dist/esm/components/types';
import {
	capitalizeAllLetter,
	formatNumWithComma,
	insertUrlParam,
	reactSelectStyle,
	symbol,
} from '../../../../../utils/helper/Helper';
import { useNavigate } from 'react-router-dom';

const UpdateMerchantFeeUserModal = ({
	onComplete,
	detail,
	visible,
	feeDetail,
	setFeeDetail,
}: {
	onComplete: () => void;
	detail?: any;
	visible?: boolean;
	feeDetail?: any;
	setFeeDetail?: (param: any) => void;
}) => {
	const [step, setStep] = React.useState<{
		id: string;
		completed: string[];
		label: string;
	}>({
		id: 'personal',
		completed: [],
		label: 'Personal Details',
	});

	// const { all_fees_no_page } = useSelector((state: RootState) => state.bankbox);
	// const fileInputRef = useRef<HTMLInputElement>(null);
	// const imgPreviewRef = useRef<HTMLImageElement>(null);
	const { all_fees_no_page, partner_info } = useSelector(
		(state: RootState) => state.bankbox
	);
	const { profile } = useSelector((state: RootState) => state.settings);
	const [formData, setFormData] = React.useState({
		fname: '',
		lname: '',
		bvn: '',
		nin: '',
		email: '',
		phone: '',
		business_category: { value: undefined, label: undefined },
		business_description: '',
		region: { value: undefined, label: undefined },
		business_name: '',
		business_address: '',
		fee_category: { value: undefined, label: undefined } as any,
	});

	// useS

	const { loading } = useSelector((state: RootState) => state.bankbox);
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
	};
	const dispatch = useDispatch();
	const handleSubmit = async () => {
		// console.log(formData?.fee_category);

		const val = {
			email: detail?.poseidon_email,
			fee_ref: newFee?.reference,
		};
		// console.log(val);
		// console.log(detail);

		// log
		const resp = await dispatch(bankboxAPI.updateUserFee(val));

		if (resp.payload?.status === 'success') {
			// await dispatch(
			// 	bankboxAPI.getAllUsers({
			// 		per_page: 20,
			// 		page: 1,
			// 	})
			// );
			// handleViewUser(detail)
			// dispatch(
			// 	bankboxAPI.getSingleUser({ email: detail?.poseidon_email as string })
			// );
			setFormData((prev) => {
				return {
					...prev,
					fname: '',
					lname: '',
					bvn: '',
					nin: '',
					email: '',
					phone: '',
					business_category: { value: undefined, label: undefined },
					business_description: '',
					region: { value: undefined, label: undefined },
					business_name: '',
					business_address: '',
					fee_category: { value: undefined, label: undefined } as any,
				};
			});
			onComplete();
		}
	};
	// const navigate = useNavigate();
	// const handleViewUser = (chi: any) => {
	// 	// setViewUser({
	// 	// 	on: true,
	// 	// 	chi: chi,
	// 	// });
	// 	navigate('?user_email=' + chi.poseidon_email);
	// 	insertUrlParam(
	// 		'user_email',
	// 		chi.poseidon_email,
	// 		`Atlas: BankBox - #${chi.fname + ' ' + chi.lname}`
	// 	);
	// };

	const getFeeObj = (param: any) => {
		// console.log(all_fees_no_page);

		const newObj = { ...param, label: param?.reference, value: param?.id };
		// console.log(newObj);
		return newObj;
	};

	// const brandColors = ['0B8376', '0B8376', 'EA872D', 'B9DEFE'];

	const formatFeeCategory = () => {
		// console.log(all_fees_no_page);

		if (all_fees_no_page?.length > 0) {
			const newList = all_fees_no_page?.map((chi: any) => {
				return { ...chi, label: chi?.reference, value: chi?.id };
			});
			return newList;
		}
	};
	const [newFee, setNewFee] = useState<any>({});
	// const [verificationType, setVerificationType] = useState('bank');

	return (
		<div
			// onClick={() => {
			// 	console.log(detail);
			// 	// console.log(all_fees_no_page);
			// }}
			className="bankbox-modals"
			style={{ width: '50rem' }}
		>
			<div className="bankbox-modals__right">
				{/* {step.id === 'fees' && ( */}
				<>
					<div className="bankbox-modals__right--content">
						<div
							style={{ marginBottom: '1rem', marginTop: '2rem' }}
							className="basic-content__title"
						>
							<h5> Merchant Fee Details</h5>
							{/* <h6>{step.label}</h6> */}
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								type="select"
								color="deep-green-light"
								// disabled
								value={
									Object?.keys(newFee)?.length < 1
										? getFeeObj(feeDetail)
										: newFee
								}
								label="Fee Category"
								style={{ position: 'relative', zIndex: 10 }}
								placeholder="Select Fee Category"
								name="fee_category"
								selectStyles={reactSelectStyle}
								selectOption={formatFeeCategory()}
								onChange={(e: any) => {
									// console.log(feeDetail);
									// console.log(e);

									setNewFee(getFeeObj(e));
									// setFormData((prev) => {
									// 	return { ...prev, fee_category: e };
									// });
									// setFeeDetail && setFeeDetail(e)
								}}
							/>
						</div>
						<div className="price-content__selected-more-details-box border-theme">
							{/* value label box start */}
							<div className="label-boxes border-theme-right">
								<p className="label border-theme-bottom grey-white-color">
									Rate
								</p>
								<p className="label border-theme-bottom grey-white-color">
									Cap
								</p>
								<p className="label border-theme-bottom grey-white-color">
									Trf Fee:
								</p>
								<p className="label border-theme-bottom grey-white-color">
									Collection Fee:
								</p>
							</div>
							{/* value label box end */}
							{/* value boxes start */}
							<div className="value-boxes">
								{newFee?.fee ? (
									<p className="value border-theme-bottom">
										{newFee?.fee ? (
											`${newFee?.fee}${newFee?.fee ? '%' : ''}`
										) : (
											<>&nbsp;</>
										)}
									</p>
								) : (
									<p className="value border-theme-bottom">
										{feeDetail?.fee || '---'}%
									</p>
								)}
								<p className="value border-theme-bottom">
									{newFee?.cap ? (
										`${symbol('ngn') + formatNumWithComma(newFee?.cap, 'ngn')}`
									) : (
										<>
											{symbol('ngn') +
												formatNumWithComma(feeDetail?.cap || '', 'ngn')}
										</>
									)}
								</p>
								<p className="value border-theme-bottom">
									{' '}
									{newFee?.transfer_fee ? (
										`${
											symbol('ngn') +
											formatNumWithComma(newFee?.transfer_fee, 'ngn')
										}`
									) : (
										<>
											{symbol('ngn') +
												formatNumWithComma(
													feeDetail?.transfer_fee || '',
													'ngn'
												)}
										</>
									)}
								</p>
								<p className="value ">
									{' '}
									{newFee?.bank_collection_fee ? (
										`${
											symbol('ngn') +
											formatNumWithComma(newFee?.bank_collection_fee, 'ngn')
										}`
									) : (
										<>
											{symbol('ngn') +
												formatNumWithComma(
													feeDetail?.bank_collection_fee || '',
													'ngn'
												)}
										</>
									)}
								</p>
							</div>
							{/* value boxes end */}
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								onClick={handleSubmit}
								color="deep-green-light"
								label="Update Merchant fee"
								disabled={
									Object?.keys(newFee)?.length < 1 ||
									String(getFeeObj(feeDetail)?.id) === String(newFee?.id)
								}
								loading={loading}
								loaderText="Uppdating fee..."
							/>{' '}
						</div>
					</div>
				</>
			</div>
		</div>
	);
};

export default UpdateMerchantFeeUserModal;
