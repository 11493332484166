import React from 'react';
import { trimLongString } from '../../utils/helper/Helper';
import Copy from './copyCheck';

interface MyComponentProps {
	text?: string;
	trimnumber?: number;
	copyText?: string;
}

const CopyFragment = ({ copyText, text, trimnumber }: MyComponentProps) => {
	return (
		<p
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '.4rem',
			}}
		>
			{trimLongString(text, 20)}
			<Copy text={copyText || text || String(trimnumber || 'N/A')} />
		</p>
	);
};

export default CopyFragment;
