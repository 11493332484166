import React, { useState } from 'react';
import './styles/Index.css';
import { icons, illustrations } from '../../../../../assets/icons';
import CurrencySelector from '../../../../../components/common/currencySelector';
import {
	capitalizeAllLetter,
	capitalizeFirstWord,
	getActionNamesByPolicyName,
	trimLongString,
} from '../../../../../utils/helper/Helper';
import { RavenNumberFormat, RavenToolTip } from '@ravenpay/raven-bank-ui';
import ImageFragment from '../../../../../components/common/ImageFragment';
import RootState, { BankBoxUser } from '../../../../../redux/types';
import { useSelector } from 'react-redux';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';
import CheckUrlFragment from '../../../../../components/common/CheckUrlCodeFragment';
import { useDarkMode } from '../../../../../hooks/useDarkMode';
import Skeleton from 'react-loading-skeleton';

interface UserDetails {
	logo: string;
	businessName: string;
	ownerName: string;
	email: string;
	dateJoined: string;
	bvn: string;
	nin: string;
	category: string;
	region: string;
	address: string;
	description: string;
	walletBalance: string;
	lien: string;
}

interface BusinessInfoCardProps {
	userDetails: UserDetails;
	setAssignBankbox?: (param: any) => void;
	assignBankbox?: any;
	setViewSettleMent?: (param: any) => void;
	setViewFeeDetails?: (param: any) => void;
	setSingleChild?: (param: any) => void;
	setEditMerchantModal?: (param: any) => void;
	setRequestSettleMerchantModal?: (param: any) => void;
}

const bankIcon = (
	<svg
		className="img"
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.5 4.50001V8.50001M4.75 4.50001V8.50001M7.25 4.50001V8.50001M9.5 4.50001V8.50001M1.5 9.30001L1.5 9.70001C1.5 9.98004 1.5 10.12 1.5545 10.227C1.60243 10.3211 1.67892 10.3976 1.773 10.4455C1.87996 10.5 2.01997 10.5 2.3 10.5H9.7C9.98003 10.5 10.12 10.5 10.227 10.4455C10.3211 10.3976 10.3976 10.3211 10.4455 10.227C10.5 10.12 10.5 9.98004 10.5 9.70001V9.30001C10.5 9.01998 10.5 8.87997 10.4455 8.77302C10.3976 8.67893 10.3211 8.60244 10.227 8.55451C10.12 8.50001 9.98003 8.50001 9.7 8.50001H2.3C2.01997 8.50001 1.87996 8.50001 1.773 8.55451C1.67892 8.60244 1.60243 8.67893 1.5545 8.77301C1.5 8.87997 1.5 9.01998 1.5 9.30001ZM5.82646 1.53858L2.12646 2.3608C1.90293 2.41047 1.79116 2.43531 1.70773 2.49541C1.63415 2.54843 1.57636 2.62046 1.54057 2.70379C1.5 2.79827 1.5 2.91276 1.5 3.14175L1.5 3.70001C1.5 3.98004 1.5 4.12005 1.5545 4.22701C1.60243 4.32109 1.67892 4.39758 1.773 4.44551C1.87996 4.50001 2.01997 4.50001 2.3 4.50001H9.7C9.98003 4.50001 10.12 4.50001 10.227 4.44551C10.3211 4.39758 10.3976 4.32109 10.4455 4.22701C10.5 4.12005 10.5 3.98004 10.5 3.70001V3.14175C10.5 2.91276 10.5 2.79827 10.4594 2.70379C10.4236 2.62046 10.3659 2.54843 10.2923 2.49541C10.2088 2.43531 10.0971 2.41047 9.87354 2.3608L6.17354 1.53858C6.10878 1.52418 6.0764 1.51699 6.04368 1.51412C6.01461 1.51157 5.98538 1.51157 5.95632 1.51412C5.9236 1.51699 5.89122 1.52418 5.82646 1.53858Z"
			stroke="#014345"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

const UserDetailsInfo: React.FC<BusinessInfoCardProps> = ({
	userDetails,
	assignBankbox,
	setAssignBankbox,
	setViewSettleMent,
	setViewFeeDetails,
	setEditMerchantModal,
	setSingleChild,
	setRequestSettleMerchantModal,
}) => {
	const { profile } = useSelector((state: RootState) => state.settings);
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const [el, setEl] = React.useState<any>({
		id: 0,
		fname: '',
		lname: '',
		poseidon_email: '',
		bvn: '',
		nin: '',
		phone: '',
		affiliate_app: '',
		affiliate_app_id: '',
		poseidon_business_name: '',
		poseidon_business_address: '',
		poseidon_business_type: null,
		poseidon_business_description: '',
		poseidon_profile_reference: '',
		business_category: '',
		region: '',
		created_at: '',
		updated_at: '',
		ref_fee: '',
	});
	const MAX_LENGTH = 26;

	const { user_merchant, user_merchant_settlement, loading } = useSelector(
		(state: RootState) => state.bankbox
	);
	const userDetailsList = [
		{
			label: 'Business Name',
			value: capitalizeAllLetter(el?.poseidon_business_name) || '---',
		},
		{
			label: 'Owner’s Fullname',
			value:
				`${capitalizeFirstWord(el?.fname) || ''} ${el?.lname || ''}` || '---',
		},
		{ label: 'Business Email Address', value: el?.poseidon_email || '---' },
		{
			label: 'Business Phone',
			value: el?.phone ? `+234 ${el?.phone || '---'}` : '---',
		},
		{ label: 'Business Category', value: el?.business_category || '---' },
		{
			label: 'Region',
			value: el?.region ? `${capitalizeFirstWord(el?.region)} State` : '---',
		},
		{
			label: 'Business Address',
			value: el?.poseidon_business_address || '---',
		},
		{
			label: 'Business Description',
			value: el?.poseidon_business_description || '---',
		},
		{
			label: 'Fee Reference',
			value: capitalizeFirstWord(user_merchant?.fees?.reference) || '----',
		},
		{
			label: 'Transaction Fee',
			value: user_merchant?.fees?.fee
				? `${RavenNumberFormat(user_merchant?.fees?.fee, {
						hideDecimal: false,
						hideSymbol: true,
				  })}%`
				: '----',
		},
		{
			label: 'Fee Cap',
			value: RavenNumberFormat(user_merchant?.fees?.cap) || '----',
		},
		{
			label: 'Collection Fee',
			value:
				RavenNumberFormat(user_merchant?.fees?.bank_collection_fee) || '----',
		},
		{ label: 'BVN', value: el?.bvn || '---', hide: !el?.bvn },
		{ label: 'NIN', value: el?.nin || '---', hide: !el?.nin },
		{ label: 'Date Joined', value: el?.created_at, date: true },
		{
			label: 'Settlement Details',
			value: '',
			settlement: true,
		},
	];

	React.useEffect(() => {
		setEl(user_merchant?.user);
	}, [user_merchant]);

	const balanceList = [
		{
			label: 'Total Wallet Balance',
			value: RavenNumberFormat(user_merchant?.user?.wallet_balance),
			curIcon: icons.currencyIcon,
			amount: true,
		},
		{
			label: 'Total Transaction Count',
			value: RavenNumberFormat(user_merchant?.transaction_info?.total_count, {
				hideDecimal: true,
				hideSymbol: true,
			}),
			amount: false,
			// curIcon: icons.currencyIcon,
		},
		{
			label: 'Lien',
			value: '0',
			labelColor: 'rgba(255, 15, 0, 1)',
			amount: true,
		},
	];

	const editIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
				stroke={theme === 'light' ? `rgba(139, 139, 139, 1)` : `#ffffff`}
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const arrowIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				// className="dull-bright-filter"
				d="M6 18L18 6M18 6H10M18 6V14"
				stroke={theme === 'light' ? `rgba(139, 139, 139, 1)` : `#ffffff`}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const assignIcon = (
		<svg
			className="img"
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.5 4.5L8 3M8 3L9.5 1.5M8 3L11 3M8 10.5V9.9C8 9.05992 8 8.63988 7.83651 8.31901C7.6927 8.03677 7.46323 7.8073 7.18099 7.66349C6.86012 7.5 6.44008 7.5 5.6 7.5H3.4C2.55992 7.5 2.13988 7.5 1.81901 7.66349C1.53677 7.8073 1.3073 8.03677 1.16349 8.31901C1 8.63988 1 9.05992 1 9.9V10.5M6.25 3.75C6.25 4.7165 5.4665 5.5 4.5 5.5C3.5335 5.5 2.75 4.7165 2.75 3.75C2.75 2.7835 3.5335 2 4.5 2C5.4665 2 6.25 2.7835 6.25 3.75Z"
				stroke={theme === 'light' ? `rgba(139, 139, 139, 1)` : `#ffffff`}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const actionList = [
		{
			icon: editIcon,
			label: 'Edit Merchant',
			onClick: () => {
				setSingleChild && setSingleChild(el);
				setEditMerchantModal && setEditMerchantModal(true);
			},
			hide: !getActionNamesByPolicyName(
				profile?.rolePolicies?.policies,
				'merchants'
			)?.includes('edit_merchant'),
		},
		{
			icon: assignIcon,
			label: ' Assign BankBox',
			onClick: () => {
				setAssignBankbox &&
					setAssignBankbox({
						...assignBankbox,
						on: true,
					});
			},
			hide: getActionNamesByPolicyName(
				profile?.rolePolicies?.policies,
				'terminals'
			)?.includes('assign_terminal')
				? false
				: true,
		},
		{
			icon: illustrations.settlementicon,
			label: ' Fee Details',
			onClick: () => {
				setViewFeeDetails && setViewFeeDetails(true);
			},
			hide: getActionNamesByPolicyName(
				profile?.rolePolicies?.policies,
				'fee details'
			)?.includes('change_fees')
				? false
				: true,
		},

		{
			hide:
				getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'settlement'
				)?.includes('add_merchant_settlement_account') ||
				getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'settlement'
				)?.includes('edit_merchant_settlement_account')
					? false
					: true,
			icon: illustrations.settlementicon,
			label: 'Settlement Details',
			onClick: () => {
				setViewSettleMent && setViewSettleMent(true);
			},
		},
		{
			icon: arrowIcon,
			label: 'Request Settlement ',
			onClick: (param?: any) => {
				setRequestSettleMerchantModal && setRequestSettleMerchantModal(true);
				// setShowDrop(false);
			},
			hide: Object?.keys(user_merchant_settlement)?.length < 1 ? true : false,
		},
	];

	const merchantLogo = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="180"
			height="180"
			fill="none"
			viewBox="0 0 180 180"
			className="img"
		>
			<path
				fill="#D1D1D1"
				d="M34.5 82.5h21.75v-36c0-8.4 0-12.601 1.635-15.81a15 15 0 0 1 6.555-6.555C67.65 22.5 71.85 22.5 80.25 22.5h19.5c8.401 0 12.601 0 15.81 1.635a15 15 0 0 1 6.555 6.555c1.635 3.209 1.635 7.41 1.635 15.81v36h21.75c4.2 0 6.301 0 7.905.817a7.5 7.5 0 0 1 3.278 3.278c.817 1.604.817 3.705.817 7.905v63h-135v-63c0-4.2 0-6.3.817-7.905a7.5 7.5 0 0 1 3.278-3.278C28.2 82.5 30.3 82.5 34.5 82.5"
			></path>
			<path
				stroke="#F7F7F7"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="4"
				d="M56.25 82.5H34.5c-4.2 0-6.3 0-7.905.817a7.5 7.5 0 0 0-3.277 3.278C22.5 88.2 22.5 90.3 22.5 94.5v63m101.25-75h21.75c4.2 0 6.301 0 7.905.817a7.5 7.5 0 0 1 3.278 3.278c.817 1.604.817 3.705.817 7.905v63m-33.75 0v-111c0-8.4 0-12.601-1.635-15.81a15 15 0 0 0-6.555-6.555c-3.209-1.635-7.409-1.635-15.81-1.635h-19.5c-8.4 0-12.601 0-15.81 1.635a15 15 0 0 0-6.555 6.555C56.25 33.9 56.25 38.1 56.25 46.5v111m108.75 0H15m67.5-105h15m-15 30h15m-15 30h15"
			></path>
		</svg>
	);

	return (
		<div
			// onClick={() => {
			// 	console.log(el);
			// }}
			className="business-card"
		>
			{/* Top Section */}
			<div
				className="business-card__top
			border-theme-bottom"
			>
				<div className="business-card__logo single-merchant-bg-color">
					<ImageFragment url={``}>
						<figure className="img-box">{merchantLogo}</figure>
					</ImageFragment>
				</div>
				<div className="business-card__details-balance-box">
					<div className="business-card__details border-theme-bottom border-theme-top">
						{userDetailsList?.map((chi, idx) => {
							if (loading) {
								return (
									<div key={idx} className="label-value-box">
										<p className="name_ label">
											{' '}
											<Skeleton width={110} height={12} />
										</p>
										<div className={`value`}>
											<Skeleton width={170} height={18} />
										</div>
									</div>
								);
							}
							if (chi?.hide) {
								return <React.Fragment key={idx}></React.Fragment>;
							}
							if (chi?.date) {
								return (
									<div key={idx} className="label-value-box">
										<p className="name_ label">{chi?.label}</p>
										<div className={`value`}>
											<DateFragmentBox date={chi?.value} />
										</div>
									</div>
								);
							}
							if (chi?.settlement) {
								return (
									<div key={idx} className="label-value-box">
										<p className="name_ label">{chi?.label}</p>
										<div
											className={`value ${
												String(chi?.value)?.length >= MAX_LENGTH
													? 'tooltip-hover-wrap'
													: ''
											}`}
										>
											<div className="single-merchant-unique-bank-name-box">
												{' '}
												<CheckUrlFragment
													className="icon-wrap card-bg border-theme"
													url={`https://businessapi.getraventest.com/static/media/banks/${user_merchant?.settlement_detail?.bank_code}.png `}
												>
													<figure className="img-box">{bankIcon}</figure>
												</CheckUrlFragment>
												<div className="name-number-box">
													{' '}
													<p className="name black-white-color">
														{user_merchant?.settlement_detail?.bank}
													</p>
													<p className="small-text grey-white-color">
														{`${
															user_merchant?.settlement_detail
																?.account_number || ''
														}  • ${
															trimLongString(
																user_merchant?.settlement_detail?.account_name,
																25
															) || ''
														}`}
													</p>
												</div>
											</div>
										</div>
									</div>
								);
							}
							return (
								<div key={idx} className="label-value-box">
									<p className="name_ label">{chi?.label}</p>
									<div
										className={`value ${
											String(chi?.value)?.length >= MAX_LENGTH
												? 'tooltip-hover-wrap'
												: ''
										}`}
									>
										{trimLongString(chi?.value, MAX_LENGTH)}
										{/* tooltip start here */}
										{String(chi?.value)?.length >= MAX_LENGTH && (
											<>
												<RavenToolTip
													position={idx < 5 ? `bottom-right` : 'top-center'}
													color="deep-green-light"
													text={chi?.value}
												/>
											</>
										)}
										{/* tooltip end here */}
									</div>
								</div>
							);
						})}
					</div>
					{/* Balance Section */}
					<div className="business-card__balances">
						{balanceList?.map((chi, idx) => {
							if (loading) {
								return (
									<div key={idx} className="label-value-box border-theme">
										<div
											style={{
												color: chi?.labelColor ? chi?.labelColor : '',
												marginTop: 'auto',
											}}
											className="label"
										>
											{chi?.curIcon && (
												<>
													<Skeleton width={25} height={25} circle />
												</>
											)}
											<>
												{' '}
												<Skeleton width={100} height={14} />
											</>
										</div>
										<p className="value">
											<Skeleton width={170} height={20} />
										</p>
									</div>
								);
							}
							return (
								<div key={idx} className="label-value-box border-theme">
									<div
										style={{ color: chi?.labelColor ? chi?.labelColor : '' }}
										className="label"
									>
										{chi?.curIcon && (
											<>
												<figure className="img-box">{chi?.curIcon}</figure>
											</>
										)}
										{chi.label}
									</div>
									<p className="value">
										{chi?.amount ? RavenNumberFormat(chi?.value) : chi?.value}
									</p>
								</div>
							);
						})}
					</div>
					{/* Buttons Section */}
				</div>
			</div>

			<div className="business-card__actions">
				{actionList?.map((chi, idx) => {
					if (loading) {
						return (
							<button
								onClick={() => {
									if (chi?.onClick) {
										chi?.onClick();
									}
								}}
								key={idx}
								className="action-btn border-theme"
							>
								<div style={{ marginTop: '-.7rem', marginRight: '.5rem' }}>
									{' '}
									<figure className="icon">
										{' '}
										<Skeleton width={20} height={20} circle />
									</figure>
								</div>
								<p>
									{' '}
									<Skeleton width={100} height={15} />
								</p>
							</button>
						);
					}
					if (chi?.hide) {
						return <React.Fragment key={idx}></React.Fragment>;
					}
					return (
						<button
							onClick={() => {
								if (chi?.onClick) {
									chi?.onClick();
								}
							}}
							key={idx}
							className="action-btn border-theme"
						>
							<div style={{ marginTop: '.3rem' }}>
								{' '}
								<figure className="icon">{chi?.icon}</figure>
							</div>
							<p>{chi?.label}</p>
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default UserDetailsInfo;
