import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import { RavenToast } from '@ravenpay/raven-bank-ui';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { auth_routes_group } from './routes/auth';
import { Helmet } from 'react-helmet';
import ScrollToTop from './utils/helper/Scrolltop';
import store from './redux/store';
import '@ravenpay/raven-bank-ui/dist/esm/styles/index.css';
import { dashboard_routes_group } from './routes/dashboard';
import PrivateRoute from './routes/PrivateGuard';
import './styles/mobile/index.css';
import './styles/tablets/index.css';
import { livechat } from './utils/livechat';
import { getCookie } from './utils/helper/Helper';
import MobilePage from './components/common/mobile-page/MobilePage';
import useWindowSize from './hooks/UseWindowSize';
import { ALL_ROUTES } from './routes/dashboard/overview/AllPathNames';

function App() {
	const location = useLocation();
	const size = useWindowSize();
	type RouteProp = {
		path: string;
		element: () => any;
		// location:
	};

	// setAuthToken();

	const token = getCookie('token');

	livechat();
	return (
		<Provider store={store}>
			<RavenToast position="top-left" />
			{Number(size?.width) < 900 &&
			!location?.pathname?.includes('/accept-invite') ? (
				<>
					<MobilePage />
				</>
			) : (
				<div className="App">
					<Helmet>
						<title>
							Bankbox || Transforming finance with integrated banking
							infrastructure and powerful API services{' '}
						</title>
						<meta
							name="description"
							content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
						/>
						<meta
							property="og:description"
							content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
						/>
						<meta
							name="keywords"
							content="bank, banking, loan, savings, roi, Transfer, Invoice, payroll,  interest, nigeria, africa, fintech"
						/>
						<meta name="page-topic" content="Banking" />
						<meta name="page-type" content="Landing" />
						<meta name="audience" content="Everyone" />
						<meta
							property="og:image"
							content="https://pbs.twimg.com/profile_images/1485498638973517826/aX6F2jHy_400x400.jpg"
						/>
						<meta property="og:url" content="https://getravenbank.com" />
						<meta name="robots" content="index, follow" />
						<meta
							property="twitter:image:src"
							content="https://pbs.twimg.com/profile_images/1485498638973517826/aX6F2jHy_400x400.jpg"
						/>
						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:image:alt" content="GetRavenBank" />
						<meta
							name="twitter:site"
							content="https://www.twitter.com/theravenbank"
						/>
						<meta
							httpEquiv="Content-Security-Policy"
							content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
						/>
					</Helmet>

					<ScrollToTop />
					<Routes location={location}>
						<Route
							path="/"
							element={
								token ? (
									<Navigate to={ALL_ROUTES?.OVERVIEW} />
								) : (
									<Navigate to="/login" />
								)
							}
						/>
						<Route path="*" element={<h1>404 Not Found</h1>} />
						{/* auth group start */}
						{auth_routes_group?.map((route: RouteProp, idx: number) => (
							<Route key={idx} path={route.path} element={<route.element />} />
						))}
						{/* auth group end */}
						{/* verification group start */}
						{/* {verification_routes_group?.map((route: RouteProp, idx: number) => (
          <Route key={idx} path={route.path} element={<route.element />} />
        ))} */}
						{/* verification group end */}
						{/* dashboard group start */}
						{dashboard_routes_group?.map((route: RouteProp, idx: number) => (
							<Route
								key={idx}
								path={route.path}
								element={<PrivateRoute>{<route.element />}</PrivateRoute>}
							/>
						))}
						{/* dashboard group end */}
					</Routes>
					<RavenToast />
				</div>
			)}
		</Provider>
	);
}

export default App;
