import { RavenButton } from '@ravenpay/raven-bank-ui';
import React from 'react';
import ReuseVerified from '../ReusedVerified';
import { icons } from '../../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { useDarkMode } from '../../../../../hooks/useDarkMode';
import { initiaitIDVerification } from '../../../../../redux/kyc';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from '../../../../../services/logger';
import RootState from '../../../../../redux/types';
import VerificationSDK from '@ravenpay/verify-sdk';
import { ALL_ROUTES } from '../../../../../routes/dashboard/overview/AllPathNames';

function IDVerification() {
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const { profile } = useSelector((state: RootState) => state.wallet);
	const { loading } = useSelector((state: RootState) => state.kyc);

	const dispatch = useDispatch();

	// logger.log(VerificationSDK);

	async function handleIDInit() {
		const resp: any = await dispatch(initiaitIDVerification());

		if (resp?.payload?.status === 'success') {
			const url = resp?.payload?.data?.data?.url;
			const extractRef = url?.split('reference=')[1];
			const refValue = extractRef?.replaceAll('%', '');

			VerificationSDK.init('nin', {
				mobileCallBackURL:
					'https://atlas.getravenbank.com/verification?kyc-stage=id&intent=mobile',
				reference: refValue,
				style: {
					color: 'deep-green-light',
				},
			});
		}
	}

	// VerificationSDK.onVerify = () => {
	// 	navigate();
	// };

	const idStatus = profile?.businessInfo?.document_verification_status;

	const navigate = useNavigate();
	return (
		<div className={`verification-wrap ${theme}`}>
			<div className="verification-wrap__title">
				<h5 className="title__big">Verify your ID </h5>
				<p className="title__sub">
					We need this ID to be able to verify your business details
				</p>
			</div>
			{/* text box end */}
			{/* form start */}
			{idStatus !== 0 ? (
				<ReuseVerified
					text={`Your ID information is verified successfully, proceed to other verifications to complete your setup..`}
					pending={idStatus === 2}
					failed={false}
					onFailClick={() => navigate(ALL_ROUTES?.ONBOARDING)}
				/>
			) : (
				<>
					<div className="verification-wrap__content ">
						{/* top info box start */}
						<div className="content__instruction-title ">
							<figure>{icons.info_blue}</figure>
							<p className="text grey-white-color-white">
								Verification Instructions
							</p>
						</div>
						{/* top info box end */}
						{/* middle info box start */}
						<div className="content__main">
							<p className="main__text">
								To initiate your ID verification , kindly click on the{' '}
								<span onClick={() => {}}> Begin verification</span>
								button below, you would be redirected to our verification
								partner's page, to complete your verification, this usually
								takes aproximately 1 minute.
							</p>
						</div>
						{/* middle info box end */}
						{/* bottom info box start */}
						<div className="content__youtube">
							{/* <figure className="img-box">{icons.youtube}</figure> */}
							<p className="text ">Or watch how to verify</p>
							{/* <FaArrowRight className="icon" /> */}
						</div>
						{/* bottom info box end */}
					</div>
					{/* btn start */}
					{/* <a
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
          href={`https://signup.getmati.com/?merchantToken=61e6d21822dcd6001cffdcaa&flowId=646096a69e79a7001c72e056&metadata={“email”:“${business?.email}”}`}
        > */}

					<RavenButton
						color="deep-green-light"
						label="Begin Verification"
						style={{ width: `100%` }}
						loading={loading}
						onClick={handleIDInit}
						// onClick={handleIntiateIdVerification}
					/>
					{/* </a> */}
					{/* btn end */}
				</>
			)}
			{/* form end */}
		</div>
	);
}

export default IDVerification;
