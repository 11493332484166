import { RavenButton } from '@ravenpay/raven-bank-ui';
import React from 'react';
import ReuseVerified from '../ReusedVerified';
import { icons } from '../../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { useDarkMode } from '../../../../../hooks/useDarkMode';
import { useDispatch, useSelector } from 'react-redux';
import { initiaitBVNVerification } from '../../../../../redux/kyc';
import RootState from '../../../../../redux/types';
import { logger } from '../../../../../services/logger';
import VerificationSDK from '@ravenpay/verify-sdk';
import { ALL_ROUTES } from '../../../../../routes/dashboard/overview/AllPathNames';

function BVNVerification() {
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { loading } = useSelector((state: RootState) => state.kyc);
	const { profile } = useSelector((state: RootState) => state.wallet);
	async function handleBvnInit() {
		const resp: any = await dispatch(initiaitBVNVerification());

		if (resp?.payload?.status === 'success') {
			const url = resp?.payload?.data?.data?.url;
			const extractRef = url?.split('reference=')[1];
			const refValue = extractRef?.replaceAll('%', '');

			VerificationSDK.init('bvn', {
				mobileCallBackURL:
					'https://atlas.getravenbank.com/verification?kyc-stage=bvn&intent=mobile',
				reference: refValue,
				style: {
					color: 'deep-green-light',
				},
			});
		}
	}

	// VerificationSDK.onVerify = () => {
	// 	navigate();
	// };

	const { merchantInfo } = profile;
	return (
		<div className={`verification-wrap ${theme}`}>
			<div className="verification-wrap__title">
				<h5 className="title__big">Verify your BVN </h5>
				<p className="title__sub">
					We need a valid BVN to be able to verify your business.
				</p>
			</div>
			{/* text box end */}
			{/* form start */}
			{merchantInfo?.bvn !== 0 ? (
				<ReuseVerified
					text={
						merchantInfo?.bvn === 3
							? `Your BVN information has been verified successfully, proceed to other verifications to complete your setup..`
							: `Your BVN verification status is currently pending, kindly check back later to verify the status, at the main time you can proceed to other verifications to complete your setup..`
					}
					pending={merchantInfo?.bvn === 2}
					failed={false}
					onFailClick={() => navigate(ALL_ROUTES?.ONBOARDING)}
				/>
			) : (
				<>
					<div className="verification-wrap__content ">
						{/* top info box start */}
						<div className="content__instruction-title ">
							<figure>{icons.info_blue}</figure>

							<p className="text grey-white-color-white">
								Verification Instructions
							</p>
						</div>
						{/* top info box end */}
						{/* middle info box start */}
						<div className="content__main">
							<p className="main__text">
								To initiate your BVN verification , kindly click on the{' '}
								<span onClick={() => {}}> Begin verification</span>
								button below, you would be redirected to our verification
								partner's page, to complete your verification, this usually
								takes aproximately 1 minute.
							</p>
						</div>
						{/* middle info box end */}
						{/* bottom info box start */}
						<div className="content__youtube">
							{/* <figure className="img-box">{icons.youtube}</figure> */}
							<p className="text ">Or watch how to verify</p>
							{/* <FaArrowRight className="icon" /> */}
						</div>
						{/* bottom info box end */}
					</div>
					{/* btn start */}
					{/* <a
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
          href={`https://signup.getmati.com/?merchantToken=61e6d21822dcd6001cffdcaa&flowId=646096a69e79a7001c72e056&metadata={“email”:“${business?.email}”}`}
        > */}

					<RavenButton
						color="deep-green-light"
						label="Begin Verification"
						style={{ width: `100%` }}
						loading={loading}
						// loading={loading}
						onClick={handleBvnInit}
						// onClick={handleIntiateIdVerification}
					/>
					{/* </a> */}
					{/* btn end */}
				</>
			)}
			{/* form end */}
		</div>
	);
}

export default BVNVerification;
