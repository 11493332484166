import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import { useNavigate } from 'react-router-dom';
import './styles/RolesPermissionIndex.css';
import {
	capitalizeFirstLetter,
	getActionNamesByPolicyName,
	getPermissionNames,
	getPolicyNames,
	removeDuplicatesById,
	replaceUnderscoreWithSpace,
	trimLongString,
} from '../../../../../utils/helper/Helper';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import NoContentBox from '../../components/no-content/NoContentBox';
import { bankboxAPI } from '../../../../../redux/bankbox';
import {
	RavenButton,
	RavenCheckBox,
	RavenToggleSlide,
} from '@ravenpay/raven-bank-ui';
import UpdateRoleAndPermision from '../../components/modals/UpdateRoleModal';
import EmptyScreen from '../../../../../components/common/emptyScreen';

const SingleRoleIndex = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading_fee, single_role, policies } = useSelector(
		(state: RootState) => state.bankbox
	);

	type modalProps = {
		update: boolean;
		delete: boolean;
	};

	const [showModal, setShowModal] = useState<modalProps>({
		update: false,
		delete: false,
	});
	const userIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="201"
			height="200"
			fill="none"
			viewBox="0 0 201 200"
			className="img"
		>
			<path
				fill="#D1D1D1"
				d="M100.666 90c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30zM32.166 180a1.48 1.48 0 01-1.484-1.505C31.482 140.53 62.509 110 100.666 110s69.183 30.53 69.984 68.495a1.48 1.48 0 01-1.484 1.505h-137z"
			></path>
		</svg>
	);

	useEffect(() => {
		const id = localStorage?.getItem('single-role-id');
		if (id) {
			getDetails(id);
		} else {
			navigate('dashboard-bankbox/settings-roles-and-permission');
		}
	}, []);

	const getDetails = async (param?: any) => {
		// console.log(param);
		const obj = {
			role_id: param,
		};
		dispatch(bankboxAPI?.getSingleRole(obj));
	};

	const roleInfo = single_role[0];

	const [activeTab, setActiveTab] = useState('Team Members');

	const tabList = [
		{ name: 'Team Members', active: activeTab === 'Team Members' },
		{ name: 'Permissions', active: activeTab === 'Permissions' },
	];

	const [policySelect, setPolicySelect] = useState<string[]>(['overview']);
	const handleSelectPolicyName = (param: string) => {
		if (param) {
			if (policySelect?.includes(param)) {
				const newList = policySelect?.filter((chi) => chi !== param);
				// handleRemovePolicyPermission(param);
				setPolicySelect(newList);
			} else {
				const newList = [...policySelect, param];
				setPolicySelect(newList);
			}
		}
	};

	const [permissionToSubmit, setPermissionToSubmit] = useState<any>([]);
	const getObjPermission = (name: string) => {
		// console.log(name);

		if (name) {
			const obj = permissionToSubmit?.find(
				(chi: any) => chi?.PolicyName === name
			);
			return obj;
		}
	};

	useEffect(() => {
		// dispatch(bankboxAPI?.getRoles({}));
		if (policies?.length < 1) {
			dispatch(bankboxAPI?.getPolicies({}));
		}
	}, []);

	useEffect(() => {
		if (Object?.keys(single_role)?.length > 0) {
			const roleInfo = single_role[0];
			if (Object?.keys(roleInfo)?.length > 0) {
				setPolicySelect(getPolicyNames(roleInfo?.policies));
				setPermissionToSubmit(preparePermissionFunc(roleInfo?.policies));
			}
		}
	}, [single_role]);

	const preparePermissionFunc = (list: any) => {
		if (list?.length > 0) {
			const newList = list?.map((chi: any) => {
				return {
					PolicyName: chi?.policyName,
					actions: getPermissionNames(chi?.actions),
				};
			});
			return newList;
		}
	};

	const getObjPermissionWithList = (name: string, list: any) => {
		if (name && list?.length > 0) {
			const obj = list?.find((chi: any) => chi?.policyName === name);
			return obj;
		}
	};

	const { profile } = useSelector((state: RootState) => state.settings);
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'roles'
		)?.includes('view_roles')
	) {
		return (
			<DashboardLayout
				onBack={() => {
					navigate('/dashboard-settings-roles-and-permission');
				}}
				back
			>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	return (
		<>
			<DashboardLayout
				onBack={() => {
					navigate('/dashboard-settings-roles-and-permission');
				}}
				back
			>
				{loading_fee ? (
					<>
						<NoContentBox loading={true} />
					</>
				) : (
					<div
						onClick={() => {
							// console.log(permissionToSubmit);
							// console.log(preparePermissionFunc(roleInfo?.policies));
							// console.log(roleInfo);
						}}
						className="single-role-and-permission-details-index-wrap white-faded-white-bg"
					>
						<p className="role-title">{roleInfo?.roleName || ''}</p>
						<div className="description-box">
							<p className="text grey-white-color">
								{roleInfo?.roleDescription || ''}
							</p>
						</div>
						{/* route path box start */}
						<div className="route-path-wrap-box border-theme-bottom">
							{tabList?.map((chi, idx) => {
								return (
									<div
										className={`item-row ${chi?.active && 'item-row-active'}`}
										key={idx}
										onClick={() => {
											if (activeTab !== chi?.name) {
												setActiveTab(chi?.name);
											}
										}}
									>
										<p className="name grey-white-color">{chi?.name}</p>
									</div>
								);
							})}
							{String(roleInfo?.isSystemRole) === '0' &&
								getActionNamesByPolicyName(
									profile?.rolePolicies?.policies,
									'roles'
								)?.includes('edit_role') && (
									<div className="right-box-btn">
										<RavenButton
											// loading={loading}
											// disabled={!canUpdate && !logoChanged}
											onClick={() => {
												setShowModal((prev) => {
													return { ...prev, update: true };
												});
											}}
											color="deep-green-light"
										>
											Update Role
										</RavenButton>
									</div>
								)}
						</div>
						{/* route path box end */}
						{/* users box start */}
						{activeTab === 'Team Members' && (
							<div className="all-users-box-wrap">
								{roleInfo?.teamMembers?.length < 1 ? (
									<>
										<NoContentBox
											title="No Team Member Yet"
											text="Team member added to this role will show up here if available."
										/>
									</>
								) : (
									<div
										className={`all-users-box-wrap-inner  ${
											roleInfo?.teamMembers?.length < 3
												? 'all-users-box-wrap-inner-two'
												: ''
										}`}
									>
										{roleInfo?.teamMembers?.map((chi: any, idx: any) => {
											return (
												<div key={idx} className="user-box grey-bg">
													<div className="user-icon">
														<figure className="img-box">{userIcon}</figure>
													</div>
													<div className="name-email-box white-faded-white-bg">
														<p className="name">
															{chi?.firstName?.length + chi?.lastName?.length <
															25
																? `${
																		capitalizeFirstLetter(chi?.firstName) || ''
																  } ${chi?.lastName}`
																: `${chi?.firstName} ${trimLongString(
																		chi?.lastName,
																		4
																  )}`}
														</p>
														<p className="email grey-white-color">
															{trimLongString(chi?.email, 25)}
														</p>
													</div>
												</div>
											);
										})}
									</div>
								)}
							</div>
						)}
						{/* users box end */}
						{/* permission box start */}
						{activeTab === 'Permissions' && (
							<>
								<div className="permission-single-role-box">
									<div className="left-right-box-wrap border-theme">
										{/* left box strart */}
										<div className="left-box border-theme-right">
											<div className="module-list-box grey-bg">
												{policies?.map((chi: any, idx: any) => {
													return (
														<div key={idx} className="item-row">
															<p className="name"> {chi?.policyName}</p>
															<div className="toggle-name-box">
																<RavenToggleSlide
																	checked={policySelect?.includes(
																		chi?.policyName
																	)}
																	color={`deep-green-light`}
																	key={`${idx}-${chi?.policyName}`}
																	// onChange={() => {
																	// 	handleSelectPolicyName(chi?.policyName);
																	// }}
																	id={`${idx}-${chi?.policyName}`}
																/>
															</div>
														</div>
													);
												})}
											</div>
										</div>
										{/* left box end */}
										{/* right box strart */}
										<div className="right-box">
											{/* box-title- permissions start */}
											{policies?.map((chi: any, idx: any) => {
												if (!policySelect?.includes(chi?.policyName)) {
													return <React.Fragment key={idx}></React.Fragment>;
												}
												return (
													<div key={idx} className="title-permissions-box">
														<p className="module-title">{chi?.policyName}</p>
														<div className="permission-box">
															{removeDuplicatesById(chi?.actions)?.map(
																(child: any, i: any) => {
																	return (
																		<div
																			key={i}
																			className="permission-row border-theme"
																		>
																			<label
																				className="permission-row-label"
																				htmlFor=""
																			>
																				<RavenCheckBox
																					onChange={() => {}}
																					checked={
																						getObjPermission(
																							chi?.policyName
																						)?.actions?.includes(
																							child?.actionName
																						)
																							? true
																							: false
																					}
																					id={`${i}-${chi?.policyName}` as any}
																					color={`deep-green-light`}
																					key={`${i}-${chi?.policyName}`}
																				/>
																				<p className="des">
																					{replaceUnderscoreWithSpace(
																						child?.actionName
																					)}
																				</p>
																			</label>
																		</div>
																	);
																}
															)}
														</div>
													</div>
												);
											})}

											{/* box-title- permissions end */}
										</div>
										{/* right box end */}
									</div>
								</div>
							</>
						)}
						{/* permission box end */}
					</div>
				)}
			</DashboardLayout>
			{/* crreat role start */}
			<UpdateRoleAndPermision
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, update: false };
					});
				}}
				visible={showModal?.update}
				onComplete={() => {
					const id = localStorage?.getItem('single-role-id');
					if (id) {
						getDetails(id);
					}
				}}
			/>
		</>
	);
};

export default SingleRoleIndex;
