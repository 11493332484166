import React, { useEffect, useState } from 'react';
import SingleUserLayout from '../../SingleUserLayout';
import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';

import {
	capitalizeFirstWord,
	convertToLowercase,
	formatMetaData,
	identifyNetwork,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';
import ImageFragment from '../../../../../../../components/common/ImageFragment';
import BadgeComponent from '../../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../../components/common/DateFragmentBox';
import { debounce, divide } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../../../redux/types';
import { getSingleDataRecords } from '../../../../../../../redux/bills';
import Pagination from '../../../../../../../components/common/pagination';
import SmartFilter from '../../../../../../../components/common/smartFilter';
const UserData = () => {
	const { dataRecords, loadingFetch } = useSelector(
		(state: RootState) => state.bills
	);
	const dataIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			fill="none"
			viewBox="0 0 20 20"
			className="img"
		>
			<path
				stroke="black"
				style={{ stroke: 'black', strokeOpacity: 1 }}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M7 10H2.6c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C1 10.76 1 11.04 1 11.6v5.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C1.76 19 2.04 19 2.6 19H7m0 0h6m-6 0V6.6c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C7.76 5 8.04 5 8.6 5h2.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C13 5.76 13 6.04 13 6.6V19m0 0h4.4c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C19 18.24 19 17.96 19 17.4V2.6c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C18.24 1 17.96 1 17.4 1h-2.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C13 1.76 13 2.04 13 2.6V6"
			></path>
		</svg>
	);
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);

	const fetchDataRecords = async () => {
		const obj = {
			currentPage: currentPage,
			per_page: 20,
		};
		dispatch(getSingleDataRecords(obj) as any);
	};

	useEffect(() => {
		fetchDataRecords();
	}, [currentPage]);

	return (
		<div>
			{/* dispute content start */}
			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					// filters={[
					//  {
					//    label: 'Status',
					//    filterBy: 'status',
					//    options: [
					//      {
					//        label: 'Pending',
					//        value: 'pending',
					//      },
					//      {
					//        label: 'Failed',
					//        value: 'failed',
					//      },
					//      {
					//        label: 'Successful',
					//        value: 'succesful',
					//      },
					//    ],
					//  },
					// ]}
					onExport={() => {
						//  setShowModal((prev) => {
						//    return { ...prev, export: true };
						//  });
					}}
					page="bankbox_transactions"
					onSearchChange={debounce((e) => {
						// dispatch(
						//  bankboxAPI.getAllFees({
						//    per_page: 20,
						//    page: currentPage,
						//    search: e,
						//  })
						// );
					}, 500)}
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={dataRecords?.pagination?.perPage}
						totalItems={dataRecords?.pagination?.total}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			{/* table content start */}
			<div
				// onClick={() => {
				// 	console.log(airtimeRecords);
				// }}
				className="transaction__table non-mobile"
			>
				<RavenTable
					action={false}
					className="table__main"
					headerList={[
						'REFERENCE',
						'PROVIDER',
						'AMOUNT',
						'MOBILE NUMBER',
						'DATE',
						'STATUS',
					]}
				>
					{dataRecords?.data?.map((chi: any, idx: any) => (
						<RavenTableRow
							loading={loadingFetch}
							// onRowClick={() => {
							// 	setSingleChild(chi);
							// 	setShowModal((prev) => {
							// 		return { ...prev, bills_modal: true };
							// 	});
							// }}
							one={trimLongString(chi.reference, 20) || '---'}
							two={
								<div className="provider_image_and_name">
									<ImageFragment
										className={`img-val-box  img-box border-theme`}
										url={`https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
											identifyNetwork(
												formatMetaData(chi?.meta_data)?.phone_number
											)
										)}.png`}
									>
										{' '}
										<figure className="img-box border-theme">
											{' '}
											{/* <img
														className="img"
														src={settlementIcon}
														alt="image"
													/> */}
										</figure>
									</ImageFragment>

									<p className="name">
										{identifyNetwork(
											formatMetaData(chi?.meta_data)?.phone_number
										)}
									</p>
								</div>
							}
							three={RavenNumberFormat(chi?.amount || 0)}
							four={formatMetaData(chi?.meta_data)?.phone_number || '---'}
							six={
								<div className="status_and_modal_holder">
									<BadgeComponent
										text={(() => {
											if (String(chi?.status === '0')) {
												return 'Disabled';
											} else {
												return 'Success';
											}
										})()}
										className={(() => {
											if (String(chi?.status === '1')) {
												return 'failed';
											} else {
												return 'success';
											}
										})()}
									/>
								</div>
							}
							five={<DateFragmentBox date={chi?.created_at} />}
						/>
					))}
				</RavenTable>
			</div>
			{/* table content end */}
		</div>
	);
};

export default UserData;
