import React, { useEffect, useState } from 'react';
import './style.css';
import { RavenButton, RavenInputField, toast } from '@ravenpay/raven-bank-ui';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../../../assets/icons';
import {
	updateBusinessInfo,
	uploadMerchantDocuments,
} from '../../../../../../redux/kyc';
import { useDispatch } from 'react-redux';
import { logger } from '../../../../../../services/logger';
import env from '../../../../../../env';
import { decrypt2 } from '../../../../../../utils/encrypt';
import { getCookie } from '../../../../../../utils/helper/Helper';
import { ALL_ROUTES } from '../../../../../../routes/dashboard/overview/AllPathNames';

const AddressVerification = () => {
	interface FormProp {
		state: string;
		lga: string;
		business_address: string;
		address: string;
		landmark: string;
	}

	const [verified, setVerified] = useState<string[]>(['rc', '']);
	const [file, setFile] = useState<File | null>();
	const [active, setActive] = useState<number>(1);
	const [formDetails, setFormData] = useState<FormProp>({
		state: '',
		lga: '',
		business_address: '',
		address: '',
		landmark: '',
	});
	const [animateClass, setAnimateClass] = useState<string>('');
	const [isComplete, setIsComplete] = useState({
		1: true,
		2: false,
		3: false,
		4: false,
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();

	function isTab(tab: number) {
		if (active === tab) return true;
		else return false;
	}

	function animate() {
		setTimeout(() => {
			setAnimateClass('active');
		}, 5);
	}

	function handleChange(event: { target: { name: any; value: any } }) {
		setFormData({
			...formDetails,
			[event.target.name]: event.target.value,
		});
	}

	useEffect(() => {
		setAnimateClass('false');
	}, [active]);

	const handleSubmit = async (event: { preventDefault: () => void }) => {
		event.preventDefault();

		// Append other form fields here
		var formdata = new FormData();

		if (file) {
			formdata.append('address_image', file as Blob, '[PROXY]');
		}

		try {
			// Make an API request using the formData
			var myHeaders = new Headers();
			myHeaders.append('Authorization', `Bearer ${getCookie('token')}`);

			var requestOptions: any = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow',
			};

			//TODO: replace any with actual type
			let resp: any = await fetch(
				env.base_url + 'web/update_documents',
				requestOptions
			);

			resp = await resp.json();

			if (resp.status === 'fail') {
				toast.error(decrypt2(resp.data));
			} else {
				const r = await dispatch(updateBusinessInfo(formDetails));

				if (r?.payload?.status === 'success') {
					navigate(ALL_ROUTES?.ONBOARDING);
				}
			}

			// Handle the response as needed

			// Reset the form state
			setFile(null);

			// Reset other form fields
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleFileChange = (event: File) => {
		setFile(event);

		// Append the file if it's not null
		logger.log(file);
	};

	return (
		<div className="cac-verification">
			<nav className="cac-verification__navbar">
				<div
					onClick={() => setActive(1)}
					className={`navbar__item ${active === 1 && 'active'} ${
						isComplete[1] && 'complete'
					}`}
				>
					<span
						className={`${
							active === 1
								? 'active'
								: verified.includes('rc')
								? 'verified'
								: ''
						}`}
					>
						<p>1</p>
					</span>
					<p className={`${verified.includes('rc') && 'verified'}`}>
						Verify Tour Address
					</p>
				</div>

				<div
					onClick={() => setActive(2)}
					className={`navbar__item ${active === 2 && 'active'} ${
						isComplete[2] && 'complete'
					}`}
				>
					<span
						className={`${
							active == 1 ? 'active' : verified.includes('rc') ? 'verified' : ''
						}`}
					>
						<p>2</p>
					</span>
					<p className={`${verified.includes('rc') && 'verified'}`}>
						Upload Office Building
					</p>
				</div>
			</nav>
			<section
				className={`cac-verification__content  ${
					isTab(1) && animate()
				} ${animateClass}`}
			>
				{isTab(1) && (
					<React.Fragment>
						<div className={`content__title`}>
							<h5>Verify Your Address</h5>
							<p>Do Provide your business RC Number</p>
						</div>

						<div className="content__country">
							<figure> Nigeria</figure>
							<p>
								Not in Nigeria?{' '}
								<b style={{ color: '#755AE2' }}>
									Contact Us {icons.arrow_right_purple}
								</b>
							</p>
						</div>
						<div className="content__form-wrap">
							<RavenInputField
								label="State"
								color="purple-light"
								value={formDetails.state}
								onChange={handleChange}
								placeholder="Lagos State"
								name="state"
							/>

							<RavenInputField
								label="LGA"
								color="purple-light"
								value={formDetails.lga}
								onChange={handleChange}
								placeholder="Ikorodu FC"
								name="lga"
							/>
							<RavenInputField
								label="Street Address"
								color="purple-light"
								value={formDetails.business_address}
								onChange={handleChange}
								placeholder="E.g ‘10 Oyedele Cl, Bajulaye Street’"
								name="business_address"
							/>
							<RavenInputField
								label="Landmark"
								color="purple-light"
								value={formDetails.landmark}
								onChange={handleChange}
								placeholder="Cherub Mall"
								name="landmark"
							/>

							<div className="form__button">
								<RavenButton
									size="big"
									onClick={() => setActive(2)}
									disabled={
										!formDetails.state ||
										!formDetails.lga ||
										!formDetails.business_address ||
										!formDetails.landmark
									}
									label="Proceed"
									color="deep-green-light"
								/>
							</div>
						</div>
					</React.Fragment>
				)}
				{isTab(2) && (
					<form onSubmit={handleSubmit}>
						<div className="content__title">
							<h5>Upload office Buiding</h5>
							<p>Kindly Upload a picture of your business office</p>
						</div>

						<div className="content__business-category-select ">
							<div className="business-category-select__upload">
								<RavenInputField
									// upploadText="or drag and drop document file here"
									type="upload"
									value={file}
									onChange={handleFileChange}
									labelColor="green-light"
									className="business-category-select__upload"
									color="deep-green-light"
								/>
								<span className="upload__file-size">
									Maximum file size acceptable for upload is 2MB
								</span>
							</div>
						</div>

						<RavenInputField
							type="submit"
							className="content__upload-button"
							color="deep-green-light"
							// onClick={() => navigate('/dashboard-onboarding?kyc-stage=cac')}
							value="Upload Bulding Picture"

							// style={{ width: '60%' }}
						/>
					</form>
				)}
			</section>
		</div>
	);
};

export default AddressVerification;
