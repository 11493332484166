import React, { useEffect, useState } from 'react';
import SingleUserLayout from '../SingleUserLayout';
import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import TwoDetailView from '../../../../../../components/common/TwoDetailView';
import {
	capitalizeFirstWord,
	convertToLowercase,
	formatTypeFunction,
	trimLongString,
} from '../../../../../../utils/helper/Helper';
import ImageFragment from '../../../../../../components/common/ImageFragment';
import DateFragmentBox from '../../../../../../components/common/DateFragmentBox';
import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
import Txreference from '../../../../../../components/common/reference/TxRef';
import { CopyText } from '../../../../../../components/common/copyCheck copy';
import Copy from '../../../../../../components/common/copyCheck';
import Pagination from '../../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../../components/common/smartFilter';
import {
	getElectricityRecords,
	getSingleElectricityRecords,
} from '../../../../../../redux/bills';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../../redux/types';
import CopyFragment from '../../../../../../components/common/CopyFragment';

// Sample Data (replace with your imported ElectricityData)
const ElectricityData = [
	{
		name: 'John Doe',
		refrence: 'TRF-2023-145892657',
		email: 'john.doe@example.com',
		provider: 'ProviderName',
		amount: 1500,
		meter_number: '456787654674',
		created_at: '2023-01-15T08:30:00',
		status: '0',
	},
	{
		refrence: 'TRF-2023-145892657',
		name: 'Jane Smith',
		email: 'jane.smith@example.com',
		provider: 'AnotherProvider',
		amount: 2500,
		meter_number: '456787654674',
		created_at: '2023-02-20T10:00:00',
		status: '1',
	},
];

const SingleElectricity = () => {
	type showModalProp = {
		bills_modal: boolean;
	};

	const electricityIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			className="img"
		>
			<path
				stroke="black"
				style={{ stroke: 'black', strokeOpacity: 1 }}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M13 2 4.093 12.688c-.348.418-.523.628-.526.804a.5.5 0 0 0 .186.397c.138.111.41.111.955.111H12l-1 8 8.906-10.688c.35-.418.524-.628.526-.804a.5.5 0 0 0-.185-.397c-.138-.111-.41-.111-.955-.111H12z"
			></path>
		</svg>
	);

	const [showModal, setShowModal] = useState<showModalProp>({
		bills_modal: false,
	});

	const [currentPage, setCurrentPage] = useState(1);
	const dispatch = useDispatch();

	const { electricityRecords, loadingFetch } = useSelector(
		(state: RootState) => state.bills
	);

	useEffect(() => {
		fetchAllElectricity();
	}, [currentPage]);

	const fetchAllElectricity = async () => {
		const obj = {
			currentPage: currentPage,
			per_page: 2,
		};
		dispatch(getSingleElectricityRecords(obj) as any);
	};

	const { loading } = useSelector((state: RootState) => state.bankbox);

	return (
		<>
			{/* dispute content start */}
			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					// filters={[
					// 	{
					// 		label: 'Status',
					// 		filterBy: 'status',
					// 		options: [
					// 			{
					// 				label: 'Pending',
					// 				value: 'pending',
					// 			},
					// 			{
					// 				label: 'Failed',
					// 				value: 'failed',
					// 			},
					// 			{
					// 				label: 'Successful',
					// 				value: 'succesful',
					// 			},
					// 		],
					// 	},
					// ]}
					onExport={() => {
						// 	setShowModal((prev) => {
						// 		return { ...prev, export: true };
						// 	});
					}}
					page="bankbox_transactions"
					onSearchChange={debounce((e) => {
						// dispatch(
						// 	bankboxAPI.getAllFees({
						// 		per_page: 20,
						// 		page: currentPage,
						// 		search: e,
						// 	})
						// );
					}, 500)}
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={electricityRecords?.pagination?.perPage}
						totalItems={electricityRecords?.pagination?.total}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			<div>
				{/* Table Content Start */}
				<div className="transaction__table non-mobile">
					<RavenTable
						action={false}
						className="table__main"
						headerList={[
							'Reference',
							'PROVIDER',
							'AMOUNT',
							'METER NUMBER',
							'DATE',
							'STATUS',
						]}
					>
						{electricityRecords?.data?.map((chi: any, idx: any) => (
							<RavenTableRow
								loading={loadingFetch}
								key={idx}
								one={trimLongString(chi?.reference || 'N/A', 15)}
								two={
									<div className="provider_image_and_name">
										<ImageFragment
											className={`img-val-box img-box border-theme`}
											url={`https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
												chi?.provider
											)}.png`}
										>
											<figure className="img-box border-theme img-box-default">
												{electricityIcon}
											</figure>
										</ImageFragment>
										<p className="name">{trimLongString(chi?.provider, 20)}</p>
									</div>
								}
								three={RavenNumberFormat(chi?.amount || 0)}
								four={
									<CopyFragment
										text={chi?.meter_number}
										trimnumber={20}
										copyText={chi?.meter_number || 'N/A'}
									/>
								}
								five={<DateFragmentBox date={chi?.created_at} />}
								six={
									<div className="status_and_modal_holder">
										<BadgeComponent
											text={chi?.status === '1' ? 'Failed' : 'Success'}
											className={chi?.status === '1' ? 'failed' : 'success'}
										/>
									</div>
								}
							/>
						))}
					</RavenTable>
				</div>
				{/* Table Content End */}
			</div>
		</>
	);
};

export default SingleElectricity;
