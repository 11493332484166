import React, { useEffect, useState } from 'react';
import SingleUserLayout from '../../SingleUserLayout';
import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import { merchantDetails } from '../../../../bills/airtime/airtimeData';
import TwoDetailView from '../../../../../../../components/common/TwoDetailView';
import {
	capitalizeFirstWord,
	convertToLowercase,
	formatMetaData,
	identifyNetwork,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';
import ImageFragment from '../../../../../../../components/common/ImageFragment';
import { useDispatch, useSelector } from 'react-redux';
import BadgeComponent from '../../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../../components/common/DateFragmentBox';
import settlementIcon from '../../../../../../../assets/bank_box_icon_settlement.svg';
import { debounce, divide } from 'lodash';
import { de } from '@faker-js/faker';

import RootState from '../../../../../../../redux/types';
import { getSingleAirtimeRecords } from '../../../../../../../redux/bills';
import Pagination from '../../../../../../../components/common/pagination';
import SmartFilter from '../../../../../../../components/common/smartFilter';
const UserAirtime = () => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const { airtimeRecords, loadingFetch } = useSelector(
		(state: RootState) => state.bills
	);
	const [singleChild, setSingleChild] = useState<any>({});
	useEffect(() => {
		fetchAllAirtime();
	}, [currentPage]);

	const fetchAllAirtime = async () => {
		const obj = {
			currentPage: currentPage,
			per_page: 4,
		};
		dispatch(getSingleAirtimeRecords(obj) as any);
	};
	return (
		<div>
			{/* dispute content start */}

			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					// filters={[
					//  {
					//    label: 'Status',
					//    filterBy: 'status',
					//    options: [
					//      {
					//        label: 'Pending',
					//        value: 'pending',
					//      },
					//      {
					//        label: 'Failed',
					//        value: 'failed',
					//      },
					//      {
					//        label: 'Successful',
					//        value: 'succesful',
					//      },
					//    ],
					//  },
					// ]}
					onExport={() => {
						//  setShowModal((prev) => {
						//    return { ...prev, export: true };
						//  });
					}}
					page="bankbox_transactions"
					onSearchChange={debounce((e) => {
						// dispatch(
						//  bankboxAPI.getAllFees({
						//    per_page: 20,
						//    page: currentPage,
						//    search: e,
						//  })
						// );
					}, 500)}
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={airtimeRecords?.pagination?.perPage}
						totalItems={airtimeRecords?.pagination?.total}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			{/* table content start */}
			<div
				// onClick={() => {
				// 	console.log(airtimeRecords);
				// }}
				className="transaction__table non-mobile"
			>
				<RavenTable
					action={false}
					className="table__main"
					headerList={[
						'REFERENCE',
						'PROVIDER',
						'AMOUNT',
						'MOBILE NUMBER',
						'DATE',
						'STATUS',
					]}
				>
					{airtimeRecords?.data.map((chi: any, idx: any) => (
						<RavenTableRow
							loading={loadingFetch}
							onRowClick={() => {
								setSingleChild(chi);
								// setShowModal((prev) => {
								// 	return { ...prev, bills_modal: true };
								// });
							}}
							one={trimLongString(chi.reference, 20) || '---'}
							two={
								<div className="provider_image_and_name">
									<ImageFragment
										className={`img-val-box  img-box border-theme`}
										url={`https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
											identifyNetwork(
												formatMetaData(chi?.meta_data)?.phone_number
											)
										)}.png`}
									>
										{' '}
										<figure className="img-box border-theme">
											{' '}
											<img className="img" src={settlementIcon} alt="image" />
										</figure>
									</ImageFragment>

									<p className="name">
										{identifyNetwork(
											formatMetaData(chi?.meta_data)?.phone_number
										)}
									</p>
								</div>
							}
							three={RavenNumberFormat(chi?.amount || 0)}
							four={formatMetaData(chi?.meta_data)?.phone_number || '---'}
							six={
								<div className="status_and_modal_holder">
									<BadgeComponent
										text={(() => {
											if (String(chi?.status === '0')) {
												return 'Disabled';
											} else {
												return 'Success';
											}
										})()}
										className={(() => {
											if (String(chi?.status === '1')) {
												return 'failed';
											} else {
												return 'success';
											}
										})()}
									/>
								</div>
							}
							five={<DateFragmentBox date={chi?.created_at} />}
						/>
					))}
				</RavenTable>
			</div>
		</div>
	);
};
export default UserAirtime;
