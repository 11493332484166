import React, { useEffect, useState } from 'react';
import './style.css';
import { RavenButton, RavenInputField, toast } from '@ravenpay/raven-bank-ui';
import { icons } from '../../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { useDarkMode } from '../../../../../hooks/useDarkMode';
import { useDispatch, useSelector } from 'react-redux';
import { updateBusinessInfo, validateRC } from '../../../../../redux/kyc';
import RootState from '../../../../../redux/types';
import { logger } from '../../../../../services/logger';
import env from '../../../../../env';
import { decrypt2 } from '../../../../../utils/encrypt';
import { getCookie } from '../../../../../utils/helper/Helper';
import { ALL_ROUTES } from '../../../../../routes/dashboard/overview/AllPathNames';

type RCDetails = {
	approvedName: string;
	code: string;
	companyStatus: string;
	email: string;
	id: number | string;
	rcNumber: string;
	registrationDate: string;
	status: string;
	address: string;
};

const CACVerification = () => {
	const [verified, setVerified] = useState<string[]>(['rc', '']);
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const { loading } = useSelector((state: RootState) => state.kyc);
	const [uploading, setUploading] = useState(false);
	const [active, setActive] = useState<number>(1);
	const [file, setFile] = useState<File | null>();
	const [value, setValue] = useState<string>('');
	const [cacDetails, setCacDetails] = useState<RCDetails[]>([]);
	const [animateClass, setAnimateClass] = useState<string>('');
	const [businessType, setBusinessType] = useState<number>(0);
	const [isComplete, setIsComplete] = useState({
		1: false,
		2: false,
		3: false,
		4: false,
	});

	const navigate = useNavigate();

	function isValidRCNumber(rcNumber: string) {
		// Remove any whitespace or hyphens from the input
		const cleanRCNumber = rcNumber.replace(/\s|-/g, '');

		// RC number should start with "RC" or "CAC"
		const startsWithRC = /^RC\d{6}$/i.test(cleanRCNumber);
		const startsWithCAC = /^CAC\d{6}$/i.test(cleanRCNumber);
		// return startsWithRC || startsWithCAC;

		return rcNumber.length > 3 ? true : false;
	}

	function isSelected(feature: number) {
		if (businessType === feature) return true;
		else return false;
	}

	function isTab(tab: number) {
		if (active === tab) return true;
		else return false;
	}

	function animate() {
		setTimeout(() => {
			setAnimateClass('active');
		}, 5);
		// setTimeout(() => {
		// 	setAnimateClass('active');
		// }, 50);
	}

	useEffect(() => {
		setAnimateClass('false');
	}, [active]);

	const dispatch = useDispatch();

	async function validateRCNumber(rcNumber: string) {
		const resp = await dispatch(
			validateRC({
				rc_number: rcNumber,
			})
		);

		if (resp?.payload?.status === 'success') {
			if (resp?.payload?.data?.data.length === 1) {
				setActive(3);
				setIsComplete({
					...isComplete,
					1: true,
				});
				setBusinessType(0);
			} else {
				setActive(2);
			}

			setCacDetails(resp?.payload?.data?.data);
		}
	}

	const handleFileChange = (event: File) => {
		setFile(event);

		// Append the file if it's not null
		logger.log(file);
	};

	const handleSubmit = async (event?: { preventDefault: () => void }) => {
		// event.preventDefault();
		setUploading(true);

		// Append other form fields here
		var formdata = new FormData();

		if (file) {
			formdata.append('cac_certificate', file as Blob, '[PROXY]');
		}

		try {
			// Make an API request using the formData
			var myHeaders = new Headers();
			myHeaders.append('Authorization', `Bearer ${getCookie('token')}`);

			var requestOptions: any = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow',
			};

			//TODO: replace any with actual type
			let resp: any = await fetch(
				env.base_url + 'web/update_documents',
				requestOptions
			);

			resp = await resp.json();

			if (resp.status === 'fail') {
				toast.error(decrypt2(resp.data));
			} else {
				dispatch(
					updateBusinessInfo({
						registration_number: cacDetails[businessType]?.rcNumber,
						rc_business_id: cacDetails[businessType]?.id,
					})
				);
				setIsComplete({
					...isComplete,
					4: true,
				});
				toast.success('CAC Details submitted and under review');
				navigate(ALL_ROUTES?.ONBOARDING);
				logger.log(decrypt2(resp.data));
				// const r = await dispatch(updateBusinessInfo(formDetails));
				// if (r?.payload?.status === 'success') {
				// 	navigate();
				// }
			}

			// Handle the response as needed

			// Reset the form state
			setFile(null);
			setUploading(false);

			// Reset other form fields
		} catch (error) {
			setUploading(false);
			console.error('Error:', error);
		}
	};

	logger.log(cacDetails);
	return (
		<div className="cac-verification">
			<nav className="cac-verification__navbar">
				<div
					onClick={() => setActive(1)}
					className={`navbar__item ${active === 1 && 'active'} ${
						isComplete[1] && 'complete'
					}`}
				>
					<span
						className={`${
							active === 1
								? 'active'
								: verified.includes('rc')
								? 'verified'
								: ''
						}`}
					>
						<p>1</p>
					</span>
					<p className={`${verified.includes('rc') && 'verified'}`}>
						Verify RC Number
					</p>
				</div>

				{/* <div
					onClick={() => setActive(2)}
					className={`navbar__item ${active === 2 && 'active'} ${
						isComplete[2] && 'complete'
					}`}
				>
					<span
						className={`${
							active == 1 ? 'active' : verified.includes('rc') ? 'verified' : ''
						}`}
					>
						<p>2</p>
					</span>
					<p className={`${verified.includes('rc') && 'verified'}`}>
						Select Business
					</p>
				</div> */}

				<div
					onClick={() => setActive(3)}
					className={`navbar__item ${active === 3 && 'active'} ${
						isComplete[3] && 'complete'
					}`}
				>
					<span
						className={`${
							active === 1
								? 'active'
								: verified.includes('rc')
								? 'verified'
								: ''
						}`}
					>
						<p>2</p>
					</span>
					<p className={`${verified.includes('biz') && 'verified'}`}>
						Confirm Business
					</p>
				</div>

				<div
					onClick={() => setActive(4)}
					className={`navbar__item ${active === 4 && 'active'} ${
						isComplete[4] && 'complete'
					}`}
				>
					<span
						className={`${
							active === 1
								? 'active'
								: verified.includes('rc')
								? 'verified'
								: ''
						}`}
					>
						<p>3</p>
					</span>
					<p className={`${verified.includes('cac') && 'verified'}`}>
						CAC Documents
					</p>
				</div>
			</nav>
			<section
				className={`cac-verification__content  ${
					isTab(1) && animate()
				} ${animateClass}`}
			>
				{isTab(1) && (
					<React.Fragment>
						<div className={`content__title`}>
							<h5>Business RC Number</h5>
							<p>Do Provide your business RC Number</p>
						</div>
						<div className="content__form">
							<RavenInputField
								label="Business RC Number"
								color="purple-light"
								showError={!isValidRCNumber(value)}
								value={value}
								onChange={(e: {
									target: { value: React.SetStateAction<string> };
								}) => setValue(e.target.value)}
								placeholder="534xxxxx"
								name="rc-number"
							/>
							<div className="form__note">
								<p>Help find my RC Number</p>
							</div>
							<div className="form__button">
								<RavenButton
									size="big"
									loading={loading}
									onClick={() => validateRCNumber(value)}
									disabled={!isValidRCNumber(value)}
									label="Verify RC Number"
									color={
										theme === 'dark' ? 'deep-green-dark' : 'deep-green-light'
									}
								/>
							</div>
						</div>
					</React.Fragment>
				)}
				{isTab(2) && (
					<>
						<div className="content__title">
							<h5>Select a Business</h5>
							<p>
								Hi there, multiple businesses were found with the RC Number
								provided. Kindly select which business is yours.
							</p>
						</div>

						<div className="content__business-category-select">
							{cacDetails?.map((chi: any, idx: number) => {
								return (
									<div
										onClick={() => setBusinessType(idx)}
										className={`business-category-select__item ${
											isSelected(idx) && 'active'
										}`}
									>
										<div className="item__span">
											{' '}
											<small>{chi?.status}</small>
											{isSelected(idx) && <figure>{icons.selected}</figure>}
										</div>
										<p>{chi?.approvedName}</p>
									</div>
								);
							})}
						</div>

						<RavenButton
							className="content__business-category-button"
							color={theme === 'dark' ? 'deep-green-dark' : 'deep-green-light'}
							label="Continue"
							onClick={() => setActive(3)}
							size="big"
							style={{ width: '55%' }}
						/>
					</>
				)}
				{isTab(3) && (
					<>
						<div className="content__title">
							<h5>Confirm Business Details</h5>
							<p>
								Kindly confirm if the business details provided from the RC
								Number is your Business.
							</p>
						</div>

						<div className="content__business-category-select ">
							<div
								// onClick={() => setBusinessType('non-profit')}
								className={`business-category-select__item business-category-select__confirm-business`}
							>
								<div className="item__span">
									{' '}
									<small>BUSINESS NAME</small>
								</div>
								<p>{cacDetails[businessType]?.approvedName}</p>
							</div>

							<div
								// onClick={() => setBusinessType('non-profit')}
								className={`business-category-select__item business-category-select__confirm-business`}
							>
								<div className="item__span">
									{' '}
									<small>RC NUMBER</small>
								</div>
								<p>{cacDetails[businessType]?.rcNumber}</p>
							</div>

							<div
								// onClick={() => setBusinessType('non-profit')}
								className={`business-category-select__item business-category-select__confirm-business`}
							>
								<div className="item__span">
									{' '}
									<small>BUSINESS ADDRESS</small>
								</div>
								<p>{cacDetails[businessType]?.address}</p>
							</div>

							<div
								// onClick={() => setBusinessType('non-profit')}
								className={`business-category-select__item business-category-select__confirm-business`}
							>
								<div className="item__span">
									{' '}
									<small>BUSINESS STATUS</small>
								</div>
								<p className="item__active">
									{cacDetails[businessType]?.status}
								</p>
							</div>
						</div>

						<RavenButton
							className="content__business-category-button"
							color={theme === 'dark' ? 'deep-green-dark' : 'deep-green-light'}
							onClick={() => {
								setActive(4),
									setIsComplete({
										...isComplete,
										3: true,
									});
							}}
							label="Yes, This is my Business"
							size="big"
							// style={{ width: '60%' }}
						/>

						<div className="content__not-your-business">
							<p>Not your Business?</p>
							<span
								onClick={() => {
									setValue('');
									setCacDetails([]);
									setActive(1);
								}}
							>
								Click Here {icons.arrow_right_deep_blue}
							</span>
						</div>
					</>
				)}

				{isTab(4) && (
					<>
						<div className="content__title">
							<h5>CAC Document</h5>
							<p>
								Kindly Upload CAC Document for <br />
								<b style={{ color: '#0B8376' }}>
									{cacDetails[businessType]?.approvedName}
								</b>
							</p>
						</div>

						<div className="content__business-category-select ">
							<div className="business-category-select__upload">
								<RavenInputField
									type="upload"
									value={file}
									onChange={handleFileChange}
									labelColor="green-light"
									className="business-category-select__upload"
									color="deep-green-light"
								/>
								<span className="upload__file-size">
									Maximum file size acceptable for upload is 2MB
								</span>
							</div>
						</div>

						<RavenButton
							className="content__upload-button"
							color={theme === 'dark' ? 'deep-green-dark' : 'deep-green-light'}
							onClick={() => handleSubmit()}
							loading={loading || uploading}
							label="Upload CAC Document"
							size="big"
							// style={{ width: '60%' }}
						/>
					</>
				)}
			</section>
		</div>
	);
};

export default CACVerification;
