import React from 'react';
import { RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import { useState } from 'react';
import {
	capitalizeFirstWord,
	formatTypeFunction,
} from '../../../../../utils/helper/Helper';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';
import { FaRegEye } from 'react-icons/fa';
import { icons } from '../../../../../assets/icons';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import TwoDetailView from '../../../../../components/common/TwoDetailView';
import ImageFragment from '../../../../../components/common/ImageFragment';
import '../style/ComplianceCacVerification.css';
import ComplianceLayout from '../ComplianceLayout';
import Pagination from '../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../components/common/smartFilter';

const ComplianceCacVerification = () => {
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	type showModalProp = {
		create: boolean;
		export: boolean;
		view_fee: boolean;
		edit_fee: boolean;
	};

	const [singleChild, setSingleChild] = useState<any>({});
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		view_fee: false,
		edit_fee: false,
	});

	function isDropped(idx: number | string) {
		return showDrop.id === idx && showDrop.show;
	}

	const editIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
				stroke="black"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const cameraIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#020202"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.4"
				d="M11.15 14.75H4.199c-.455 0-.682 0-.787-.09a.38.38 0 0 1-.13-.314c.01-.138.171-.299.492-.62l6.378-6.377c.297-.297.445-.446.616-.502a.75.75 0 0 1 .464 0c.171.056.32.205.617.502l2.901 2.901v.9m-3.6 3.6c1.26 0 1.89 0 2.371-.245.424-.216.768-.56.984-.984.245-.48.245-1.11.245-2.371m-3.6 3.6h-6.3c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 0 1-.984-.984c-.245-.48-.245-1.11-.245-2.371v-6.3c0-1.26 0-1.89.245-2.371a2.25 2.25 0 0 1 .984-.984c.48-.245 1.11-.245 2.371-.245h6.3c1.26 0 1.89 0 2.371.245.424.216.768.56.984.984.245.48.245 1.11.245 2.371v6.3M6.875 5.375a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
			></path>
		</svg>
	);
	const downloadIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#1B1B1B"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.4"
				d="M14.75 10.25v.9c0 1.26 0 1.89-.245 2.371-.216.424-.56.768-.984.984-.48.245-1.11.245-2.371.245h-6.3c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 0 1-.984-.984c-.245-.48-.245-1.11-.245-2.371v-.9m10.5-3.75L8 10.25m0 0L4.25 6.5M8 10.25v-9"
			></path>
		</svg>
	);

	const DropMore = ({ className, idx, chi }: any) => {
		return (
			<div className={`more-wrap ${className}`}>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							view_fee: true,
						}));
					}}
					className="more_item"
				>
					<figure>
						<FaRegEye />
					</figure>
					<p>View Fee</p>
				</div>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							edit_fee: true,
						}));
					}}
					className="more_item"
				>
					<figure className="img-box">{editIcon}</figure>
					<p>Edit Fee</p>
				</div>
			</div>
		);
	};

	// Dummy data for all_fees
	const all_fees = {
		data: [
			{
				BUSINESSTYPE: 'Corporation',
				name: 'Folasayo Ogunnaike',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-01T00:00:00',
			},
			{
				BUSINESSTYPE: 'Corporation',
				name: 'Yussuf Ahmed',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-10T00:00:00',
			},
			{
				BUSINESSTYPE: 'Non Profit Organisation',
				name: 'Josh Michael',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
			{
				BUSINESSTYPE: 'Corporation',
				name: 'Hannah Pedro',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
			{
				BUSINESSTYPE: 'Non Profit Organisation',
				name: 'Esther Joel',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 0,
				created_at: '2024-11-15T00:00:00',
			},
			{
				BUSINESSTYPE: 'Non Profit Organisation',
				name: 'Ebubechukwu Agnes',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
		],
	};

	const getInitials = (name: string) => {
		if (!name) return '';
		const nameParts = name.trim().split(' ');
		const firstInitial = nameParts[0]?.[0]?.toUpperCase() || '';
		const lastInitial =
			nameParts.length > 1
				? nameParts[nameParts.length - 1]?.[0]?.toUpperCase()
				: '';
		return `${firstInitial}${lastInitial}`;
	};

	const trimText = (text: string, maxLength: number) => {
		return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
	};

	return (
		<>
			{/* Table content start */}
			<ComplianceLayout>
				{/* dispute content start */}
				<div
					style={{ marginBottom: '1.5rem' }}
					className="global-search-pagination-filter-box"
				>
					{' '}
					<SmartFilter
						// hideExport
						searchTitle="Search By Email Address"
						defaultFilterBy="status"
						// filters={[
						// 	{
						// 		label: 'Status',
						// 		filterBy: 'status',
						// 		options: [
						// 			{
						// 				label: 'Pending',
						// 				value: 'pending',
						// 			},
						// 			{
						// 				label: 'Failed',
						// 				value: 'failed',
						// 			},
						// 			{
						// 				label: 'Successful',
						// 				value: 'succesful',
						// 			},
						// 		],
						// 	},
						// ]}
						onExport={() => {
							// 	setShowModal((prev) => {
							// 		return { ...prev, export: true };
							// 	});
						}}
						page="bankbox_transactions"
						onSearchChange={debounce((e) => {
							// dispatch(
							// 	bankboxAPI.getAllFees({
							// 		per_page: 20,
							// 		page: currentPage,
							// 		search: e,
							// 	})
							// );
						}, 500)}
					/>
					<div className="pagination-wrap">
						{' '}
						<Pagination
							className="top-bar__pagination"
							currentPage={0}
							itemsPerPage={0}
							totalItems={0}
							onPageChange={() => {}}
						/>
					</div>
				</div>
				{/* dispute content end */}
				<div className="transaction__table non-mobile">
					<RavenTable
						action={false}
						className="table__main"
						headerList={[
							'MERCHANT DETAILS',
							'BUSINESS TYPE',
							'RC NUMBER',
							'IMAGE',
							'DATE',
							'STATUS',
							'',
						]}
					>
						{all_fees?.data?.map((chi: any, idx: any) => {
							const { name, BUSINESSTYPE, email, RCNUMBER, Status } = chi || {};
							return (
								<RavenTableRow
									key={idx}
									one={
										<>
											<div className="flex-con">
												<ImageFragment
													url={chi?.imageUrl || ''}
													className="profile-avatar"
													styles={{
														width: '4rem',
														height: '4rem',
														borderRadius: '50%',
														// background: '#f0f0f0',
													}}
													loadSize={30}
												>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '4rem',
															height: '4rem',
															borderRadius: '50%',
															background: 'rgba(247, 247, 247, 1)',
															fontSize: '1.4rem',
															fontWeight: 'bold',
															color: 'rgba(2, 2, 2, 1)',
														}}
													>
														{getInitials(name || 'NA')}
													</div>
												</ImageFragment>
												<TwoDetailView
													title={
														capitalizeFirstWord(
															formatTypeFunction(name) as any
														) || '----'
													}
													text_one={email}
												/>
											</div>
										</>
									}
									two={BUSINESSTYPE || '----'}
									three={trimText(RCNUMBER || '----', 50)}
									four={
										<>
											<div className="flex-img">
												<div>
													<ImageFragment
														className="profile-avatar"
														styles={{
															width: '4rem',
															height: '4rem',
															borderRadius: '50%',
															// background: '#f0f0f0',
														}}
														loadSize={30}
													>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																width: '4rem',
																height: '4rem',
																borderRadius: '50%',
																background: 'rgba(247, 247, 247, 1)',
																fontSize: '1.4rem',
																fontWeight: 'bold',
																color: 'rgba(2, 2, 2, 1)',
																position: 'relative',
															}}
														>
															{/* Show initials */}
															{/* {getInitials(name || 'NA')} */}

															{/* Add camera icon */}
															<span
																style={{
																	position: 'absolute',
																	bottom: '0.4rem',
																	// right: '0.4rem',
																	// background: '#fff',
																	borderRadius: '50%',
																	padding: '0.2rem',
																	// boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
																}}
															>
																{cameraIcon}
															</span>
														</div>
													</ImageFragment>
												</div>
												<div>
													<ImageFragment
														className="profile-avatar"
														styles={{
															width: '4rem',
															height: '4rem',
															borderRadius: '50%',
															// background: '#f0f0f0',
														}}
														loadSize={30}
													>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																width: '4rem',
																height: '4rem',
																borderRadius: '50%',
																background: 'rgba(247, 247, 247, 1)',
																fontSize: '1.4rem',
																fontWeight: 'bold',
																color: 'rgba(2, 2, 2, 1)',
																position: 'relative',
															}}
														>
															{/* Show initials */}
															{/* {getInitials(name || 'NA')} */}

															{/* Add camera icon */}
															<span
																style={{
																	position: 'absolute',
																	bottom: '0.4rem',
																	// right: '0.4rem',
																	// background: '#fff',
																	borderRadius: '50%',
																	padding: '0.2rem',
																	// boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
																}}
															>
																{downloadIcon}
															</span>
														</div>
													</ImageFragment>
												</div>
											</div>
										</>
									}
									five={<DateFragmentBox date={chi?.created_at} />}
									six={
										<>
											{/* Render badges based on the Status */}
											<BadgeComponent
												className={Status === 1 ? 'success' : 'warning'}
												text={Status === 1 ? 'verified' : 'pending'}
											/>
										</>
									}
									seven={
										<div
											style={{ position: 'relative' }}
											onClick={(e) => {
												e.stopPropagation();
												setShowDrop((prev) => ({
													...prev,
													id: idx,
													show: showDrop?.id === idx ? !showDrop.show : true,
												}));
											}}
										>
											<DropMore
												idx={idx}
												className={isDropped(idx) ? 'show-drop' : ''}
												chi={chi}
											/>
											{icons.three_dots}
										</div>
									}
								/>
							);
						})}
					</RavenTable>
				</div>
			</ComplianceLayout>

			{/* Table content end */}
		</>
	);
};

export default ComplianceCacVerification;
