import React, { useState } from 'react';
import './styles/terminalDetailsIndex.css';
import { formatNumWithCommaNairaSymbolTwo } from '../../../../../../../utils/helper/Helper';
import { icons } from '../../../../../../../assets/icons';
export interface UserTerminalDetail {
  name?: string;
  serialNo?: number;
  assignee?: string;
  volume?: number;
}
type showModalProp = {
  create: boolean;
  export: boolean;
  Set_to_In_Approved: boolean;
  Set_to_Dispatched: boolean;
  View_Details: boolean;
};
export interface UserTerminalDetailsProps {
  userDetails: UserTerminalDetail[]; // Array of terminal details
}
const UserTerminalDetails: React.FC<UserTerminalDetailsProps> = ({
  userDetails,
}) => {
  const usersSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      className="img"
    >
      <circle cx="11" cy="11" r="11" fill="#F7F7F7"></circle>
      <path
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.097"
        d="M12.939 6.438a2.41 2.41 0 0 1 1.305 2.156c0 .945-.531 1.762-1.305 2.156m1.044 2.799c.79.365 1.5.961 2.089 1.729m-10.443 0c1.016-1.325 2.396-2.14 3.916-2.14s2.9.815 3.916 2.14m-1.566-6.684c0 1.329-1.052 2.406-2.35 2.406s-2.35-1.077-2.35-2.406 1.052-2.406 2.35-2.406 2.35 1.077 2.35 2.406"
      ></path>
    </svg>
  );
  // State to manage dropdown visibility for each card
  const [showDrop, setShowDrop] = useState<number | null>(null); // Holds the serialNo of the currently active dropdown or null
  const [singleChild, setSingleChild] = useState<any>({});
  const [showModal, setShowModal] = useState<showModalProp>({
    create: false,
    export: false,
    Set_to_In_Approved: false,
    Set_to_Dispatched: false,
    View_Details: false,
  });
  // Function to check if the dropdown for a given serialNo is open
  function isDropped(serialNo: number) {
    return showDrop === serialNo;
  }
  const DropMore = ({ className, idx, chi }: any) => {
    return (
      <div className={`more-wrap ${className}`}>
        <div
          style={{
            opacity: '1',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSingleChild(chi);
            setShowModal((prev) => ({
              ...prev,
              Set_to_In_Approved: true,
            }));
          }}
          className="more_item"
        >
          <p>Set to In Approved</p>
        </div>
        <div
          style={{
            opacity: '1',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSingleChild(chi);
            setShowModal((prev) => ({
              ...prev,
              Set_to_Dispatched: true,
            }));
          }}
          className="more_item"
        >
          <p>Set to Dispatched</p>
        </div>
        <div
          style={{
            opacity: '1',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSingleChild(chi);
            setShowModal((prev) => ({
              ...prev,
              View_Details: true,
            }));
          }}
          className="more_item"
        >
          <p>View Details</p>
        </div>
      </div>
    );
  };
  return (
    <div className="user_terminal_details_general_container_holder_wrapper ">
      {userDetails.map((chi, index) => (
        <div
          key={index}
          className="user_terminal_details_general_container_holder  single-merchant-bg-color"
        >
          <div className="header_content_holder_wrap">
            <p className="terminal_hearder_name">{chi.name}</p>
            <p className="terminal_serial_number label">
              Serial No: {chi.serialNo}
            </p>
          </div>
          <div className="modal_content_holder_wrapper single-merchant-bg-color">
            <div className="modal_header_content">
              <div className="modal_header_content_users_assignee">
                <div>
                  <figure>{usersSvg}</figure>
                </div>
                <p className="modal_header_content_assignee">
                  Assignee: {chi.assignee}
                </p>
              </div>
              <div
                style={{ position: 'relative' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDrop((prev) =>
                    prev === (chi.serialNo ?? -1) ? null : chi.serialNo ?? -1
                  );
                }}
              >
                <DropMore
                  idx={chi.serialNo}
                  className={isDropped(chi.serialNo ?? -1) ? 'show-drop' : ''}
                  chi={chi}
                />
                {icons.three_dots}
              </div>
            </div>
            <div className="modal_bottom_content">
              <p className="modal_terminal_volumn">
                Volume in the last 30 Days
              </p>
              <p className="modal_terminal_volumn_content">
                {formatNumWithCommaNairaSymbolTwo(chi.volume)}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default UserTerminalDetails;