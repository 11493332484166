import React, { useEffect, useState } from 'react';
import SingleUserLayout from '../SingleUserLayout';
import Pagination from '../../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../../components/common/smartFilter';
import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../components/common/DateFragmentBox';
import {
	capitalizeFirstWord,
	formatTypeFunction,
	getActionNamesByPolicyName,
	trimLongString,
} from '../../../../../../utils/helper/Helper';
import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import TwoDetailView from '../../../../../../components/common/TwoDetailView';
import SettlementModal from './modal/settlementModal';
import TabContent from './modal/TabContent';
import ExportModal from '../../../../../../components/common/exportModal';
import { bankboxAPI } from '../../../../../../redux/bankbox';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../../redux/types';
import EmptyScreen from '../../../../../../components/common/emptyScreen';

const Dispute = () => {
	const dispatch = useDispatch();
	const [showExport, setShowExport] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalData, setModalData] = useState<any | null>(null);
	const [noContent, setNoContent] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);

	type showModalProp = {
		create: boolean;
		export: boolean;
		showdetails: boolean;
	};

	const [refreshTable, setRefreshTable] = useState(false);

	useEffect(() => {
		getFirstFetch();
	}, [currentPage || refreshTable, refreshTable]);

	const getFirstFetch = async () => {
		const data = await dispatch(
			bankboxAPI.getSingleAllDisputes({
				per_page: 20,
				page: currentPage,
			})
		);
		// console.log(data);

		if (
			data?.payload?.status === 'success' &&
			data?.payload?.data?.data?.data?.length > 0
		) {
			setNoContent(false);
		}
		// console.log(data);
	};

	// const handleFinish = () => {
	// 	setShowModal((prev) => {
	// 		return { ...prev, view: false, status: false };
	// 	});
	// 	setRefreshTable(!refreshTable);
	// };

	const { all_single_disputes, loading_roles } = useSelector(
		(state: RootState) => state.bankbox
	);

	const [singleChild, setSingleChild] = useState<any>({});
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		showdetails: false,
	});

	// Dummy data for all_fees


	const handleRowClick = (rowData: any) => {
		setModalData(rowData);
		setShowModal((prev) => ({
			...prev,
			showdetails: true, // Ensure this is set to true
		}));
	};

	return (
		<>
			{/* dispute content start */}
			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					filters={[
						{
							label: 'Status',
							filterBy: 'status',
							options: [
								{
									label: 'Pending',
									value: '0',
								},
								{
									label: 'Review',
									value: '3',
								},
								{
									label: 'Discarded',
									value: '2',
								},
								{
									label: 'Resolved',
									value: '4',
								},
							],
						},
					]}
					onExport={() => setShowExport(true)}
					onSearchChange={debounce((e) => {
						dispatch(
							bankboxAPI.getSingleAllDisputes({
								per_page: 20,
								page: currentPage,
								search: e,
							})
						);
					}, 500)}
					page="disputes"
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={all_single_disputes?.pagination?.per_page}
						totalItems={all_single_disputes?.pagination?.total}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			<div className="transaction__table non-mobile">
				<RavenTable
					action={false}
					className="table__main"
					headerList={[
						'REFERENCE',
						'TYPE',
						'NOTE',
						'AMOUNT',
						'DATE LOGGED',
						'STATUS',
					]}
				>
					{loading_roles &&  all_single_disputes?.data?.length < 1 ? <></> : <></>}
					{all_single_disputes?.data?.map((chi: any, idx: any) => {
						const {
							reference,
							email,
							type,
							Channel,
							note,
							TID,
							BVN,
							RRN,
							Stan,
							SerialNo,
							Cashier,
							amount,
							Status,
						} = chi || {};
						return (
							<RavenTableRow
								onRowClick={() => {
									handleRowClick(chi);
									// console.log(formatMetaData(chi?.meta_data));
								}}
								loading={loading_roles}
								// onRowClick={() => handleRowClick(chi)}
								key={idx}
								one={trimLongString(chi?.reference, 10) || '---'}
								two={type}
								three={trimLongString(note, 20) || '---'}
								four={RavenNumberFormat(amount || '----')}
								five={<DateFragmentBox date={chi?.created_at} />}
								six={
									<>
										{/* Render badges based on the Status */}
										<BadgeComponent
											className={Status === 1 ? 'success' : 'warning'}
											text={Status === 1 ? 'successful' : 'pending'}
										/>
									</>
								}
							/>
						);
					})}
				</RavenTable>
			</div>

			{/* Modal */}
			<TabContent
				amount={modalData?.amount}
				Fee={modalData?.Fee}
				RRN={modalData?.RRN}
				PNL={modalData?.PNL}
				Stan={modalData?.Stan}
				SerialNo={modalData?.SerialNo}
				TID={modalData?.TID}
				Cashier={modalData?.Cashier}
				Channel={modalData?.Channel}
				email={modalData?.email}
				reference={modalData?.reference}
				note={modalData?.note}
				type={modalData?.type}
				status={modalData?.Status === 1 ? 'Successful' : 'Pending'}
				date={new Date(modalData?.created_at)}
				onClose={() => {
					setShowModal((prev) => ({
						...prev,
						showdetails: false,
					}));
				}}
				visible={showModal?.showdetails}
				singleChild={singleChild}
			/>

			{/* view detail modal end */}
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="disputes"
				onFinishExport={(param) => {
					// console.log(param);
					window.open(param, '_blank')?.focus();
				}}
			/>
		</>
	);
};

export default Dispute;
