import React, { useEffect, useState } from 'react';
// import SingleUserLayout from '../SingleUserLayout';
import Pagination from '../../../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../../../components/common/smartFilter';
import BadgeComponent from '../../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../../components/common/DateFragmentBox';
import {
	capitalizeFirstLetter,
	capitalizeFirstWord,
	convertToHumanReadable,
	downloadCsvFile,
	formatDateHelper,
	formatDateTime,
	formatMetaData,
	formatTypeFunction,
	maskCardNumber,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';
import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
	RavenToolTip,
} from '@ravenpay/raven-bank-ui';
// import { bankboxAPI } from '../../../../../../redux/bankbox';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../../../redux/types';
import { bankboxAPI } from '../../../../../../../redux/bankbox';
import EmptyScreen from '../../../../../../../components/common/emptyScreen';
import Skeleton from 'react-loading-skeleton';
import TwoDetailView from '../../../../../../../components/common/TwoDetailView';
import ExportModal from '../../../../../../../components/common/exportModal';
import TransactionModal from '../../../../../../../components/common/transactionModal';

const Transaction = () => {
	const dispatch = useDispatch();
	const [showExport, setShowExport] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);
	const { user_merchant, all_merchant_bankbox_trx, loading_roles } =
		useSelector((state: RootState) => state.bankbox);
	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});

	//fetch transfers
	useEffect(() => {
		if (
			Object?.keys(user_merchant)?.length > 0 &&
			user_merchant?.user?.poseidon_email
		) {
			getMerchantAllTransaction();
		}
	}, [currentPage, user_merchant]);

	const getMerchantAllTransaction = async () => {
		dispatch(
			bankboxAPI.getMerchantAllTransactions({
				page: currentPage,
				per_page: 20,
				search: user_merchant?.user?.poseidon_email,
			})
		);
	};

	const csvHeader = [
		{ label: 's/n', key: 'sn' },
		{ label: 'terminal ID', key: 'id' },
		{ label: 'direction', key: 'direction' },
		{ label: 'system_reference', key: 'system_reference' },
		{ label: 'business_email', key: 'business_email' },
		{ label: 'type', key: 'type' },
		{ label: 'amount', key: 'amount' },
		{ label: 'credited_amount', key: 'credited_amount' },
		{ label: 'merchant_settlement', key: 'merchant_settlement' },
		{ label: 'fee', key: 'fee' },
		{ label: 'platform_fee', key: 'platform_fee' },
		{ label: 'balance_before', key: 'balance_before' },
		{ label: 'balance_after', key: 'balance_after' },
		{ label: 'stamp_duty', key: 'stamp_duty' },
		{ label: 'terminal_label', key: 'terminal_label' },
		{ label: 'transaction_reference', key: 'tranx_reference' },
		{ label: 'transaction_details', key: 'transaction_details' },
		{ label: 'provider', key: 'provider' },
		{ label: 'date', key: 'created_at' },
		{ label: 'status', key: 'status' },
	];
	const formatExportData = (param: any) => {
		if (param?.length > 0) {
			const newData = param?.map((chi: any, idx: any) => {
				return {
					sn: idx + 1 < 10 ? `0${idx + 1}` : idx + 1,
					id: chi?.t_id || 'NIL',
					direction: chi?.direction
						? capitalizeFirstLetter(chi?.direction)
						: 'NIL',
					system_reference: chi?.reference || 'NIL',
					business_email:
						formatMetaData(chi?.pdon_meta)?.terminal?.poseidon_business_email ||
						chi?.business_email ||
						'NIL',
					type:
						chi?.type === 'pdon_card'
							? `card_payment`
							: formatTypeFunction(chi?.type) || 'NIL',
					amount: RavenNumberFormat(chi?.amount) || 'NIL',
					credited_amount: RavenNumberFormat(chi?.credited_amount),
					merchant_settlement: RavenNumberFormat(chi?.merchant_settlement),
					fee: RavenNumberFormat(chi?.fee) || 'NIL',
					platform_fee: RavenNumberFormat(chi?.platform_fee),
					balance_before: RavenNumberFormat(chi?.b_before) || 'NIL',
					balance_after: RavenNumberFormat(chi?.b_after) || 'NIL',
					stamp_duty: RavenNumberFormat(chi?.stamp_duty) || 'NIL',
					serial:
						chi?.serial ||
						formatMetaData(chi?.pdon_meta)?.terminal?.serial ||
						'NIL',
					terminal_label:
						formatMetaData(chi?.pdon_meta)?.terminal?.tid_label || 'NIL',
					tranx_reference:
						chi?.type === 'transfer_in'
							? formatMetaData(chi?.meta_data)?.session_id
							: chi?.rrn || 'NIL',
					transaction_details:
						chi?.type === 'transfer_in'
							? `Account Name: ${
									formatMetaData(chi?.meta_data)?.source?.first_name || ''
							  } ${
									formatMetaData(chi?.meta_data)?.source?.last_name || ''
							  }, Account Number: ${
									formatMetaData(chi?.meta_data)?.source?.account_number
							  } ,  Bank: ${formatMetaData(chi?.meta_data)?.source?.bank} `
							: chi?.type === 'pdon_card' && !chi?.meta_data
							? maskCardNumber(formatMetaData(chi?.pdon_meta)?.card?.card_pan)
							: `${chi?.stan}`,
					// provider:
					// chi?.provider  || "NIL",
					created_at: chi?.created_at
						? formatDateHelper(chi?.created_at)?.replace('—', '•')
						: 'NIL',
					status: String(chi?.status) === '00' ? `Success` : `Failed`,
				};
			});

			downloadCsvFile(csvHeader, newData, 'transaction_data.csv');
		}
	};

	return (
		<>
			{/* dispute content start */}
			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
				// onClick={() => {
				// 	console.log(all_merchant_bankbox_trx);
				// }}
			>
				{' '}
				<SmartFilter
					searchTitle="Search by stan, RRN and email"
					defaultFilterBy="status"
					onSearchChange={debounce((e) => {
						// console.log(e);
						dispatch(
							bankboxAPI.getAllTransactions({
								per_page: 20,
								page: currentPage,
								// search: e,
								search: user_merchant?.user?.poseidon_email,
							})
						);
					}, 500)}
					filters={[
						{
							label: 'Status',
							filterBy: 'status',
							options: [
								{
									label: 'Successful',
									value: 'success',
								},
								{
									label: 'Failed',
									value: 'failed',
								},
								{
									label: 'All',
									value: 'reset',
								},
							],
						},
					]}
					filtersTwo={[
						{
							label: 'Type',
							filterBy: 'type',
							options: [
								{
									label: 'Card',
									value: 'pdon_card',
								},
								{
									label: 'Transfer In',
									value: 'transfer_in',
								},
								// {
								// 	label: 'Transfer Out',
								// 	value: 'transfer_out',
								// },
								{
									label: 'All',
									value: 'reset',
								},
							],
						},
					]}
					onExport={() => setShowExport(true)}
					page="bankbox_transactions"
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={all_merchant_bankbox_trx?.perPage ?? 0}
						totalItems={all_merchant_bankbox_trx?.total ?? 0}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			<div
				style={{ minHeight: '45rem' }}
				className="transaction__table non-mobile"
				// onClick={() => {
				// 	console.log(user_merchant);
					
				// }}
			>
				{ all_merchant_bankbox_trx?.data?.length < 1 ? (
					<EmptyScreen
						loading={loading_roles}
						loadingText={
							loading_roles ? 'Searching...' : 'Loading Transactions...'
						}
						
						title="No Merchant Transaction Found"
						subTitle="You don't have any record yet,your new bankbox transactions will be displayed here."
					/>
				) : (
					<>
						<RavenTable
							action={false}
							// className="table__main"
							headerList={[
								// 'Merchant',
								'Type',
								'Amount',
								'Details',
								'Status',
								'Transaction Date',
							]}
						>
							<>
								{' '}
								{all_merchant_bankbox_trx?.data?.map(
									(chi: any, idx: number) => {
										const meta = JSON.parse(chi.meta_data);
										const pdon = JSON.parse(chi.pdon_meta);

										return (
											<React.Fragment key={idx}>
												<RavenTableRow
													onRowClick={() => {
														onView({
															show: true,
															content: {
																...chi,
																meta_data: meta,
																pdon_meta: pdon,
															},
														});
														// console.log(pdon);
														// console.log(meta);
														// console.log(chi);
													}}
													// className="table__main"
													five={
														loading_roles ? (
															<Skeleton
																style={{ borderRadius: '10px' }}
																width={80}
																height={20}
															/>
														) : (
															<div className="tooltip-hover-wrap">
																{/* {formatStatus(meta?.response_code)} */}
																<BadgeComponent
																	text={
																		chi?.status === '00' ? 'success' : 'failed'
																	}
																	className={
																		chi?.status === '00' ? 'success' : 'failed'
																	}
																/>
																{formatMetaData(chi?.pdon_meta)?.card
																	?.response_meaning && (
																	<RavenToolTip
																		color="deep-green-light"
																		text={
																			formatMetaData(chi?.pdon_meta)?.card
																				?.response_meaning
																		}
																		position={`left`}
																	/>
																)}
															</div>
														)
													}
													six={
														loading_roles ? (
															<Skeleton width={100} height={20} />
														) : (
															// formatDateTime(chi?.created_at)
															<DateFragmentBox date={chi?.created_at} />
														)
													}
													two={
														loading_roles ? (
															<Skeleton width={100} height={20} />
														) : (
															convertToHumanReadable(chi?.type)?.replace(
																'Pdon',
																''
															)
														)
													}
													// one={'---'}
													four={
														loading_roles ? (
															<Skeleton width={80} height={20} />
														) : (
															<div className="type-wrap">
																<TwoDetailView
																	title={
																		chi?.type === 'transfer_in'
																			? `${meta?.source?.first_name} ${meta?.source?.last_name}`
																			: formatMetaData(chi?.pdon_meta)
																					?.card_meta?.card_pan || '---'
																	}
																	text_one={
																		chi?.type === 'transfer_in'
																			? `${trimLongString(
																					meta?.source?.bank,
																					22
																			  )} • ${meta?.source?.account_number}`
																			: `RRN: ${chi?.rrn}`
																	}
																/>
															</div>
														)
													}
													three={
														loading_roles ? (
															<Skeleton width={100} height={20} />
														) : (
															RavenNumberFormat(chi?.amount)
														)
													}
												/>
											</React.Fragment>
										);
									}
								)}
							</>
						</RavenTable>
					</>
				)}
			</div>
			<TransactionModal
				status={view?.content?.status === '00' ? 'success' : 'failed'}
				onClose={() => {
					// console.log(view?.content);

					onView({
						show: false,
						content: {
							type: '',
							bank: '',
							description: '',
							amount: '',
							date: '',
							session_id: '',
							account_number: '',
							email: '',
							created_at: function (created_at: any): string {
								throw new Error('Function not implemented.');
							},
							updated_at: function (updated_at: any): string {
								throw new Error('Function not implemented.');
							},
						},
						on: false,
					});
				}}
				visible={view.show}
				direction={view?.content?.direction}
				title={'Transactions Details'}
				amount={view?.content?.amount as unknown as number}
				onClick={() => {}}
				meta={JSON.stringify({
					...view?.content?.meta_data,
					...view?.content?.pdon_meta,
				})}
				content={
					view?.content?.type === 'transfer_in'
						? [
								{
									label: 'Narration',
									value: view?.content?.meta_data?.source?.narration || '--',
								},
								{
									label: 'Amount',
									value: RavenNumberFormat(view?.content?.amount),
								},
								{
									label: 'Fee',
									value: RavenNumberFormat(view?.content?.fee),
								},
								{
									label: 'Bank',
									value: view?.content?.meta_data?.source?.bank || '--',
								},
								{
									label: 'Account Name',
									value:
										view?.content?.meta_data?.source?.first_name +
											' ' +
											view?.content?.meta_data?.source?.last_name ||
										'--' ||
										'--',
								},
								{
									label: 'Account Number',
									value:
										view?.content?.meta_data?.source?.account_number || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'Terminal ID',
									value: view?.content?.t_id || '--',
									copy: true,
									trim: true,
								},

								{
									label: 'Session ID',
									value: view?.content?.meta_data?.session_id || '--',
									copy: true,
									trim: true,
								},

								{
									label: 'Reason',
									value: view?.content?.pdon_meta?.card?.response_meaning
										? view?.content?.pdon_meta?.card?.response_meaning
										: '--',
									// copy: true,
									dontSHow: view?.content?.pdon_meta?.card?.response_meaning
										? false
										: true,
								},
								{
									label: 'Created At',
									value: formatDateTime(
										view?.content?.created_at as unknown as string
									),
								},
						  ]
						: [
								// {
								// 	label: 'Serial',
								// 	value: view?.content?.serial || '--',
								// },
								{
									label: 'Amount',
									value: RavenNumberFormat(view?.content?.amount),
								},
								{
									label: 'Fee',
									value: RavenNumberFormat(view?.content?.fee),
								},
								{
									label: 'Business Email',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_owner_email || '--',
								},
								{
									label: 'Business Name',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_business_name || '--',
								},
								{
									label: 'Serial Number',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_serial_number || '--',
									copy: true,
									trim: true,
									// dontSHow: true,
								},
								{
									label: 'Terminal ID',
									value: view?.content?.t_id || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'Card Pan',
									value:
										view?.content?.pdon_meta?.card_meta?.card_pan ||
										maskCardNumber(view?.content?.pdon_meta?.card?.card_pan) ||
										'--',
									copy: true,
									trim: true,
								},
								{
									label: 'RRN',
									value: view?.content?.rrn || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'Stan',
									value: view?.content?.stan || '--',
									copy: true,
									trim: true,
								},
								{
									label: 'Merchant Reference',
									value: view?.content?.reference || '--',
									copy: true,
								},

								{
									label: 'Reason',
									value: view?.content?.pdon_meta?.card?.response_meaning
										? view?.content?.pdon_meta?.card?.response_meaning
										: '--',
									// copy: true,
									dontSHow: view?.content?.pdon_meta?.card?.response_meaning
										? false
										: true,
								},
								{
									label: 'BankBox Label',
									value:
										view?.content?.pdon_meta?.terminal_profile
											?.poseidon_label || '--',
								},
								{
									label: 'Created At',
									value: formatDateTime(
										view?.content?.created_at as unknown as string
									),
								},
						  ]
				}
			/>
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="bankbox_transactions"
				onFinishExport={(param) => {
					formatExportData(param);
				}}
			/>
		</>
	);
};

export default Transaction;
