import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import SmartFilter from '../../../../components/common/smartFilter';
import './style/Compliance.css';
import Pagination from '../../../../components/common/pagination';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RootState from '../../../../redux/types';
import { getActionNamesByPolicyName } from '../../../../utils/helper/Helper';
import EmptyScreen from '../../../../components/common/emptyScreen';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import './style/ComplianceLayout.css';
import { ALL_ROUTES } from '../../../../routes/dashboard/overview/AllPathNames';
import ComplianceTabLink from './ComplianceTabLink';

interface MyComponentProps {
	children?: React.ReactNode;
	btnContent?: React.ReactNode;
	tabLink?: React.ReactNode;
	activeTabIndex?: string;
	setActiveTabIndex?: (param?: any) => void;
}

const ComplianceLayout = ({
	children,
	btnContent,
	tabLink,
	activeTabIndex,
	setActiveTabIndex,
}: MyComponentProps) => {
	// const { profile } = useSelector((state: RootState) => state.settings);
	// const [activeTab, setActiveTab] = useState<string>('BVN Verification');
	const location = useLocation();
	const navigate = useNavigate();

	// Define tab routes and content

	const { profile } = useSelector((state: RootState) => state.settings);
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'compliance'
		)?.includes('view_compliance')
	) {
		return (
			<DashboardLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	// Render component
	return (
		<DashboardLayout>
			<PageLayout
				pageTitle="Compliance"
				pageSubtitle="Manage all Disputes made on the platform"
				topRightContent={<></>}
			>
				<div className="com-layout-index-wrap-box">
					{/* Tabs Navigation */}
					<div className="children-route-wrapper-box white-faded-white-bg com-layout">
						{/* tablink start */}
						<ComplianceTabLink
							activeTabIndex={activeTabIndex}
							setActiveTabIndex={setActiveTabIndex}
						/>
						{/* tab link end */}

						{/* Render Active Tab's Content */}
						<div className="children-wrap-box">{children}</div>
					</div>
				</div>
			</PageLayout>
		</DashboardLayout>
	);
};

export default ComplianceLayout;
