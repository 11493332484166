import React, { ReactNode, useEffect, useState } from 'react';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import UserDetailsInfo from './UserDetailsInfo';
import verified from '../../../../../assets/images/verified_m.png';
import '../newsingleuser/styles/Index.css';
import SingleMerchantTabLink from './components/SingleMerchantTabLink';
import { ALL_ROUTES } from '../../../../../routes/dashboard/overview/AllPathNames';
import RootState, {
	AssignBankBoxPayload,
	BankBoxUser,
} from '../../../../../redux/types';
import {
	RavenInputField,
	RavenModal,
	RavenToggleSlide,
	RavenToolTip,
	toast,
} from '@ravenpay/raven-bank-ui';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import {
	reactSelectStyle,
	validateStringForTerminalId,
} from '../../../../../utils/helper/Helper';
import CameraAssignBox from '../cameraAssign/CameraAssignBox';
import { icons } from '../../../../../assets/icons';
import SettleMentAccountModal from '../component/SettleMentAccountModal';
import UpdateMerchantFeeUserModal from '../../components/modals/UpdateFeeMerchantModal';
import UpdateBankboxUserModal from '../../components/modals/UpdateBankboxUserModal';
import ProccessSettleMentAccountModal from '../component/ProcessSettlementMerchant';

interface SingleUserLayoutProps {
	children?: React.ReactNode;
	btnContent?: React.ReactNode;
	modalContent?: React.ReactNode;
	tabLink?: React.ReactNode;
	activeTabIndex?: string;

	setActiveTabIndex?: (param?: any) => void;
}

const userDetails = {
	logo: verified,
	businessName: 'Nathaniel and Sons LTD',
	ownerName: 'Adeeko Emmanuel',
	email: 'emmy4su.web@gmail.com',
	dateJoined: '26, October 2021',
	bvn: '567654323456',
	nin: '567654323456',
	category: 'Financial Technology',
	region: 'Lagos State',
	address: '10, Oyedele Close, Lagos State',
	description: 'Deals in Importing and Exporting Goods.',
	walletBalance: '₦45,533,584.00',
	lien: '₦0.00',
};

const SingleUserLayout: React.FC<SingleUserLayoutProps> = ({
	children,
	btnContent,
	tabLink,
	activeTabIndex,
	setActiveTabIndex,
	modalContent,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showSettlement, setShowSettlement] = useState<boolean>(false);
	const [editMerchantFeeModal, setEditMerchantFeeModal] =
		useState<boolean>(false);
	const { loading, user_merchant, all_states, all_lga } = useSelector(
		(state: RootState) => state.bankbox
	);

	const dispatch = useDispatch();

	const infoIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_164_20412)">
				<path
					d="M7.99967 10.6668V8.00016M7.99967 5.3335H8.00634M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z"
					stroke="black"
					strokeWidth="1.2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_164_20412">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
	const cameraIcon = (
		<svg
			className="img"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_16594_47752)">
				<path
					d="M5.97578 16.0249V5.64153C5.97578 4.9518 5.97578 4.60694 6.05209 4.32399C6.25917 3.55616 6.86282 2.95642 7.63564 2.75068C7.92043 2.67487 8.26754 2.67487 8.96176 2.67487C9.65597 2.67487 10.0031 2.67487 10.2879 2.75068C11.0607 2.95642 11.6643 3.55616 11.8714 4.32399C11.9477 4.60694 11.9477 4.9518 11.9477 5.64153V10.4624M11.9477 13.429V16.0249M5.07999 16.0249H12.8435C14.0977 16.0249 14.7249 16.0249 15.2039 15.7824C15.6253 15.569 15.9679 15.2287 16.1826 14.81C16.4267 14.334 16.4267 13.711 16.4267 12.4649V9.20153C16.4267 7.95542 16.4267 7.33236 16.1826 6.8564C15.9679 6.43774 15.6253 6.09736 15.2039 5.88404C14.7249 5.64153 14.0977 5.64153 12.8435 5.64153H5.07999C3.82577 5.64153 3.19865 5.64153 2.7196 5.88404C2.29822 6.09736 1.95562 6.43774 1.74091 6.8564C1.49683 7.33236 1.49683 7.95542 1.49683 9.20153V12.4649C1.49683 13.711 1.49683 14.334 1.74091 14.81C1.95562 15.2287 2.29822 15.569 2.7196 15.7824C3.19865 16.0249 3.82577 16.0249 5.07999 16.0249ZM9.55895 13.429H13.2168C13.4258 13.429 13.5303 13.429 13.6102 13.3886C13.6804 13.3531 13.7375 13.2963 13.7733 13.2266C13.814 13.1472 13.814 13.0434 13.814 12.8357V11.0557C13.814 10.848 13.814 10.7442 13.7733 10.6648C13.7375 10.5951 13.6804 10.5383 13.6102 10.5028C13.5303 10.4624 13.4258 10.4624 13.2168 10.4624H9.55895C9.34991 10.4624 9.24539 10.4624 9.16555 10.5028C9.09532 10.5383 9.03822 10.5951 9.00244 10.6648C8.96176 10.7442 8.96176 10.848 8.96176 11.0557V12.8357C8.96176 13.0434 8.96176 13.1472 9.00244 13.2266C9.03822 13.2963 9.09532 13.3531 9.16555 13.3886C9.24539 13.429 9.34991 13.429 9.55895 13.429Z"
					stroke="black"
					strokeWidth="1.335"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_16594_47752">
					<rect
						width="17.9158"
						height="17.8"
						fill="white"
						transform="translate(0.00390625 0.44986)"
					/>
				</clipPath>
			</defs>
		</svg>
	);

	// const [activeTabIndex, setActiveTabIndex] = useState<string>(
	// 	ALL_ROUTES?.SINGLE_TRANSACTION
	// );
	const deviceTypeOption = [
		{ label: 'BankBox', value: 'bankbox' },
		{ label: 'BankBox PinPad', value: 'bankbox_pinpad' },
	];
	const [assignFormData, setAssignFormData] = useState<AssignBankBoxPayload>({
		// affiliate_app_id: '',
		poseidon_business_email: '',
		serial_number: '',
		label: '',
		poseidon_business_name: '',
		exist_nibbs: true,
		// term_id: '',
		// auto_gen: true,
		auto_generate: true,
		is_nibss: true,
		terminal_id: '',
		device_type: '',
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [uploadViaCamera, setUploadViaCamera] = useState(false);
	const [requestSettleMerchantModal, setRequestSettleMerchantModal] =
		useState<boolean>(false);
	const [terminalDetails, setTerminalDetails] = useState<any>({});
	const [selectedType, setSelectedType] = useState('Yes, It is');
	const selectTypeList = ['Yes, It is', 'No, it’s not'];
	const [assignBankbox, setAssignBankbox] = useState<{
		on: boolean;
		chi: BankBoxUser;
	}>({
		on: false,
		chi: {
			id: 0,
			fname: '',
			lname: '',
			poseidon_email: '',
			bvn: '',
			nin: '',
			phone: '',
			affiliate_app: '',
			affiliate_app_id: '',
			poseidon_business_name: '',
			poseidon_business_address: '',
			poseidon_business_type: null,
			poseidon_business_description: '',
			poseidon_profile_reference: '',
			business_category: '',
			region: '',
			created_at: '',
			updated_at: '',
			lga: '',
		},
	});

	interface formComponent {
		state: any;
		lga: any;
	}
	const [details, setDetails] = useState<formComponent>({
		state: '',
		lga: '',
	});

	const [singleChild, setSingleChild] = useState<any>({});
	const [editMerchantModal, setEditMerchantModal] = useState<boolean>(false);
	const [showTerminalError, setShowTerminalError] = useState(false);
	const [autoAssign, setAutoAssign] = useState(true);

	useEffect(() => {
		getFirstFetch();
	}, []);

	useEffect(() => {
		if (Object.keys(all_states)?.length < 1) {
			dispatch(bankboxAPI.getBankboxStates({}));
		}
	}, []);

	const getFirstFetch = async () => {
		dispatch(
			bankboxAPI.getAllFeesWithoutPagination({
				per_page: 20,
				page: currentPage,
				// affiliate_app_id:
			})
		);
	};

	async function handleAssign() {
		if (
			!validateStringForTerminalId(assignFormData?.terminal_id) &&
			!autoAssign
		) {
			toast.error(
				'Terminal ID must be exactly 8 characters, all capitalized and alphanumeric.'
			);
			setShowTerminalError(true);
			return;
		}
		const obj = {
			...assignFormData,
			auto_generate: autoAssign,
			is_nibss: autoAssign ? false : assignFormData?.exist_nibbs,
			terminal_id: assignFormData?.terminal_id,
			state: details?.state?.value,
			city: details?.lga,
			poseidon_business_name: user_merchant?.user?.poseidon_business_name,
			poseidon_business_email: user_merchant?.user?.poseidon_email,
			device_type: assignFormData?.device_type?.value,
		};
		// console.log(obj);

		const resp = await dispatch(bankboxAPI.assignBankbox(obj));

		if (resp.payload?.status === 'success') {
			setAssignBankbox({
				...assignBankbox,
				on: false,
			});
			getFirstFetch();
			setAssignFormData({
				exist_nibbs: true,
				// affiliate_app_id: '',
				poseidon_business_email: '',
				serial_number: '',
				label: '',
				poseidon_business_name: '',
				auto_generate: false,
				is_nibss: true,
				terminal_id: '',
				device_type: '',
			});
		}
	}
	const [el, setEl] = React.useState<any>({
		id: 0,
		fname: '',
		lname: '',
		poseidon_email: '',
		bvn: '',
		nin: '',
		phone: '',
		affiliate_app: '',
		affiliate_app_id: '',
		poseidon_business_name: '',
		poseidon_business_address: '',
		poseidon_business_type: null,
		poseidon_business_description: '',
		poseidon_profile_reference: '',
		business_category: '',
		region: '',
		created_at: '',
		updated_at: '',
		ref_fee: '',
	});
	React.useEffect(() => {
		setEl(user_merchant?.user);
	}, [user_merchant]);

	const merchant_email = localStorage?.getItem('merchant-email-user-preview');
	const [refreshTable, setRefreshTable] = useState(false);
	useEffect(() => {
		if (merchant_email) {
			// coming back here with user_merchant
			getSingleDetails();
		} else {
			navigate('dashboard-bankbox/users');
		}
	}, [refreshTable]);

	const formatState = (param: any) => {
		if (param?.length > 0) {
			const newList = param?.map((chi: any) => {
				return { label: chi, value: chi };
			});
			return newList;
		}
	};

	const [feeDetail, setFeeDetail] = useState<any>({});
	const [singleChildFee, setSingleChildFee] = useState<any>({});
	const getSingleDetails = async () => {
		const data = await dispatch(
			bankboxAPI.getSingleUserMerchant({ email: merchant_email as string })
		);
		// console.log(data);
		if (data?.payload?.status === 'success') {
			const objFee = data?.payload?.data?.data?.fees;
			const user = data?.payload?.data?.data?.user;
			// console.log(data?.payload?.data?.data);

			const newObj = { ...user, fee_ref: objFee?.id };
			// console.log(newObj);
			setSingleChildFee(newObj);
			setFeeDetail(objFee);
		}
	};

	// List of tabs
	const singleuserList = [
		{
			name: 'Transaction',
			active:
				location?.pathname === '/dashboard-bankbox/single-user-transaction',
			path: '/dashboard-bankbox/user-transaction',
			onClick: () => navigate('/dashboard-bankbox/single-user-transaction'),
		},
		{
			name: 'Device Requests',
			active:
				location?.pathname === '/dashboard-bankbox/single-user-device_requests',
			path: '/dashboard-bankbox/device-requests',
			onClick: () => navigate('/dashboard-bankbox/single-user-device_requests'),
		},
		{
			name: 'Settlements',
			active:
				location?.pathname === '/dashboard-bankbox/single-user-Settlements',
			path: '/dashboard-bankbox/Settlements',
			onClick: () => navigate('/dashboard-bankbox/single-user-Settlements'),
		},
		{
			name: 'Dispute',
			active: location?.pathname === '/dashboard-bankbox/single-dispute',
			path: '/dashboard-bankbox/Dispute',
			onClick: () => navigate('/dashboard-bankbox/single-dispute'),
		},
		{
			name: 'Terminals',
			active: activeTabIndex === ALL_ROUTES?.SINGLE_USER_TERMINAL,
			path: ALL_ROUTES?.SINGLE_USER_TERMINAL,
			onClick: () =>
				setActiveTabIndex &&
				setActiveTabIndex(ALL_ROUTES?.SINGLE_USER_TERMINAL),
		},
		{
			name: 'Payment Links',
			active: location?.pathname === ALL_ROUTES?.SINGLE_MERCHANT_PAYMENT_LINK,
			path: ALL_ROUTES?.SINGLE_MERCHANT_PAYMENT_LINK,
			onClick: () => navigate(ALL_ROUTES?.SINGLE_MERCHANT_PAYMENT_LINK),
		},
		{
			name: 'Compliance',
			active: activeTabIndex === '/dashboard-bankbox/users-single-compliance',
			path: '/dashboard-bankbox/user-single-compliance',
			onClick: () =>
				setActiveTabIndex &&
				setActiveTabIndex('/dashboard-bankbox/users-single-compliance'),
		},

		// {
		// 	name: 'Payment Detail Table',
		// 	active: location?.pathname === '/dashboard-bankbox/users-single-detail-table',
		// 	path: '/dashboard-bankbox/user-single-detail-table',
		// 	onClick: () => navigate('dashboard-single-merchant-detail-table'),
		// },

		// {
		// 	name: 'Compliance BVN Verification',
		// 	active: location?.pathname === '/dashboard-bankbox/user-transaction',
		// 	path: '/dashboard-bankbox/user-transaction',
		// 	onClick: () => navigate(''),
		// },
	];

	// State to track the active tab index, initially set to the first tab
	// const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

	return (
		<>
			<DashboardLayout back onBack={() => navigate(ALL_ROUTES?.BANKBOX_USER)}>
				<PageLayout
					pageTitle="Merchant Details"
					pageSubtitle="All Details about this Merchant"
					topRightContent={<></>}
					loading={loading}
					noContent={Object?.keys(user_merchant)?.length < 1}
				>
					{/* UserDetailsInfo comes first */}
					<UserDetailsInfo
						assignBankbox={assignBankbox}
						setAssignBankbox={setAssignBankbox}
						userDetails={userDetails}
						setViewSettleMent={setShowSettlement}
						setViewFeeDetails={setEditMerchantFeeModal}
						setSingleChild={setSingleChild}
						setEditMerchantModal={setEditMerchantModal}
						setRequestSettleMerchantModal={setRequestSettleMerchantModal}
					/>
					<div
						className="com-layout-index-wrap-box"
						style={{
							marginBottom: '1.5rem',
							flex: 'unset',
							overflowY: 'unset',
						}}
					>
						{/* Tabs Navigation */}
						<div className="children-route-wrapper-box white-faded-white-bg -layout non-over-flow-y-div">
							{/* tablink start */}
							<SingleMerchantTabLink
								activeTabIndex={activeTabIndex}
								setActiveTabIndex={setActiveTabIndex}
							/>
							{/* tab link end */}

							{/* Render Active Tab's Content */}
							<div className="children-wrap-box">{children}</div>
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>

			<RavenModal
				onClose={() => setAssignBankbox({ ...assignBankbox, on: false })}
				visble={assignBankbox.on}
				// visble={true}
				loading={loading}
				onBtnClick={handleAssign}
				btnColor="deep-green-light"
				btnLabel={!uploadViaCamera ? 'Assign Terminal' : ''}
				className={`session-expired-wrap-modal`}
				disabled={
					autoAssign &&
					(!assignFormData?.label ||
						!assignFormData?.serial_number ||
						!assignFormData?.device_type ||
						!details?.lga ||
						!details?.state)
						? true
						: !autoAssign &&
						  (!assignFormData?.terminal_id ||
								!assignFormData?.label ||
								!details?.lga ||
								!details?.state ||
								!assignFormData?.device_type)
						? true
						: false
				}
			>
				<div className="assign-bb-modal">
					<div className="assign-bb-modal__title">
						<h6>Assign BankBox</h6>
						<p>Allocate a BankBox to a user </p>
					</div>
					{/* <MyComponent />  */}
					{/* {!uploadViaCamera && (
								<div
									onClick={() => {
										setUploadViaCamera(true);
									}}
									className="camera-icon-box"
								>
									<div className="camera-icon">
										<figure className="img-box grey-bg">{cameraIcon}</figure>
									</div>
									<p>Use webcam to get bankbox serial no</p>
								</div>
							)} */}
					{uploadViaCamera ? (
						<>
							{/* <WebcamCapture /> */}
							<CameraAssignBox
								onCancel={() => {
									setUploadViaCamera(false);
								}}
								onFinish={(param: any) => {
									setUploadViaCamera(false);
									setTerminalDetails(param);
									setAssignFormData((prev) => {
										return { ...prev, serial_number: param?.actualValue };
									});
								}}
							/>
						</>
					) : (
						<>
							{' '}
							<div className="assign-bb-modal__form-body">
								{/* <RavenInputField
									type="select"
									color="deep-green-light"
									value={assignFormData?.fee_category}
									label="Fee Category"
									style={{ position: 'relative', zIndex: 10 }}
									placeholder="Select Fee Category"
									name="fee_category"
									selectOption={formatFeeCategory()}
									onChange={(e: any) => {
										setAssignFormData((prev) => {
											return { ...prev, fee_category: e };
										});
									}}
								/> */}
								<div className="serial-number-input-form-group">
									<RavenInputField
										type={`account-number`}
										maxLength={10}
										color="deep-green-light"
										value={assignFormData?.serial_number}
										label="BankBox Serial Number"
										onChange={(e: any) => {
											setAssignFormData({
												...assignFormData,
												serial_number: e.target.value,
											});
											if (terminalDetails) {
												setTerminalDetails({});
											}
										}}
										placeholder="Input last 10 digit of serial number"
										name="serial"
										labelSpanText={
											terminalDetails?.fullValue ? (
												((
													<>
														<div
															onClick={() => {
																setUploadViaCamera(true);
															}}
															className="camera-icon-box"
														>
															<div className="camera-icon">
																<figure className="img-box grey-bg">
																	{cameraIcon}
																</figure>
															</div>
															<p>Input via webcam</p>
														</div>
													</>
												) as any)
											) : (
												<div
													onClick={() => {
														setUploadViaCamera(true);
													}}
													className="camera-icon-box"
												>
													<div className="camera-icon">
														<figure className="img-box grey-bg">
															{cameraIcon}
														</figure>
													</div>
													<p>Input via webcam </p>
												</div>
											)
										}
									/>
								</div>

								<RavenInputField
									type="text"
									value={assignFormData?.label}
									onChange={(e: any) => {
										setAssignFormData({
											...assignFormData,
											label: e.target.value,
										});
									}}
									color="deep-green-light"
									label="BankBox Label"
									placeholder="E.g Eatery one"
									name="serial"
								/>
								<RavenInputField
									style={{
										position: 'relative',
										zIndex: 100,
										padding: '0rem .2rem',
									}}
									type="select"
									value={assignFormData?.device_type}
									onChange={(e: any) => {
										setAssignFormData({
											...assignFormData,
											device_type: e,
										});
									}}
									color="deep-green-light"
									label="Device Type"
									placeholder="E.g Select device type"
									name="device_type"
									menuPlacement={`top`}
									selectOption={deviceTypeOption}
									selectStyles={reactSelectStyle}
								/>
								{/* two input field box start */}
								<div
									style={{ position: 'relative', zIndex: '101' }}
									className="two-input-field-box"
								>
									<form autoComplete="off" style={{ width: '100%' }} action="">
										<RavenInputField
											menuPlacement={`top`}
											selectStyles={reactSelectStyle}
											color="deep-green-light"
											label="State * "
											type="select"
											placeholder="Select option"
											value={details?.state}
											onChange={(e: any) => {
												setDetails((prev) => {
													return { ...prev, state: e, lga: '' };
												});
												// if
												// dispatch(
												// 	bankboxAPI.getBankboxLgaByStates({ region: e.value })
												// );
											}}
											// disabled={user?.region ? true : false}
											selectOption={formatState(all_states)}
										/>
									</form>
									<form autoComplete="off" style={{ width: '100%' }} action="">
										{' '}
										<RavenInputField
											selectStyles={reactSelectStyle}
											menuPlacement={`top`}
											color="deep-green-light"
											label="City* "
											type="text"
											placeholder="Enter city"
											disabled={!details?.state}
											value={details?.lga}
											onChange={(e: any) => {
												setDetails((prev) => {
													return { ...prev, lga: e?.target?.value };
												});
											}}
											selectOption={formatState(all_lga)}
											// loadingSelect={true}
										/>
									</form>
								</div>
								{/* two input field box end */}
								{/* auto generate box start */}
								<div
									style={{ position: 'relative', zIndex: '20' }}
									className="auto-generate-box-wrap"
								>
									<div className="togle-info-box">
										{' '}
										<RavenToggleSlide
											value={autoAssign}
											checked={autoAssign}
											onChange={() => {
												setAutoAssign(!autoAssign);
											}}
											color={`deep-green-light`}
										/>
									</div>

									<p className="text">Autogenerate Terminal ID</p>
									<div className="info-box">
										<figure className="img-box tooltip-hover-wrap">
											{infoIcon}
											<RavenToolTip
												textColor="white-light"
												color={`deep-green-light`}
												position={`top-left`}
												text="Autogenerate Terminal info"
											/>
										</figure>
									</div>
								</div>
								{/* auto generate box end */}
								{/* auto assin content start */}
								{!autoAssign && (
									<div className="auto-assign-content-box">
										<RavenInputField
											type="text"
											value={assignFormData?.terminal_id}
											showError={showTerminalError}
											onChange={(e: any) => {
												if (e.target.value?.length < 9) {
													setShowTerminalError(false);
													setAssignFormData((prev) => {
														return { ...prev, terminal_id: e.target.value };
													});
												}
											}}
											color="deep-green-light"
											label="Terminal ID"
											placeholder="Enter Terminal ID"
											name="term_id"
											// maxSize={8}
										/>
										{/* select option start  */}
										<div
											className="select-option-box-wrap"
											style={{ marginTop: '2.4rem' }}
										>
											<label htmlFor="">
												Is this Terminal ID registered on NIBSS?
											</label>
											<div className={'export-modal__export-as--selector-wrap'}>
												{selectTypeList?.map((chi, idx) => {
													return (
														<div
															key={idx}
															onClick={() => {
																setSelectedType(chi);
																setAssignFormData((prev) => {
																	return {
																		...prev,
																		exist_nibbs:
																			chi === 'Yes, It is' ? true : false,
																	};
																});
															}}
															className={`selector-wrap__item ${
																selectedType === chi && 'selected'
															}`}
														>
															<figure>
																{selectedType === chi
																	? icons.radio_check
																	: icons.radio_unchecked}
															</figure>
															<p>{chi}</p>
														</div>
													);
												})}
											</div>
										</div>
										{/* select option end */}
									</div>
								)}
								{/* auto assin content end */}
							</div>
						</>
					)}
				</div>
			</RavenModal>

			<SettleMentAccountModal
				visible={showSettlement}
				onClose={() => {
					setShowSettlement(false);
				}}
			/>
			<RavenModal
				visble={editMerchantFeeModal}
				onClose={() => setEditMerchantFeeModal(false)}
				className={'bankbox-create-modal bankbox-create-modal-update-fee'}
				onBtnClick={function (e?: any): void {
					throw new Error('Function not implemented.');
				}}
			>
				<UpdateMerchantFeeUserModal
					onComplete={() => {
						setRefreshTable(!refreshTable);
						setEditMerchantFeeModal(false),
							toast.success('BankBox merchant fee updated successfully');
					}}
					detail={singleChildFee}
					feeDetail={feeDetail}
					setFeeDetail={setFeeDetail}
					visible={editMerchantFeeModal}
				/>
			</RavenModal>
			<RavenModal
				visble={editMerchantModal}
				onClose={() => setEditMerchantModal(false)}
				className={'bankbox-create-modal'}
				onBtnClick={function (e?: any): void {
					throw new Error('Function not implemented.');
				}}
			>
				<UpdateBankboxUserModal
					onComplete={() => {
						setRefreshTable(!refreshTable);
						setEditMerchantModal(false),
							toast.success('BankBox merchant updated successfully');
					}}
					detail={singleChild}
					visible={editMerchantModal}
				/>
			</RavenModal>
			<ProccessSettleMentAccountModal
				onClose={() => {
					setRequestSettleMerchantModal(false);
				}}
				el={el}
				visible={requestSettleMerchantModal}
				onFinish={() => {
					setRefreshTable(!refreshTable);
				}}
			/>
			{modalContent}
		</>
	);
};

export default SingleUserLayout;
