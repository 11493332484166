import React, { ReactNode, useState } from 'react';
import DashboardLayout from '../../../../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../../../../layout/dashboard/tableLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import UserDetailsInfo from './UserDetailsInfo';
import verified from '../../../../../../../assets/images/verified_m.png';
import LinkDetailsInfo from '../paymentLinks/linkDetailsInfo';
import SingleUserLayout from '../../SingleUserLayout';
import SmartFilter from '../../../../../../../components/common/smartFilter';
import { debounce } from 'lodash';
import Pagination from '../../../../../../../components/common/pagination';
import { RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import {
	capitalizeFirstWord,
	formatNumWithCommaNairaSymbolTwo,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';
import BadgeComponent from '../../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../../components/common/DateFragmentBox';

interface linkLayoutProps {
	children?: ReactNode;
	btnContent?: React.ReactNode;
}
interface linkDetails {
	logo: string;
	businessName: string;
	ownerName: string;
	email: string;
	dateJoined: string;
	bvn: string;
	nin: string;
	category: string;
	region: string;
	address: string;
	description: string;
	walletBalance: string;
	lien: string;
}

// const linkDetailss = {
// 	logo: verified,
// 	businessName: 'Nathaniel and Sons LTD',
// 	ownerName: 'Adeeko Emmanuel',
// 	email: 'emmy4su.web@gmail.com',
// 	dateJoined: '26, October 2021',
// 	bvn: '567654323456',
// 	nin: '567654323456',
// 	category: 'Financial Technology',
// 	region: 'Lagos State',
// 	address: '10, Oyedele Close, Lagos State',
// 	description: 'Deals in Importing and Exporting Goods.',
// 	walletBalance: '₦45,533,584.00',
// 	lien: '₦0.00',
// };
interface BusinessInfoCardProps {
	linkDetail: linkDetails;
}

const PaylinkLayoutTable = () => {
	const navigate = useNavigate();
	const location = useLocation();

	// List of tabs
	const dummyData = [
		{
			fullName: 'John Doe',
			email: 'john.doe@example.com',
			amount: 150.25,
			paymentDate: '2025-01-10',
		},
		{
			fullName: 'Jane Smith',
			email: 'jane.smith@example.com',
			amount: 89.99,
			paymentDate: '2025-01-11',
		},
		{
			fullName: 'Robert Brown',
			email: 'robert.brown@example.com',
			amount: 200.0,
			paymentDate: '2025-01-12',
		},
		{
			fullName: 'Emily Davis',
			email: 'emily.davis@example.com',
			amount: 50.75,
			paymentDate: '2025-01-13',
		},
		{
			fullName: 'Michael Wilson',
			email: 'michael.wilson@example.com',
			amount: 125.5,
			paymentDate: '2025-01-14',
		},
		{
			fullName: 'Sophia Johnson',
			email: 'sophia.johnson@example.com',
			amount: 300.1,
			paymentDate: '2025-01-15',
		},
		{
			fullName: 'Daniel Lee',
			email: 'daniel.lee@example.com',
			amount: 75.8,
			paymentDate: '2025-01-16',
		},
		{
			fullName: 'Olivia Martinez',
			email: 'olivia.martinez@example.com',
			amount: 99.99,
			paymentDate: '2025-01-17',
		},
		{
			fullName: 'James Garcia',
			email: 'james.garcia@example.com',
			amount: 180.6,
			paymentDate: '2025-01-18',
		},
		{
			fullName: 'Isabella Hernandez',
			email: 'isabella.hernandez@example.com',
			amount: 220.0,
			paymentDate: '2025-01-19',
		},
	];
  const paymentDetails = {
    title: "Spotify Premium for the boys",
    subtitle: "Payment for the design and development of a new website",
    linkType: "Multicharge",
    status: "Pending", // Status can be "Pending", "Completed", "Cancelled", etc.
    currencyAbrev: "USD", // Currency abbreviation
    amountReceived: 1500, // Amount received
    amountRequested: 2000, // Amount requested
    noOfPayments: 3, // Number of payments made or required
    dateCreated:  new Date(2025, 0, 18), // Date when this record was created
    link: "https://example.com/payment/details/12345", // Payment details link
  };
  
  
  

	
	return (
		<div>
			<DashboardLayout back onBack={() => navigate('/dashboard-bankbox/users')}>
				<PageLayout
					pageSubtitle="All Details about this User"
					pageTitle="Link Details"
					topRightContent={<></>}
				>
          <div>
            <LinkDetailsInfo linkDetails={paymentDetails}
            />
          </div>
					<div
						style={{ marginBottom: '1.5rem' }}
						className="global-search-pagination-filter-box"
					>
						<SmartFilter
							searchTitle="Search Terminals..."
							defaultFilterBy="status"
							onSearchChange={debounce((e) => {}, 500)}
						/>
						<div className="pagination-wrap">
							<Pagination
								className="top-bar__pagination"
								currentPage={0}
								itemsPerPage={0}
								totalItems={0}
								onPageChange={() => {}}
							/>
						</div>
					</div>
					<div>
						{/* table content start */}
						<div className="transaction__table non-mobile">
							<RavenTable
								action={false}
								className="table__main"
								headerList={['FULLNAME', 'EMAIL', 'AMOUNT', 'PAYMENT DATE']}
							>
								{dummyData.map((chi: any, idx: any) => (
									<RavenTableRow
										// loading={loadingFetch}
										// onRowClick={() => {
										// 	setShowModal({ bills_modal: true });
										// }}

										one={capitalizeFirstWord(chi?.fullName || '---') || '----'}
										two={trimLongString(chi.email, 20) || '---'}
										three={formatNumWithCommaNairaSymbolTwo(chi.amount)}
										four={<DateFragmentBox date={chi?.paymentDate} />}
										// seven={
										//   <div
										//     style={{ position: 'relative' }}
										//     onClick={(e) => {
										//       e.stopPropagation();
										//       setShowDrop((prev) => ({
										//         ...prev,
										//         id: idx,
										//         show: showDrop?.id === idx ? !showDrop.show : true,
										//       }));
										//     }}
										//   >
										//     <DropMore
										//       idx={idx}
										//       className={isDropped(idx) ? 'show-drop' : ''}
										//       chi={chi}
										//     />
										//     {icons.three_dots}
										//   </div>
										// }
									/>
								))}
							</RavenTable>
						</div>
						{/* table content end */}
					</div>
				</PageLayout>
			</DashboardLayout>
		</div>
	);
};

export default PaylinkLayoutTable;
