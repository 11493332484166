import { useEffect, useState } from 'react';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
} from '@ravenpay/raven-bank-ui';
import '../../../../settlements/styles/settlementModal.css';
import BadgeComponent from '../../../../../../../components/common/badge/BadgeComponent';
import Copy from '../../../../../../../components/common/copyCheck';

interface ComponentProps {
	email?: string;
	amount?: number;
	reference?: string;
	note?: string;
	bank?: string;
	type?: string;
	status?: string;
	Channel?: string;
	date?: Date;
	TID?: number;
	visible?: boolean;
	onClose?: () => void;
	singleChild?: any;
	Cashier?: String;
	SerialNo?: number;
	Stan?: number;
	RRN?: number;
	Fee?: number;
	PNL?: number;
}

const TabContent: React.FC<ComponentProps> = ({
	email,
	TID,
	amount,
	reference,
	Cashier,
	note,
	Channel,
	bank,
	SerialNo,
	type,
	status,
	PNL,
	RRN,
	date,
	onClose,
	Fee,
	visible,
	singleChild,
	Stan,
}) => {
	const [activeTab, setActiveTab] = useState('Dispute');

	useEffect(() => {
		if (visible) {
			setActiveTab('Dispute');
		}
	}, [visible]);

	const SIcon = (
		<svg
			className="img"
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M10 10V6m0 0H6m4 0-4 4m8.667-2A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0"
			></path>
		</svg>
	);

	const RIcon = (
		<svg
			className="img"
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#EA872D"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2m.054 2.667h.006M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0"
			></path>
		</svg>
	);

	const Disputedata = [
		{ label: 'Merchant', value: email },
		{ label: 'Reference', value: reference, isCopyable: true },
		{ label: 'Note', value: note },
		{ label: 'Type', value: type },
		{
			label: 'Dispute Status',
			value: (
				<BadgeComponent
					className={
						status === 'Successful'
							? 'success'
							: status === 'Pending'
							? 'warning'
							: 'error'
					}
					text={
						status === 'Successful'
							? 'successful'
							: status === 'Pending'
							? 'Pending'
							: 'Failed'
					}
				/>
			),
		},
		{ label: 'Date Logged', value: date ? date.toLocaleDateString() : 'N/A' },
	];

	const fields = [
		{ label: 'Amount', value: amount },
		{
			label: 'Type',
			value: (
				<>
					{type}
					<button
						className="card-details-button"
						// onClick={() => console.log('Card Details Clicked')}
					>
						Card Details
						<span className="icon-arrow">{SIcon}</span>
					</button>
				</>
			),
		},
		{ label: 'Channel', value: Channel },
		{ label: 'Cashier', value: Cashier || '---' },
		{
			label: 'TID',
			value: TID ? (
				<>
					<span>{TID}</span>
					<Copy text={TID} />
				</>
			) : null,
		},
		{
			label: 'Serial No.',
			value: SerialNo ? (
				<>
					<span>{SerialNo}</span>
					<Copy text={SerialNo} />
				</>
			) : null,
		},
		{
			label: 'Stan',
			value: Stan ? (
				<>
					<span>{Stan}</span>
					<Copy text={Stan} />
				</>
			) : null,
		},
		{
			label: 'RRN',
			value: RRN ? (
				<>
					<span>{RRN}</span>
					<Copy text={RRN} />
				</>
			) : null,
		},
		{ label: 'Fee', value: Fee },
		{ label: 'PNL', value: PNL },
		{
			label: 'Status',
			value: (
				<BadgeComponent
					className={
						status === 'Successful'
							? 'success'
							: status === 'Pending'
							? 'warning'
							: 'error'
					}
					text={
						status === 'Successful'
							? 'successful'
							: status === 'Pending'
							? 'Pending'
							: 'Failed'
					}
				/>
			),
		},
		{ label: 'Date Logged', value: date ? date.toLocaleDateString() : 'N/A' },
	];

	const renderTabContent = () => {
		switch (activeTab) {
			case 'Dispute':
				return (
					<>
						{' '}
						<div className="settlement_modal_general_wrapper ">
							<div className="settlement_details_modal_contents_box">
								{Disputedata.map((item, index) => (
									<div
										key={index}
										className="settlement_details_modal_contents_content_holder settle"
									>
										<p className="settlement_details_modal_contents_content_head">
											{item.label}
										</p>
										<p
											className="settlement_details_modal_contents_content_details"
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: item.isCopyable ? '2rem' : undefined,
											}}
										>
											{item.isCopyable && item.value ? (
												<>
													<span>{item.value}</span>
													<Copy text={item.value} />
												</>
											) : (
												item.value
											)}
										</p>
									</div>
								))}
							</div>
						</div>
					</>
				);
			case 'Transaction':
				return (
					<>
						{' '}
						<div className="settlement_modal_general_wrapper">
							<div
								className="settlement_details_modal_contents_box"
								style={{
									maxHeight: '500px',
									overflowY: 'auto',
									paddingRight: '10px',
								}}
							>
								{fields.map((field, index) => (
									<div
										key={index}
										className="settlement_details_modal_contents_content_holder settle"
									>
										<p className="settlement_details_modal_contents_content_head">
											{field.label}
										</p>
										<p
											className="settlement_details_modal_contents_content_details"
											style={{
												display: 'flex',
												alignItems: 'center',
												gap:
													field.value && typeof field.value === 'object'
														? '2rem'
														: undefined,
											}}
										>
											{field.value}
										</p>
									</div>
								))}
							</div>
						</div>
					</>
				);
			case 'Respond':
				return (
					<div className="">
						<p className="group">
							<button
								className="card-details-button"
								// onClick={() => console.log('Card Details Clicked')}
							>
								fukuchi@verizon.net
							</button>
							<figure className="img-">{RIcon}</figure>
						</p>

						<div className="-custom">
							<RavenInputField
								color="green-light"
								label="Response Title *"
								className="my_custom"
								labelClassName=""
								labelColor="purple-light"
								onActionClick={function noRefCheck() {}}
								onChange={function noRefCheck() {}}
								onComplete={function noRefCheck() {}}
								onCountDownComplete={function noRefCheck() {}}
								onRemoveFile={function noRefCheck() {}}
								onSizeError={function noRefCheck() {}}
								onSubmit={function noRefCheck() {}}
								textareaColumn={30}
								textareaRow={2}
								type="textarea"
							/>
							<RavenInputField
								color="green-light"
								label="Response Message * "
								className="my_custom"
								labelClassName="my_custom"
								labelColor="purple-light"
								onActionClick={function noRefCheck() {}}
								onChange={function noRefCheck() {}}
								onComplete={function noRefCheck() {}}
								onCountDownComplete={function noRefCheck() {}}
								onRemoveFile={function noRefCheck() {}}
								onSizeError={function noRefCheck() {}}
								onSubmit={function noRefCheck() {}}
								textareaColumn={30}
								textareaRow={5}
								placeholder="Write your thoughts here... "
								type="textarea"
							/>
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<RavenModal
			btnColor={activeTab === 'Transaction' ? undefined : 'deep-green-light'}
			btnLabel={activeTab === 'Transaction' ? undefined : 'Pay Settlement'}
			effect="fadeInRight"
			visble={visible}
			onClose={() => {
				onClose && onClose();
			}}
			onBtnClick={() => {
				// console.log('Button inside modal clicked');
			}}
			className="mode"
		>
			<div className="settlement_modal_general_wrapper">
				{/* Tabs Navigation */}
				<div className="tabs-navigation">
					<button
						className={`tab-btn ${activeTab === 'Dispute' ? 'active' : ''}`}
						onClick={() => setActiveTab('Dispute')}
					>
						Dispute Details
					</button>
					<button
						className={`tab-btn ${activeTab === 'Transaction' ? 'active' : ''}`}
						onClick={() => setActiveTab('Transaction')}
					>
						Transaction
					</button>
					<button
						className={`tab-btn ${activeTab === 'Respond' ? 'active' : ''}`}
						onClick={() => setActiveTab('Respond')}
					>
						Give a Response
					</button>
				</div>

				{/* Tab Content */}
				<div className="">{renderTabContent()}</div>
			</div>
		</RavenModal>
	);
};

export default TabContent;
