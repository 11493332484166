import React from 'react';
import '../styles/singlecompliance.css';
import ImageFragment from '../../../../../../components/common/ImageFragment';

interface ComplianceCardProps {
	nin?: string;
	bvn?: string;
	fullname?: string;
	mobileNumbe?: string;
	address?: string;
	dateOfBirth?: string;
	DirectorEmail?: string;
	dateAuthorized?: string;
	imageUrl?: string;
	BAD?: string;
	state?: string;
	mobileNumber?: string;
	onRevoke: () => void;
	LocalGovernmentArea?: string;
	StateofResidence?: string;
	FullAddress?: string;
}

const ComplianceCard: React.FC<ComplianceCardProps> = ({
	nin,
	LocalGovernmentArea,
	StateofResidence,
	FullAddress,
	state,
	bvn,
	DirectorEmail,
	fullname,
	mobileNumber,
	address,
	BAD,
	dateOfBirth,
	dateAuthorized,
	imageUrl,
	onRevoke,
}) => {
	// Define userDetailsList with explicit types
	const userDetailsList: { label: string; value: string }[] = [
		{ label: 'Fullname', value: fullname || 'N/A' },
		{ label: 'Mobile Number', value: mobileNumber || 'N/A' },
		{ label: 'Address', value: address || 'N/A' },
		{ label: 'Date of Birth', value: dateOfBirth || 'N/A' },
		{ label: 'Date Authorized', value: dateAuthorized || 'N/A' },
		...(LocalGovernmentArea
			? [{ label: 'Local Government Area', value: LocalGovernmentArea }]
			: []),
		...(StateofResidence
			? [{ label: 'State of Residence', value: StateofResidence }]
			: []),
		...(FullAddress ? [{ label: 'Full Address', value: FullAddress }] : []),
		...(DirectorEmail
			? [{ label: 'Director Email', value: DirectorEmail }]
			: []),
	];

	// Filter out undefined values explicitly (just in case any are added)
	const filteredUserDetailsList = userDetailsList.filter(
		(item): item is { label: string; value: string } => item !== undefined
	);

	// Divide userDetailsList into four cards
	const card1 = userDetailsList.slice(0, 2);
	const card2 = userDetailsList.slice(2, 4);
	const card3 = filteredUserDetailsList.slice(
		4,
		Math.min(7, filteredUserDetailsList.length)
	);
	const card4 = userDetailsList.slice(7);

	return (
		<div className="nin-card border-theme">
			<div className="card-header">
				<h4 className="title">
					{nin
						? `NIN Details • ${nin}`
						: bvn
						? `BVN Details • ${bvn}`
						: BAD
						? `Business Address Details • ${BAD}`
						: `Details`}
				</h4>
				{nin && (
					<button className="revoke-button" onClick={onRevoke}>
						Revoke NIN Verification
					</button>
				)}
				{bvn && (
					<button className="revoke-button" onClick={onRevoke}>
						Revoke BVN Verification
					</button>
				)}
				{BAD && (
					<button className="revoke-button" onClick={onRevoke}>
						Revoke Address Verification
					</button>
				)}
			</div>
			<div className="card-body">
				<div className="image-section">
					<ImageFragment url={''}>
						<figure className="img-box">
							<img src={imageUrl} alt={`${fullname}'s profile`} />
						</figure>
					</ImageFragment>
				</div>
				<div className="_contents_box">
					{/* Render Card 1 */}
					<div className="card-content">
						{card1.map((detail, index) => (
							<div key={index} className="contents_content_holder">
								<p className="_contents_content_head">{detail.label}</p>
								<p className="_contents_content_details">
									{detail.value || 'N/A'}
								</p>
							</div>
						))}
					</div>

					{/* Render Card 2 */}
					<div className="card-content">
						{card2.map((detail, index) => (
							<div key={index} className="contents_content_holder">
								<p className="_contents_content_head">{detail.label}</p>
								<p className="_contents_content_details">
									{detail.value || 'N/A'}
								</p>
							</div>
						))}
					</div>

					{/* Render Card 3 */}
					<div className="card-content">
						{card3.map((detail, index) => (
							<div key={index} className="contents_content_holder">
								<p className="_contents_content_head">{detail.label}</p>
								<p className="_contents_content_details">
									{detail.value || 'N/A'}
								</p>
							</div>
						))}
					</div>

					{/* Render Card 4 */}
					<div className="card-content">
						{card4.map((detail, index) => (
							<div key={index} className="contents_content_holder">
								<p className="_contents_content_head">{detail.label}</p>
								<p className="_contents_content_details">
									{detail.value || 'N/A'}
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ComplianceCard;
