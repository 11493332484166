import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import { ElectricityData } from './eletricityData';
import TwoDetailView from '../../../../../components/common/TwoDetailView';
import {
	capitalizeFirstWord,
	convertToLowercase,
	formatMetaData,
	formatTypeFunction,
	identifyNetwork,
	trimLongString,
} from '../../../../../utils/helper/Helper';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import settlementIcon from '../../../../../assets/bank_box_icon_settlement.svg';

import './electricityIndex.css';
import BillsModal from '../billsModal';
import BillsLayout from '../billslayout';
import { debounce } from 'lodash';
import Pagination from '../../../../../components/common/pagination';
import SmartFilter from '../../../../../components/common/smartFilter';
import { getElectricityRecords } from '../../../../../redux/bills';
import ImageFragment from '../../../../../components/common/ImageFragment';
// import { getelectricityRecords } from '../../../../../redux/bills';
const ElectricityBill = () => {
	type showModalProp = {
		bills_modal: boolean;
	};
	const [currentPage, setCurrentPage] = useState(1);
	const [showModal, setShowModal] = useState<showModalProp>({
		bills_modal: false,
	});

	const { electricityRecords, loadingFetch } = useSelector(
		(state: RootState) => state.bills
	);

	const dispatch = useDispatch();

	useEffect(() => {
		fetchAllElectricity();
	}, [currentPage]);

	const fetchAllElectricity = async () => {
		const obj = {
			currentPage: currentPage,
			per_page: 2,
		};
		dispatch(getElectricityRecords(obj) as any);
	};

	const electricityIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			className="img"
		>
			<path
				stroke="black"
				style={{ stroke: 'black', strokeOpacity: 1 }}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M13 2 4.093 12.688c-.348.418-.523.628-.526.804a.5.5 0 0 0 .186.397c.138.111.41.111.955.111H12l-1 8 8.906-10.688c.35-.418.524-.628.526-.804a.5.5 0 0 0-.185-.397c-.138-.111-.41-.111-.955-.111H12z"
			></path>
		</svg>
	);

	const { loading } = useSelector((state: RootState) => state.bankbox);
	return (
		<>
			<BillsLayout
				noContentText={
					"You don't have any record yet, your bankbox electricity will be displayed here."
				}
				noContentTitle="No Electricity Record Found"
				noContent={electricityRecords?.data?.length < 1}
				pageLoading={loadingFetch}
			>
				{/* dispute content start */}
				<div
					style={{ marginBottom: '1.5rem' }}
					className="global-search-pagination-filter-box"
				>
					{' '}
					<SmartFilter
						// hideExport
						searchTitle="Search By Email Address"
						defaultFilterBy="status"
						// filters={[
						//  {
						//    label: 'Status',
						//    filterBy: 'status',
						//    options: [
						//      {
						//        label: 'Pending',
						//        value: 'pending',
						//      },
						//      {
						//        label: 'Failed',
						//        value: 'failed',
						//      },
						//      {
						//        label: 'Successful',
						//        value: 'succesful',
						//      },
						//    ],
						//  },
						// ]}
						onExport={() => {
							//  setShowModal((prev) => {
							//    return { ...prev, export: true };
							//  });
						}}
						page="bankbox_transactions"
						onSearchChange={debounce((e) => {
							// dispatch(
							//  bankboxAPI.getAllFees({
							//    per_page: 20,
							//    page: currentPage,
							//    search: e,
							//  })
							// );
						}, 500)}
					/>
					<div className="pagination-wrap">
						{' '}
						<Pagination
							className="top-bar__pagination"
							currentPage={currentPage}
							itemsPerPage={electricityRecords?.pagination?.perPage}
							totalItems={electricityRecords?.pagination?.total}
							onPageChange={function (page: number): void {
								setCurrentPage(page);
							}}
						/>
					</div>
				</div>
				{/* dispute content end */}
				<div>
					{/* table content start */}
					<div className="transaction__table non-mobile">
						<RavenTable
							action={false}
							className="table__main"
							headerList={[
								'MERCHANT DETAILS',
								'PROVIDER',
								'AMOUNT',
								'METER NUMBER',
								'DATE',
								'STATUS',
							]}
						>
							{electricityRecords?.data?.map((chi: any, idx: any) => (
								<RavenTableRow
									loading={loadingFetch}
									onRowClick={() => {
										setShowModal({ bills_modal: true });
									}}
									one={
										<TwoDetailView
											title={
												capitalizeFirstWord(
													formatTypeFunction(chi?.merchant_name) as any
												) || '----'
											}
											text_one={trimLongString(chi.email, 20)}
										/>
									}
									two={
										<div className="provider_image_and_name">
											<ImageFragment
												className={`img-val-box  img-box border-theme`}
												url={`https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
													chi?.provider
												)}.png`}
											>
												{' '}
												<figure className="img-box border-theme img-box-default">
													{electricityIcon}
												</figure>
											</ImageFragment>

											<p className="name">
												{trimLongString(chi?.provider, 20)}
											</p>
										</div>
									}
									three={RavenNumberFormat(chi?.amount || 0)}
									four={chi?.meter_number}
									five={<DateFragmentBox date={chi?.created_at} />}
									six={
										<div className="status_and_modal_holder">
											<BadgeComponent
												text={chi?.status === '1' ? 'Disabled' : 'Success'}
												className={chi?.status === '1' ? 'failed' : 'success'}
											/>
										</div>
									}
								/>
							))}
						</RavenTable>
					</div>
					{/* table content end */}
				</div>
			</BillsLayout>
			<BillsModal
				visible={showModal.bills_modal}
				title="Transaction Details"
				onClose={() => setShowModal({ bills_modal: false })}
				amount={5000}
				businessEmail="aby1@buz.com"
				type="Top-up"
				fee={100}
				mobileNumber="080-222-333-11"
				provider="glo"
				references="REF-12345"
				merchantRef="MER-12345"
				status="sucessful"
				date="2024-08-18T13:45:00"
				plan="Basic"
				btnLabel="Download Reciept"
				btnColor="purple-light"
			/>
		</>
	);
};
export default ElectricityBill;
