import React from 'react';
import ComplianceCard from './ComplianceCard';
import { cardData } from './ComplianceCardData'; // Ensure this is correctly imported
import SingleUserLayout from '../SingleUserLayout';

const ComplianceCardList = () => {
	return (
		<div className="nin-card-list">
			{cardData.map((card) => (
				<ComplianceCard
					BAD={card.BAD}
					key={card.nin || card.bvn || card.BAD}
					DirectorEmail={card.DirectorEmail}
					nin={card.nin}
					bvn={card.bvn}
					fullname={card.fullname}
					mobileNumber={card.mobileNumber}
					address={card.address}
					dateOfBirth={card.dateOfBirth}
					dateAuthorized={card.dateAuthorized}
					imageUrl={card.imageUrl}
					onRevoke={() => {
						// console.log('Revoke action triggered');
					}}
				/>
			))}
		</div>
	);
};

export default ComplianceCardList;
