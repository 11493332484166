import { RavenModal } from '@ravenpay/raven-bank-ui';
import React from 'react';
import ImageFragment from '../../../../../../components/common/ImageFragment';
import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
import livecheckmodal from '../../../../../../assets/images/livecheckmodal.png';
import '../../style/ComplianceLivelinessCheck.css';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
	singleChild?: any;
	// style?: React.CSSProperties;
}

const LivenessModal = ({ onClose, visible, singleChild }: MyComponentProps) => {
	return (
		<>
			<div className="liveness-modal-container">
				<RavenModal
					visble={visible}
					onClose={
						() => {
							onClose && onClose();
						}
						// setShowModal((prev) => ({ ...prev, view_liveliness: false }))
					}
					onBtnClick={() => {
						// console.log('Button inside modal clicked');
					}}
					className="liveness-modal-container"
				>
					<div className="liveliness-check-modal ">
						<div className="modal-content">
							<div className="image-container">
								<ImageFragment
									url={livecheckmodal}
									className="profile-avatar"
									styles={{
										width: '100%',
										height: '50%',
									}}
								/>
							</div>
							<div className="details-container">
								<h2>Liveliness Check</h2>
								<table>
									<tbody>
										<tr>
											<td>
												<p>Merchant Name:</p>
											</td>
											<td>{singleChild.name}</td>
										</tr>
										<tr>
											<td>
												<p>BVN:</p>
											</td>
											<td>{singleChild.Bvn}</td>
										</tr>
										<tr>
											<td>
												<p>NIN:</p>
											</td>
											<td>{singleChild.Nin}</td>
										</tr>
										<tr>
											<td>
												<p>Email:</p>
											</td>
											<td>{singleChild.email}</td>
										</tr>
										<tr>
											<td>
												<p>Status:</p>
											</td>
											<td>
												<BadgeComponent
													className={
														singleChild.Status === 1 ? 'success' : 'warning'
													}
													text={
														singleChild.Status === 1 ? 'Verified' : 'Pending'
													}
												/>
											</td>
										</tr>
										<tr>
											<td>
												<p>Date Authorized:</p>
											</td>
											<td>
												{new Date(singleChild.created_at).toLocaleDateString()}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</RavenModal>
			</div>
		</>
	);
};

export default LivenessModal;
