import React from 'react'
import SingleUserLayout from '../../SingleUserLayout'
import SmartFilter from '../../../../../../../components/common/smartFilter'
import { debounce } from 'lodash'
import Pagination from '../../../../../../../components/common/pagination'
import { RavenNumberFormat, RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui'
import TwoDetailView from '../../../../../../../components/common/TwoDetailView'
import { capitalizeFirstWord, convertToLowercase, formatMetaData, identifyNetwork, trimLongString } from '../../../../../../../utils/helper/Helper'
import ImageFragment from '../../../../../../../components/common/ImageFragment'
import BadgeComponent from '../../../../../../../components/common/badge/BadgeComponent'
import DateFragmentBox from '../../../../../../../components/common/DateFragmentBox'

const LinkTable = () => {
  const dummyData = [
    {
      linkTitle: "Grocery Store",
      type: "Single Charge",
      paymentLink: "https://payment.example.com/12345",
      currency: "USD",
      dateCreated: "2025-01-15",
      status: "Active",
    },
    {
      linkTitle: "Utility Bill",
      type: "Multi Charge",
      paymentLink: "https://payment.example.com/67890",
      currency: "EUR",
      dateCreated: "2025-01-14",
      status: "Pending",
    },
    {
      linkTitle: "Subscription Service",
      type: "Multi Charge",
      paymentLink: "https://payment.example.com/111213",
      currency: "GBP",
      dateCreated: "2025-01-13",
      status: "Expired",
    },
    {
      linkTitle: "Groceries Online",
      type: "Single Charge",
      paymentLink: "https://payment.example.com/141516",
      currency: "USD",
      dateCreated: "2025-01-12",
      status: "Completed",
    },
    {
      linkTitle: "Gym Membership",
      type: "Multi Charge",
      paymentLink: "https://payment.example.com/171819",
      currency: "CAD",
      dateCreated: "2025-01-11",
      status: "Active",
    },
    {
      linkTitle: "Fuel Station",
      type: "Single Charge",
      paymentLink: "https://payment.example.com/202122",
      currency: "AUD",
      dateCreated: "2025-01-10",
      status: "Pending",
    },
    {
      linkTitle: "Online Learning",
      type: "Single Charge",
      paymentLink: "https://payment.example.com/232425",
      currency: "USD",
      dateCreated: "2025-01-09",
      status: "Completed",
    },
    {
      linkTitle: "Mobile Recharge",
      type: "Multi Charge",
      paymentLink: "https://payment.example.com/262728",
      currency: "INR",
      dateCreated: "2025-01-08",
      status: "Active",
    },
    {
      linkTitle: "Insurance Payment",
      type: "Multi Charge",
      paymentLink: "https://payment.example.com/293031",
      currency: "SGD",
      dateCreated: "2025-01-07",
      status: "Expired",
    },
    {
      linkTitle: "Event Booking",
      type: "Single Charge",
      paymentLink: "https://payment.example.com/323334",
      currency: "EUR",
      dateCreated: "2025-01-06",
      status: "Completed",
    },
  ];
  
  
  
  return (
    <div>
        
        <div
          style={{ marginBottom: '1.5rem' }}
          className="global-search-pagination-filter-box"
        >
          <SmartFilter
            searchTitle="Search Terminals..."
            defaultFilterBy="status"
            onSearchChange={debounce((e) => {}, 500)}
          />
          <div className="pagination-wrap">
            <Pagination
              className="top-bar__pagination"
              currentPage={0}
              itemsPerPage={0}
              totalItems={0}
              onPageChange={() => {}}
            />
          </div>
        </div>
        <div>
					{/* table content start */}
					<div className="transaction__table non-mobile">
						<RavenTable
							action={false}
							className="table__main"
							headerList={[
								'LINK TITTLE',
								'LINK',
								'CURRENCY',
								'TYPE',
								'DATE CREATED',
								'STATUS',
							]}
						>
							{dummyData.map((chi: any, idx: any) => (
								<RavenTableRow
								// loading={loadingFetch}
									// onRowClick={() => {
									// 	setShowModal({ bills_modal: true });
									// }}
                  
									one={
										capitalizeFirstWord(chi?.linkTitle || '---') ||
												'----'
									}
									two={trimLongString(chi.paymentLink, 20) || '---'}
									three={
								chi.currency
									}
									four={chi.type}
									
									six={
										<div className="status_and_modal_holder">
											<BadgeComponent
												text={(() => {
													if (String(chi?.status === '0')) {
														return 'Disabled';
													} else {
														return 'Success';
													}
												})()}
												className={(() => {
													if (String(chi?.status === '1')) {
														return 'failed';
													} else {
														return 'success';
													}
												})()}
											/>
										</div>
									}
									five={<DateFragmentBox date={chi?.dateCreated} />}
                  // seven={
                  //   <div
                  //     style={{ position: 'relative' }}
                  //     onClick={(e) => {
                  //       e.stopPropagation();
                  //       setShowDrop((prev) => ({
                  //         ...prev,
                  //         id: idx,
                  //         show: showDrop?.id === idx ? !showDrop.show : true,
                  //       }));
                  //     }}
                  //   >
                  //     <DropMore
                  //       idx={idx}
                  //       className={isDropped(idx) ? 'show-drop' : ''}
                  //       chi={chi}
                  //     />
                  //     {icons.three_dots}
                  //   </div>
                  // }
								/>
							))}
						</RavenTable>
					</div>
					{/* table content end */}
				</div>
     
    
    </div>
  )
}

export default LinkTable