import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import { merchantDetails } from './airtimeData';
import TwoDetailView from '../../../../../components/common/TwoDetailView';
import {
	capitalizeFirstWord,
	convertToLowercase,
	formatMetaData,
	identifyNetwork,
	trimLongString,
	// formatTypeFunction,
} from '../../../../../utils/helper/Helper';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import settlementIcon from '../../../../../assets/bank_box_icon_settlement.svg';

import './airtimeIndex.css';

import BillsLayout from '../billslayout';
import AirtimeBillsModal from './AirtimeBillsModal';
import { getAirtimeRecords } from '../../../../../redux/bills';
import Pagination from '../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../components/common/smartFilter';
import TabLinksComponent from '../tabLinksComponent';
import ImageFragment from '../../../../../components/common/ImageFragment';
// import Txreference from '../../../../../components/common/reference/TxRef';

const AirtimeBill = () => {
	const [currentPage, setCurrentPage] = useState(1);
	type showModalProp = {
		bills_modal: boolean;
		redu_modal: boolean;
	};
	const dispatch = useDispatch();

	const [showModal, setShowModal] = useState<showModalProp>({
		bills_modal: false,
		redu_modal: false,
	});
	const [singleChild, setSingleChild] = useState<any>({});

	

	const { airtimeRecords, loadingFetch } = useSelector(
		(state: RootState) => state.bills
	);

	useEffect(() => {
		fetchAllAirtime();
	}, [currentPage]);

	const fetchAllAirtime = async () => {
		const obj = {
			currentPage: currentPage,
			per_page: 2,
		};
		dispatch(getAirtimeRecords(obj) as any);
	};

	return (
		<>
			<BillsLayout
				noContentText={
					"You don't have any record yet,your  bankbox airtime will be displayed here."
				}
				noContentTitle="No Airtime Record Found"
				noContent={airtimeRecords?.data?.length < 1}
				pageLoading={loadingFetch}
			>
				{/* dispute content start */}

				<div
					style={{ marginBottom: '1.5rem' }}
					className="global-search-pagination-filter-box"
				>
					{' '}
					<SmartFilter
						// hideExport
						searchTitle="Search By Email Address"
						defaultFilterBy="status"
						// filters={[
						//  {
						//    label: 'Status',
						//    filterBy: 'status',
						//    options: [
						//      {
						//        label: 'Pending',
						//        value: 'pending',
						//      },
						//      {
						//        label: 'Failed',
						//        value: 'failed',
						//      },
						//      {
						//        label: 'Successful',
						//        value: 'succesful',
						//      },
						//    ],
						//  },
						// ]}
						onExport={() => {
							//  setShowModal((prev) => {
							//    return { ...prev, export: true };
							//  });
						}}
						page="bankbox_transactions"
						onSearchChange={debounce((e) => {
							// dispatch(
							//  bankboxAPI.getAllFees({
							//    per_page: 20,
							//    page: currentPage,
							//    search: e,
							//  })
							// );
						}, 500)}
					/>
					<div className="pagination-wrap">
						{' '}
						<Pagination
							className="top-bar__pagination"
							currentPage={currentPage}
							itemsPerPage={airtimeRecords?.pagination?.perPage}
							totalItems={airtimeRecords?.pagination?.total}
							onPageChange={function (page: number): void {
								setCurrentPage(page);
							}}
						/>
					</div>
				</div>
				{/* dispute content end */}
				<div>
					{/* table content start */}
					<div
						// onClick={() => {
						// 	console.log(airtimeRecords);
						// }}
						className="transaction__table non-mobile"
					>
						<RavenTable
							action={false}
							className="table__main"
							headerList={[
								'MERCHANT DETAILS',
								'REFERENCE',
								'PROVIDER',
								'AMOUNT',
								'MOBILE NUMBER',
								'DATE',
								'STATUS',
							]}
						>
							{airtimeRecords?.data?.map((chi: any, idx: any) => (
								<RavenTableRow
									loading={loadingFetch}
									onRowClick={() => {
										setSingleChild(chi);
										setShowModal((prev) => {
											return { ...prev, bills_modal: true };
										});
									}}
									one={
										<TwoDetailView
											title={
												capitalizeFirstWord(chi?.merchant_name || '---') ||
												'----'
											}
											text_one={chi.email || '---'}
										/>
									}
									two={trimLongString(chi.reference, 20) || '---'}
									three={
										<div className="provider_image_and_name">
											<ImageFragment
												className={`img-val-box  img-box border-theme`}
												url={`https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
													identifyNetwork(
														formatMetaData(chi?.meta_data)?.phone_number
													)
												)}.png`}
											>
												{' '}
												<figure className="img-box border-theme">
													{' '}
													<img
														className="img"
														src={settlementIcon}
														alt="image"
													/>
												</figure>
											</ImageFragment>

											<p className="name">
												{identifyNetwork(
													formatMetaData(chi?.meta_data)?.phone_number
												)}
											</p>
										</div>
									}
									four={RavenNumberFormat(chi?.amount || 0)}
									five={formatMetaData(chi?.meta_data)?.phone_number || '---'}
									seven={
										<div className="status_and_modal_holder">
											<BadgeComponent
												text={(() => {
													if (String(chi?.status === '0')) {
														return 'Disabled';
													} else {
														return 'Success';
													}
												})()}
												className={(() => {
													if (String(chi?.status === '1')) {
														return 'failed';
													} else {
														return 'success';
													}
												})()}
											/>
										</div>
									}
									six={<DateFragmentBox date={chi?.created_at} />}
								/>
							))}
						</RavenTable>
					</div>
					{/* table content end */}
				</div>
			</BillsLayout>
			<AirtimeBillsModal
				visible={showModal.bills_modal}
				onClose={() =>
					setShowModal((prev) => {
						return { ...prev, bills_modal: false };
					})
				}
				//  singleChild={singleChild}
				singleChildren={singleChild}
				title={'Transaction Details'}
				amount={5000}
				businessEmail="aby1@buz.com"
				type="Top-up"
				fee={100}
				mobileNumber="080-222-333-11"
				provider="glo"
				references="REF-12345"
				merchantRef="MER-12345"
				status="0"
				date="2024-08-18T13:45:00"
				plan="Basic"
				btnLabel="Download Reciept"
				btnColor="green-light"
			/>
		</>
	);
};
export default AirtimeBill;
