import React, { useState } from 'react';
import SingleUserLayout from '../SingleUserLayout';
import Pagination from '../../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../../components/common/smartFilter';
import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../components/common/DateFragmentBox';
import {
	capitalizeFirstWord,
	formatTypeFunction,
} from '../../../../../../utils/helper/Helper';
import { RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import TwoDetailView from '../../../../../../components/common/TwoDetailView';
import { icons } from '../../../../../../assets/icons';
import { FaRegEye } from 'react-icons/fa';
import SettoApproved from './modal/SettoApproved';
import Settodispatch from './modal/Settodispatch';
import DeviceRequestModal from './modal/ViewDetails';

const DeviceRequest = () => {
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});


	type showModalProp = {
		create: boolean;
		export: boolean;
		Set_to_In_Approved: boolean;
		Set_to_Dispatched: boolean;
		View_Details: boolean;
	};
	const [singleChild, setSingleChild] = useState<any>({});
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		Set_to_In_Approved: false,
		Set_to_Dispatched: false,
		View_Details: false,
	});

	function isDropped(idx: number | string) {
		return showDrop.id === idx && showDrop.show;
	}

	const Dispatchedicon = (
		<svg
			className="img"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="18"
			fill="none"
			viewBox="0 0 22 18"
		>
			<path
				stroke="#020202"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="M13 4h2.337c.245 0 .367 0 .482.028a1 1 0 0 1 .29.12c.1.061.187.148.36.32l4.062 4.063c.173.173.26.26.322.36q.082.136.12.29c.027.115.027.237.027.482V12.5c0 .466 0 .699-.076.883a1 1 0 0 1-.541.54c-.184.077-.417.077-.883.077m-5 0H13m0 0V4.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C11.48 1 10.92 1 9.8 1H4.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C1 2.52 1 3.08 1 4.2V12a2 2 0 0 0 2 2m10 0H9m0 0a3 3 0 1 1-6 0m6 0a3 3 0 1 0-6 0m16.5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"
			></path>
		</svg>
	);

	const approvedicon = (
		<svg
			className="img"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				stroke="#1ACE37"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="m6.5 11 3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10"
			></path>
		</svg>
	);

	const ViewDetailsicon = (
		<svg
			className="img"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				stroke="#333"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="m11 15 4-4m0 0-4-4m4 4H7m14 0c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10"
			></path>
		</svg>
	);

	// Dummy data for all_fees
	const user_transaction = {
		data: [
			{
				businessname: 'godswill',
				amount: '₦25,000',
				name: '25 Pieces',
				moblie_number: '070 1526 3711',
				BVN: 12345678903,
				rate: 'at ₦10,000 for 1',
				stan: ' 12345678903',
				Status: 0,
				created_at: '2024-11-01T00:00:00',
			},
			{
				amount: '₦25,000',
				name: '25 Pieces',
				moblie_number: '070 1526 3711',
				BVN: 12345678903,
				rate: 'at ₦10,000 for 1',
				stan: ' 12345678903',
				Status: 0,
				created_at: '2024-11-10T00:00:00',
			},
			{
				amount: '₦25,000',
				name: '25 Pieces',
				rate: 'at ₦10,000 for 1',
				moblie_number: '070 1526 3711',
				BVN: 12345678903,
				stan: ' 12345678903',
				Status: 0,
				created_at: '2024-11-15T00:00:00',
			},
			{
				amount: '₦25,000',
				name: '25 Pieces',
				moblie_number: '070 1526 3711',
				BVN: 12345678903,
				stan: ' 12345678903',
				rate: 'at ₦10,000 for 1',
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
			{
				amount: '₦25,000',
				name: '25 Pieces',
				moblie_number: '070 1526 3711',
				BVN: 12345678903,
				stan: ' 12345678903',
				rate: 'at ₦10,000 for 1',
				Status: 0,
				created_at: '2024-11-15T00:00:00',
			},
			{
				amount: '₦25,000',
				name: '25 Pieces',
				rate: 'at ₦10,000 for 1',
				moblie_number: '070 1526 3711',
				stan: ' 12345678903',
				BVN: 12345678903,
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
		],
	};

	// Function to mask mobile numbers
	const maskMobileNumber = (number: string | null): string =>
		number ? `${number.slice(0, 7)}***${number.slice(-2)}` : '----';

	const DropMore = ({ className, idx, chi }: any) => {
		return (
			<div className={`more-wrap ${className}`}>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							Set_to_In_Approved: true,
						}));
					}}
					className="more_item"
				>
					<figure className="img-box">{approvedicon}</figure>
					<p>Set to In Approved</p>
				</div>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							Set_to_Dispatched: true,
						}));
					}}
					className="more_item"
				>
					<figure className="img-box">{Dispatchedicon}</figure>
					<p>Set to Dispatched</p>
				</div>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							View_Details: true,
						}));
					}}
					className="more_item"
				>
					<figure className="img-box">{ViewDetailsicon}</figure>
					<p>View Details</p>
				</div>
			</div>
		);
	};

	return (
		<>
			{/* dispute content start */}
			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					// filters={[
					// 	{
					// 		label: 'Status',
					// 		filterBy: 'status',
					// 		options: [
					// 			{
					// 				label: 'Pending',
					// 				value: 'pending',
					// 			},
					// 			{
					// 				label: 'Failed',
					// 				value: 'failed',
					// 			},
					// 			{
					// 				label: 'Successful',
					// 				value: 'succesful',
					// 			},
					// 		],
					// 	},
					// ]}
					onExport={() => {
						// 	setShowModal((prev) => {
						// 		return { ...prev, export: true };
						// 	});
					}}
					page="bankbox_transactions"
					onSearchChange={debounce((e) => {
						// dispatch(
						// 	bankboxAPI.getAllFees({
						// 		per_page: 20,
						// 		page: currentPage,
						// 		search: e,
						// 	})
						// );
					}, 500)}
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={0}
						itemsPerPage={0}
						totalItems={0}
						onPageChange={() => {}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			<div className="transaction__table non-mobile">
				<RavenTable
					action={false}
					className="table__main"
					headerList={['QUANTITY', 'AMOUNT', 'DATE ', 'STATUS', 'ACTION']}
				>
					{user_transaction?.data?.map((chi: any, idx: any) => {
						const {
							name,
							moblie_number,
							businessname,
							amount,
							BVN,
							stan,
							Status,
							rate,
						} = chi || {};
						return (
							<RavenTableRow
								key={idx}
								one={
									capitalizeFirstWord(formatTypeFunction(name) as any) || '----'
								}
								two={
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: '5px',
										}}
									>
										{/* Display Amount */}
										<div>{amount || 'Amount not available'}</div>

										{/* Display Rate or fallback */}
										<div
											style={{
												fontWeight: '400',
												color: 'rgba(103, 103, 103, 1)',
											}}
										>
											{rate ? `at ${rate}` : 'Rate not available'}
										</div>
									</div>
								}
								three={<DateFragmentBox date={chi?.created_at} />}
								four={
									<>
										{/* Render badges based on the Status */}
										<BadgeComponent
											className={Status === 1 ? 'success' : 'warning'}
											text={Status === 1 ? 'verified' : 'pending'}
										/>
									</>
								}
								five={
									<div
										style={{ position: 'relative' }}
										onClick={(e) => {
											e.stopPropagation();
											setShowDrop((prev) => ({
												...prev,
												id: idx,
												show: showDrop?.id === idx ? !showDrop.show : true,
											}));
										}}
									>
										<DropMore
											idx={idx}
											className={isDropped(idx) ? 'show-drop' : ''}
											chi={chi}
										/>
										{icons.three_dots}
									</div>
								}
							/>
						);
					})}
				</RavenTable>
			</div>

			{/* RavenModal for View Liveliness */}
			<SettoApproved
				onClose={() => {
					setShowModal((prev) => ({ ...prev, Set_to_In_Approved: false }));
				}}
				visible={showModal?.Set_to_In_Approved}
				singleChild={singleChild}
			/>
			<Settodispatch
				onClose={() => {
					setShowModal((prev) => ({ ...prev, Set_to_Dispatched: false }));
				}}
				visible={showModal?.Set_to_Dispatched}
				singleChild={singleChild}
			/>
			<DeviceRequestModal
				onClose={() => {
					setShowModal((prev) => ({ ...prev, View_Details: false })); // Close modal
				}}
				visible={showModal?.View_Details} // Bind to visibility state
				singleChild={singleChild}
			/>
			{/* Table content end */}
		</>
	);
};

export default DeviceRequest;
