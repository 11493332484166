import complianceImage from '../../../../../../assets/images/complianceimage.png';

const images = [complianceImage];

export type CardData = {
	nin?: string;
	bvn?: string;
	BAD?: string;
	fullname?: string;
	mobileNumber?: string;
	address?: string;
	dateOfBirth?: string;
	dateAuthorized?: string;
	imageUrl?: string;
	DirectorEmail?: string;
	state?: string;
	StateofResidence?: string;
	LocalGovernmentArea?: string;
	FullAddress?: string;
};

export const cardData: CardData[] = [
	{
		state: 'hello',
		DirectorEmail: '',
		nin: '5867184746453',
		fullname: 'ADEEKO EMMANUEL IYANUOLUWA',
		mobileNumber: '0701 526 3711',
		address: 'Table Body Content',
		dateOfBirth: 'Tuesday, January 21, 2003',
		dateAuthorized: 'December 12, 2022',
		imageUrl: complianceImage,
	},
	{
		state: '',
		DirectorEmail: '',
		bvn: '1234567890123',
		fullname: 'JOHN DOE',
		mobileNumber: '0812 345 6789',
		address: '123 Main Street',
		dateOfBirth: 'Monday, May 15, 1990',
		dateAuthorized: 'January 1, 2023',
		imageUrl: complianceImage,
	},
	{
		LocalGovernmentArea: 'Shomolu Local Goverment Area (LGA)',
		StateofResidence: 'Lagos State',
		FullAddress: 'Full Address',
		BAD: 'Buisiness Address Details',
		dateAuthorized: 'February 20, 2023',
		imageUrl: complianceImage,
	},
	{
		state: '',
		bvn: '1122334455667',
		fullname: 'MICHAEL JOHNSON',
		mobileNumber: '0901 567 8901',
		address: '789 Oak Avenue',
		dateOfBirth: 'Wednesday, August 25, 1978',
		dateAuthorized: 'March 15, 2023',
		imageUrl: complianceImage,
		DirectorEmail: 'emmanuel.adeyemi@gmail.com',
	},
];
