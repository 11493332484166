import { RavenModal } from '@ravenpay/raven-bank-ui';
import React from 'react';
import ImageFragment from '../../../../../../components/common/ImageFragment';
import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
import livecheckmodal from '../../../../../../assets/images/livecheckmodal.png';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
	singleChild?: any;
}

const EditLivenessModal = ({
	onClose,
	visible,
	singleChild,
}: MyComponentProps) => {
	return (
		<>
			<RavenModal
				visble={visible}
				onClose={
					() => {
						onClose && onClose();
					}
					// setShowModal((prev) => ({ ...prev, view_liveliness: false }))
				}
				onBtnClick={() => {
					// console.log('Button inside modal clicked');
				}}
				className="liveliness-modal"
			>
				<div className="liveliness-check-modal">
					<div className="modal-content">
						<div className="image-container">
							<ImageFragment
								url={livecheckmodal}
								className="profile-avatar"
								styles={{
									width: '100%',
									height: '100%',
								}}
							/>
						</div>
						<div className="details-container">
							<h2> Edit Liveliness Check</h2>
							<table>
								<tbody>
									<tr>
										<td>
											<strong>Merchant Name:</strong>
										</td>
										<td>{singleChild.name}</td>
									</tr>
									<tr>
										<td>
											<strong>BVN:</strong>
										</td>
										<td>{singleChild.Bvn}</td>
									</tr>
									<tr>
										<td>
											<strong>NIN:</strong>
										</td>
										<td>{singleChild.Nin}</td>
									</tr>
									<tr>
										<td>
											<strong>Email:</strong>
										</td>
										<td>{singleChild.Email}</td>
									</tr>
									<tr>
										<td>
											<strong>Status:</strong>
										</td>
										<td>
											<BadgeComponent
												className={
													singleChild.Status === 1 ? 'success' : 'warning'
												}
												text={singleChild.Status === 1 ? 'Verified' : 'Pending'}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>Date Authorized:</strong>
										</td>
										<td>
											{new Date(singleChild.created_at).toLocaleDateString()}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</RavenModal>
		</>
	);
};

export default EditLivenessModal;
