import React from 'react';
import './styles/linkDetailsInfo.css';
import { icons, illustrations } from '../../../../../../../assets/icons';
import CurrencySelector from '../../../../../../../components/common/currencySelector';
import QRCode from './QR Code.png';
import {
	formatNumWithCommaNairaSymbolTwo,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';
import ImageFragment from '../../../../../../../components/common/ImageFragment';
import { RavenToolTip } from '@ravenpay/raven-bank-ui';

interface LinkDetails {
	// logo: string;

	title?: string;
	subtitle?: string;
	linkType?: string;
	status?: string;
	currencyAbrev?: string; // Currency abbreviation
	amountReceived?: number; // Amount received
	amountRequested?: number; // Amount requested
	dateCreated?: Date; // Date when this record was created
	noOfPayments?: number; // Number of payments made or required

	link?: string; // Payment details link
}

interface BusinessInfoCardProps {
	linkDetails: LinkDetails;
}

const messageIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className="img"
	>
		<path
			stroke="#ACACAC"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M6.25 2.5h5.917c1.867 0 2.8 0 3.513.363.627.32 1.137.83 1.457 1.457.363.713.363 1.646.363 3.513v5.917M5.167 17.5h6.75c.933 0 1.4 0 1.756-.182.314-.16.569-.414.729-.728.181-.357.181-.823.181-1.757v-6.75c0-.933 0-1.4-.181-1.756a1.67 1.67 0 0 0-.729-.729c-.356-.181-.823-.181-1.756-.181h-6.75c-.934 0-1.4 0-1.757.181-.314.16-.569.415-.728.729-.182.356-.182.823-.182 1.756v6.75c0 .934 0 1.4.182 1.757.16.314.414.569.728.728.357.182.823.182 1.757.182"
		></path>
	</svg>
);

const disableLinkIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
	>
		<g clipPath="url(#clip0_2748_80114)">
			<path
				stroke="url(#paint0_linear_2748_80114)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M4.108 4.108 15.89 15.892M18.333 10a8.333 8.333 0 1 1-16.667 0 8.333 8.333 0 0 1 16.667 0"
			></path>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_2748_80114"
				x1="9.999"
				x2="9.999"
				y1="1.667"
				y2="18.333"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFA554"></stop>
				<stop offset="1" stopColor="#E66F04"></stop>
			</linearGradient>
			<clipPath id="clip0_2748_80114">
				<path fill="#fff" d="M0 0h20v20H0z"></path>
			</clipPath>
		</defs>
	</svg>
);

const LinkDetailsInfo: React.FC<BusinessInfoCardProps> = ({ linkDetails }) => {
	const linkdetailsList = [
		{
			label: 'Link Type',
			value: linkDetails.linkType,
		},
		{
			label: 'Amount Requested',
			valuue: linkDetails.amountRequested,
		},
		{
			label: 'Amount Received',
			value: linkDetails.amountReceived,
		},
		{
			label: 'No Of Payments',
			value: linkDetails.noOfPayments,
		},
		{
			label: 'Link Status',
			value: linkDetails.status,
		},
		{
			label: 'Currency',
			value: linkDetails.currencyAbrev,
		},
		{
			label: 'Date Created',
			value: linkDetails.dateCreated,
		},
	];
	const MAX_LENGTH = 27;
	return (
		<div className="link-card_container_wrap">
			{/* Top Section */}
			<div className="the_link_item_box border-theme-bottom ">
			<div className="qr_code_holder">
					<ImageFragment url={``}>
						<figure className="img-box">
							<img src={QRCode} alt="Business Logo" className="img" />
						</figure>
					</ImageFragment>
				</div>


					<div className="link_card_details">
						<div className="general_top_content_wrap_holder">
							<p className="general_link_title">{linkDetails.title}</p>
							<p className="general_link_subtitle">{linkDetails.subtitle}</p>
						</div>

						<div className='general_bottom_content_wrap_holder border-theme-bottom border-theme-top'>

						{linkdetailsList.map((chi, idx) => {
							return (
								<div key={idx} className="label_value_box">
									<p className="name_label">{chi?.label}</p>
									<div
										className={`value? ${
											String(chi?.value)?.length >= MAX_LENGTH
												? 'tooltip-hover-wrap'
												: ''
										}`}
									>
										{trimLongString(
											typeof chi?.value === 'string'
												? chi.value
												: String(chi?.value),
											MAX_LENGTH
										)}
										{/* tooltip start here */}
										{String(chi?.value)?.length >= MAX_LENGTH && (
											<>
												<RavenToolTip
													position={idx < 5 ? `bottom-right` : 'top-center'}
													color="deep-green-light"
													text={String(chi?.value)}
												/>
											</>
										)}
										{/* tooltip end here */}
									</div>
								</div>
							);
						})}



{/*             
              <div className='content_wrap_d'>
							<p className="content_header_name">Link Status</p>
              <p className="content_body">{linkDetails.status}</p>
              </div>
              
							<div className='content_wrap_d'>
              <p className="content_header_name">Currency</p>
              <p className="content_body">{linkDetails.currencyAbrev}</p>
           
              </div>
              <div className='content_wrap_d'>
              <p className="content_header_name">Date Created</p>
              <p className="content_body">{ linkDetails.amountReceived}</p>
              </div> */}

            </div>

						<div className="link_container_and_disable_box">
							<div className="link_and_message_bod">
								<p>{linkDetails.link}</p>
								<p>{trimLongString(linkDetails.link,45)}</p>
								<div>
									<figure>{messageIcon}</figure>
								</div>
							</div>
							<div className="disable_and_icon_box">
								<div>
									<figure>{disableLinkIcon}</figure>
								</div>
								<p>Disable Link</p>
							</div>
						</div>
					</div>
					{/* Balance Section */}
				</div>
			</div>

	);
};

export default LinkDetailsInfo;