import React from 'react'
import SingleUserLayout from '../../SingleUserLayout';
import SmartFilter from '../../../../../../../components/common/smartFilter';
import { debounce } from 'lodash';
import Pagination from '../../../../../../../components/common/pagination';
import PaymentLinksComponent from './paymentLinksComponentIndex';

export const PaymentLink = () => {
  const dummyData = [
    {
      name: "Spotify Premium for the boysssssss",
      subscriptionName: "Monthly subscription payment for the boys",
      paymentLink: "https://example.com/payment/johndoe",
      status: "Active",
      charges: "Single Charge",
    },
    {
      name: "Jane Smith",
      subscriptionName: "Basic Plan",
      paymentLink: "https://example.com/payment/janesmith",
      status: "Pending",
      charges: "Single Charge",
    },
    {
      name: "Michael Brown",
      subscriptionName: "Pro Plan",
      paymentLink: "https://example.com/payment/michaelbrown",
      status: "Active",
      charges: "Single Charge",
    },
    {
      name: "Emily Johnson",
      subscriptionName: "Standard Plan",
      paymentLink: "https://example.com/payment/emilyjohnson",
      status: "Expired",
      charges: "Single Charge",
    },
    {
      name: "Chris Davis",
      subscriptionName: "Premium Plan",
      paymentLink: "https://example.com/payment/chrisdavis",
      status: "Active",
      charges: "Single Charge",
    },
    {
      name: "Sarah Wilson",
      subscriptionName: "Basic Plan",
      paymentLink: "https://example.com/payment/sarahwilson",
      status: "Canceled",
      charges: "Single Charge",
    },
    {
      name: "Daniel Garcia",
      subscriptionName: "Pro Plan",
      paymentLink: "https://example.com/payment/danielgarcia",
      status: "Active",
      charges: "Single Charge",
    },
    {
      name: "Jessica Martinez",
      subscriptionName: "Standard Plan",
      paymentLink: "https://example.com/payment/jessicamartinez",
      status: "Pending",
      charges: "Single Charge",
    },
    {
      name: "David Lee",
      subscriptionName: "Premium Plan",
      paymentLink: "https://example.com/payment/davidlee",
      status: "Expired",
      charges: "Single Charge",
    },
  ];
  
  return (
    <div  >
   
      <div
        style={{ marginBottom: '1.5rem' }}
        className="global-search-pagination-filter-box"
      >
      
       
      </div>
      <div>
     <PaymentLinksComponent paymentLinks={dummyData}/>
      </div>
   
  </div>
  )
}

export default PaymentLink;
